/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { MEMBER_FAQ_API_URL, MEMBER_FAQ_CATEGORIES_API_URL } from './memberFAQUrls';

export const listMemberFAQCategories = createAsyncThunk(
  'memberFAQSlice/listMemberFAQCategories',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(MEMBER_FAQ_CATEGORIES_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getMemberFAQCategories = createAsyncThunk(
  'memberFAQSlice/getMemberFAQCategories',
  async (id) => {
    return axios.get(MEMBER_FAQ_CATEGORIES_API_URL(id)).then(({ data }) => data);
  },
);

export const createFAQ = async (params) => {
  return axios.post(
    MEMBER_FAQ_API_URL(),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const createMemberFAQCategories = createAsyncThunk(
  'memberFAQSlice/createMemberFAQCategories',
  async (params) => {
    let faqs = params.faqs.map((p) => createFAQ(p));
    faqs = await Promise.all(faqs);
    faqs = faqs.map((p) => p?.doc?.id);
    return axios.post(
      MEMBER_FAQ_CATEGORIES_API_URL(),
      { ...params, faqs },
    ).then(({ data }) => {
      toast.success('Member FAQ Category has been created');
      return data;
    });
  },
);

export const updateFAQ = async ({ id, ...params }) => {
  return axios.patch(
    MEMBER_FAQ_API_URL(id),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const updateMemberFAQCategories = createAsyncThunk(
  'memberFAQSlice/updateMemberFAQCategories',
  async ({ id, params }) => {
    let faqsToUpdate = params.faqs.filter((p) => p.id);
    faqsToUpdate = faqsToUpdate.map((p) => updateFAQ(p));
    faqsToUpdate = await Promise.all(faqsToUpdate);
    faqsToUpdate = faqsToUpdate.map((p) => p?.doc?.id);

    let faqsToCreate = params.faqs.filter((p) => !p.id);
    faqsToCreate = faqsToCreate.map((p) => createFAQ(p));
    faqsToCreate = await Promise.all(faqsToCreate);
    faqsToCreate = faqsToCreate.map((p) => p?.doc?.id);

    return axios.patch(
      MEMBER_FAQ_CATEGORIES_API_URL(id),
      { ...params, faqs: [...faqsToCreate, ...faqsToUpdate] },
    ).then(({ data }) => {
      toast.success('Member FAQ Category has been updated');
      return data;
    });
  },
);

export const deleteMemberFAQCategories = createAsyncThunk(
  'memberFAQSlice/deleteMemberFAQCategories',
  async (id) => {
    return axios.patch(MEMBER_FAQ_CATEGORIES_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Member FAQ Category has been deleted');
      });
  },
);

import React, { useState } from 'react';
import './_addNewBiomarker.scss';
import Button from 'shared/components/button/Button';
import Grid from 'shared/components/grid/Grid';
import { AddedBiomarkerGridColumns } from 'utils/grid/columns';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import _ from 'lodash';
import AddNewBiomarker from './AddNewBiomarkerList';

export default function AddedBiomarker({ value, onChange }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const onDelete = (row) => {
    onChange(value?.filter((o) => o.id !== row.id));
  };

  const addBiomarkerpopup = () => {
    setDialogOpen(true);
  };

  const onSelect = (v) => {
    onChange(v);
    setDialogOpen(false);
  };

  return (
    <div className="added-biomarker-container">
      <div className="added-biomarker-container__body">
        <label htmlFor="bio">Add Biomarker *</label>
        <Button variant={Button.variant.filled} onClick={addBiomarkerpopup}>
          Add Biomarkers
        </Button>
        {!_.isEmpty(value) && (
          <div>
            <label htmlFor="bio">Added Biomarkers</label>
            <Grid columns={AddedBiomarkerGridColumns(onDelete)} data={value} />
          </div>
        )}
      </div>
      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen}
        title="Select Biomarker"
        handleOk={() => {}}
        handleCancel={() => setDialogOpen(false)}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <AddNewBiomarker value={value} onChange={onSelect} />
      </DialogPopup>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteHowTos, listHowTos } from 'redux-store/howTos/howTosActions';
import { clearData } from 'redux-store/howTos/howTosSlice';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
// import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { MemberVideoGridColumns } from 'utils/grid/columns';

function ProviderVideo() {
  const filterKey = 'where[and][0][title][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [filterKey]: '',
    'where[and][1][audience][equals]': 'Providers',
    sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { howTos } = useSelector((state) => state.howTos);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.PROVIDER_VIDEO_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.PROVIDER_VIDEO_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      limit: 10,
      page: 1,
      [filterKey]: v,
    });
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteHowTos({ id: item.id }))
        .then((payload) => {
          if (!payload.error) {
            if (params.page === 1) {
              dispatch(listHowTos(params));
            } else {
              setParams({ ...params, page: 1 });
            }
          }
        });
    }
  };

  useEffect(() => {
    dispatch(listHowTos(params));
  }, [params]);

  useEffect(() => () => dispatch(clearData()), []);

  return (
    <div className="revdoc-setup-container">
      <div className="revdoc-setup-container__header">
        <SearchBox placeholder="Search Videos by title... " onChange={onSearch} />
        {/* <DropDown
          placeholder="Filter By"
          isOptionsObj
          className="provider-list-dropdown"
          options={[]}
        /> */}
        <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
          + Add New Video
        </Button>
      </div>
      <Grid
        columns={
          MemberVideoGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )
        }
        data={howTos.docs}
        pagination={{
          total: howTos.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
      />
    </div>
  );
}

export default ProviderVideo;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { PRIVACY_POLICY_API_URL } from './privacyPolicyUrls';

export const listPrivacyPolicy = createAsyncThunk(
  'privacyPolicySlice/listPrivacyPolicy',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PRIVACY_POLICY_API_URL(`versions?${query}`)).then(({ data }) => data);
  },
);
export const getPrivacyPolicyLive = createAsyncThunk(
  'privacyPolicySlice/getPrivacyPolicyLive',
  async () => {
    return axios.get(PRIVACY_POLICY_API_URL()).then(({ data }) => data);
  },
);

export const getPrivacyPolicy = createAsyncThunk(
  'privacyPolicySlice/getPrivacyPolicy',
  async (id) => {
    return axios.get(PRIVACY_POLICY_API_URL(`versions/${id}`)).then(({ data }) => data);
  },
);

export const createPrivacyPolicy = createAsyncThunk(
  'privacyPolicySlice/createPrivacyPolicy',
  async (params) => {
    return axios.post(
      PRIVACY_POLICY_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      params,
    ).then(({ data }) => {
      toast.success('Privacy Policy has been created');
      return data;
    });
  },
);

export const updatePrivacyPolicy = createAsyncThunk(
  'privacyPolicySlice/updatePrivacyPolicy',
  async ({ id, params }) => {
    return axios.post(
      PRIVACY_POLICY_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      { ...params, id },
    ).then(({ data }) => {
      toast.success('Privacy Policy has been updated');
      return data;
    });
  },
);

export const deletePrivacyPolicy = createAsyncThunk(
  'privacyPolicySlice/deletePrivacyPolicy',
  async (id, { dispatch }) => {
    return axios.patch(PRIVACY_POLICY_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Privacy Policy has been deleted');
        return dispatch(listPrivacyPolicy());
      });
  },
);

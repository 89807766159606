/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { BIO_MARKERS_API_URL } from './bioMarkersUrls';

export const listBioMarkers = createAsyncThunk(
  'bioMarkersSlice/listBioMarkers',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(BIO_MARKERS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getBioMarkers = createAsyncThunk(
  'bioMarkersSlice/getbioMarkers',
  async (id) => {
    return axios.get(BIO_MARKERS_API_URL(id)).then(({ data }) => data);
  },
);

export const createBioMarkers = createAsyncThunk(
  'bioMarkersSlice/createBioMarkers',
  async (params) => {
    return axios.post(BIO_MARKERS_API_URL(), params).then(({ data }) => {
      toast.success('Successfully created biomarker');
      return data;
    });
  },
);

export const updateBioMarkers = createAsyncThunk(
  'bioMarkersSlice/updateBioMarkers',
  async ({ id, data: params }) => {
    return axios.patch(BIO_MARKERS_API_URL(id), params)
      .then(({ data }) => {
        toast.success('BioMarker has been updated');
        return data;
      });
  },
);
export const deleteBioMarkers = createAsyncThunk(
  'bioMarkersSlice/deleteBioMarkers',
  async (id) => {
    return axios.patch(BIO_MARKERS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('BioMarker has been deleted');
      });
  },
);

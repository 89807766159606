import { createSlice } from '@reduxjs/toolkit';
import {
  createAtHomeLab,
  deleteAtHomeLab,
  getAtHomeLab,
  listAtHomeLab,
  updateAtHomeLab,
} from './atHomeLabsActions';

const INITIAL_STATE = {
  isLoading: false,
  atHomeLabs: {},
  selectedAtHomeLabs: {},
};

export const atHomeLabSlice = createSlice({
  name: 'atHomeLabSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listAtHomeLab.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listAtHomeLab.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listAtHomeLab.fulfilled, (state, action) => {
      state.isLoading = false;
      state.atHomeLabs = action.payload;
    });
    builder.addCase(getAtHomeLab.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAtHomeLab.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAtHomeLab.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedAtHomeLabs = action.payload;
    });
    builder.addCase(createAtHomeLab.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAtHomeLab.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createAtHomeLab.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAtHomeLab.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAtHomeLab.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAtHomeLab.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedAtHomeLabs = action.payload.doc;
    });
    builder.addCase(deleteAtHomeLab.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAtHomeLab.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAtHomeLab.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = atHomeLabSlice.actions;

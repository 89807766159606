import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'shared/axios/axios';

import { PRODUCTS_API_URL } from './productUrls';

export const listProducts = createAsyncThunk(
  'productsSlice/listProducts',
  async (filters, thunkApi) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PRODUCTS_API_URL(`?${query}`))
      .then(({ data }) => thunkApi.fulfillWithValue(data))
      .catch((e) => thunkApi.rejectWithValue(e));
  },
);

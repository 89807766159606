import { createSlice } from '@reduxjs/toolkit';
import {
  createMessages,
  deleteMessages,
  getMessages,
  getMessagesInbox,
  markThreadRead,
  refreshMessagesFromInterval,
  updateMessages,
} from './messagesActions';

const INITIAL_STATE = {
  isLoading: false,
  isSendingMessage: false,
  messages: { docs: [] },
  selectedSymptom: {},
  inbox: [],
};

export const messagesSlice = createSlice({
  name: 'messagesSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: (state) => ({ ...INITIAL_STATE, inbox: state.inbox }),
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMessages.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSymptom = action.payload;
    });
    builder.addCase(createMessages.pending, (state) => {
      state.isSendingMessage = true;
    });
    builder.addCase(createMessages.rejected, (state) => {
      state.isSendingMessage = false;
    });
    builder.addCase(createMessages.fulfilled, (state) => {
      state.isSendingMessage = false;
      // state.messages.docs = [action.payload.doc, ...state.messages.docs];
    });
    builder.addCase(updateMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMessages.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMessages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSymptom = action.payload.doc;
    });
    builder.addCase(deleteMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMessages.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMessages.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(refreshMessagesFromInterval.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(refreshMessagesFromInterval.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(refreshMessagesFromInterval.fulfilled, (state, action) => {
      state.isLoading = false;
      state.messages = action.payload;
    });
    builder.addCase(getMessagesInbox.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMessagesInbox.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMessagesInbox.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inbox = action.payload;
    });
    builder.addCase(markThreadRead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(markThreadRead.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(markThreadRead.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = messagesSlice.actions;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { FEEDBACKS_API_URL } from './feedbacksUrls';

export const listFeedbacks = createAsyncThunk(
  'feedbacksSlice/listFeedbacks',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(FEEDBACKS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getFeedbacks = createAsyncThunk(
  'feedbacksSlice/getFeedbacks',
  async (id) => {
    return axios.get(FEEDBACKS_API_URL(id)).then(({ data }) => data);
  },
);

export const createFeedbacks = createAsyncThunk(
  'feedbacksSlice/createFeedbacks',
  async (params) => {
    return axios.post(FEEDBACKS_API_URL(), params).then(({ data }) => {
      toast.success('Successfully created feedback');
      return data;
    });
  },
);

export const updateFeedbacks = createAsyncThunk(
  'feedbacksSlice/updateFeedbacks',
  async ({ id, data: params }) => {
    return axios.patch(FEEDBACKS_API_URL(id), params)
      .then(({ data }) => {
        toast.success('Feedback has been updated');
        return data;
      });
  },
);
export const deleteFeedbacks = createAsyncThunk(
  'feedbacksSlice/deleteFeedbacks',
  async (id) => {
    return axios.patch(FEEDBACKS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Feedback has been deleted');
      });
  },
);

/* eslint-disable jsx-a11y/media-has-caption */
import { Form } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserManagementSetup, updateUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { clearData } from 'redux-store/userManagementSetup/userManagementSetupSlice';
import Button from 'shared/components/button/Button';
import TextAreaField from 'shared/components/textArea/TextArea';
import UploadButton from 'shared/components/upload/Upload';
import { ViewMedia } from 'shared/components/viewMedia/ViewMedia';
import { VIEW_IMAGE, VIEW_VIDEO } from 'shared/constants/viewMediaType';
import { applyTrim, blackListedChars } from 'shared/utils/validators';

export default function ProfileSetup() {
  const user = useSelector((s) => s.auth.user);
  const userSetup = useSelector((s) => s.userManagementSetups.selectedUserManagementSetup);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = (params) => {
    params = applyTrim(params);
    dispatch(updateUserManagementSetup({ params, id: user.id }));
  };
  const setForm = () => {
    form.setFieldsValue({
      ...userSetup,
      profilePhoto: _.get(userSetup, 'profilePhoto.id'),
      profileVideo: _.get(userSetup, 'profileVideo.id'),
    });
  };

  const deleteVideo = () => {
    dispatch(updateUserManagementSetup({ params: { profileVideo: null }, id: user.id }));
  };
  const deleteImage = () => {
    dispatch(updateUserManagementSetup({ params: { profilePhoto: null }, id: user.id }));
  };

  useEffect(() => {
    if (user.id) {
      dispatch(getUserManagementSetup(user.id));
    }
  }, [user.id]);

  useEffect(() => {
    setForm();
  }, [userSetup]);

  useEffect(() => () => {
    dispatch(clearData());
  }, []);

  return (
    <div className="profile-setup-container">
      <div className="profile-setup-container__wrapper">
        <h4>Profile Set Up</h4>
        <Form onFinish={handleSubmit} form={form} onFinishFailed={() => toast.error('Please fill the entire form')}>
          <div className="d-grid">
            <div>
              <div className="profile-label">Image</div>
              <div className="sub-label">
                Please upload a clear professional headshot.
              </div>
            </div>
            <div className="d-grid">
              <Form.Item
                name="profilePhoto"
                className="formfield"
              >
                <UploadButton accept=".jpg, .jpeg, .png" label="" placeholder="Upload Image" />
              </Form.Item>
              {userSetup.profilePhoto?.url && (
                <ViewMedia
                  src={userSetup.profilePhoto?.url}
                  type={VIEW_IMAGE}
                  onDelete={deleteImage}
                />
              )}
            </div>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div>
              <div className="profile-label">Video</div>
              <div className="sub-label">
                Please refer to guidelines regarding video
                requirements included in the onboarding manual.
              </div>
            </div>
            <div className="d-grid">
              <Form.Item
                name="profileVideo"
                className="formfield"
              >
                <UploadButton accept=".mp4, " maxSize={50} label="" placeholder="Upload Video" />
              </Form.Item>
              {userSetup.profileVideo?.url && (
                <ViewMedia
                  src={_.get(userSetup, 'profileVideo.url', '')}
                  videoType={_.get(userSetup, 'profileVideo.mimeType', '')}
                  type={VIEW_VIDEO}
                  onDelete={deleteVideo}
                />
              )}
            </div>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div>
              <div className="profile-label">About/Bio</div>
              <div className="sub-label">
                Instructions on what to include in your bio are also in the onboarding manual.
              </div>
            </div>
            <Form.Item
              name="profileAbout"
              className="formfield"
              rules={[
                { required: false, message: 'This field is required' },
                blackListedChars,
              ]}
            >
              <TextAreaField className="test" rows={3} maxLength={1500} placeholder="About" />
            </Form.Item>
          </div>
          <div className="d-grid">
            <div>
              <div className="profile-label">RevDoc ID</div>
            </div>
            {userSetup.revDocId}
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">Your Personal Email</div>
            <div>
              {_.get(userSetup, 'personalEmail')}
            </div>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">Your Revdoc Email</div>
            <div>
              {_.get(userSetup, 'revdocEmail')}
            </div>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">Your Phone Number</div>
            <div>
              {_.get(userSetup, 'mobilePhoneNumber')}
            </div>
          </div>

          {/* <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">State Region Assigned To</div>
            <Form.Item
              name="uploadImage"
              className="formfield"
              rules={[{ required: false, message: 'This field is required' }]}
            >
              <InputWithLabel type="text" label="State Region Assigned To" />
            </Form.Item>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">Director Assigned To</div>
            <Form.Item
              name="uploadImage"
              className="formfield"
              rules={[{ required: false, message: 'This field is required' }]}
            >
              <InputWithLabel type="text" label="Director Assigned To" />
            </Form.Item>
          </div>
          <div className="border-line" />
          <div className="d-grid">
            <div className="profile-label">Contact Information</div>
            <div className="dd-grid">
              <Form.Item
                name="uploadImage"
                className="formfield"
                rules={[{ required: false, message: 'This field is required' }]}
              >
                <InputWithLabel type="text" label="RevDoc Email Address" />
              </Form.Item>
              <Form.Item
                name="uploadImage"
                className="formfield"
                rules={[{ required: false, message: 'This field is required' }]}
              >
                <InputWithLabel type="text" label="RevDoc Mobile Number" />
              </Form.Item>
            </div>
          </div> */}
          <div className="border-line" />
          <div className="profile-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={setForm}>Cancel</Button>
            <Button variant={Button.variant.filled} htmlType="submit">Save</Button>
          </div>
        </Form>
      </div>

      <div className="profile-setup-container__wrapper mrt-36">
        <h4>My Information</h4>
        <div className="border-line" />
        <div className="d-grid">
          <div className="profile-label">State Region Assigned To</div>
          <span>{_.uniq(_.get(userSetup, 'counties', []).map((c) => c.stateName)).join(', ')}</span>
        </div>
        <div className="border-line" />
        <div className="d-grid">
          <div className="profile-label">County Assigned To</div>
          <span>{_.get(userSetup, 'counties', []).map((c) => c.countyName).join(', ')}</span>
        </div>
        <div className="border-line" />
        <div className="d-grid">
          <div className="profile-label">Director Assigned To</div>
          {!_.isEmpty(userSetup.reportsTo) && (
            <span>
              {`
              ${_.get(userSetup, 'reportsTo.firstName', '')} ${_.get(userSetup, 'reportsTo.middleName', '')}  
              ${_.get(userSetup, 'reportsTo.lastName', '')},
              ${_.get(userSetup, 'reportsTo.revdocEmail', '-')},
              ${_.get(userSetup, 'reportsTo.mobilePhoneNumber', '-')}
            `}
            </span>
          )}
        </div>
        <div className="border-line" />
        <div className="d-grid">
          <div className="profile-label">RevDoc Start Date</div>
          <span>{moment(_.get(userSetup, 'startDate')).format('MM/DD/YYYY')}</span>
        </div>
      </div>
    </div>
  );
}

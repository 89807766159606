import React from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from '../../../../../../shared/components/grid/Grid';
import {
  AssignedProvidersGridColumns, ComplaintssGridColumns, GoalsGridColumns, ReviewsGridColumns,
} from '../../../../../../utils/grid/columns';
import {
  AssignedProvidersGridMockData, ComplaintGridMockData, GoalGridMockData, ReviewGridMockData,
} from '../../../../../../mock/mockData';

export default function PrmDashboardStatistics() {
  const navigate = useNavigate();
  const ProviderNameClickHanlder = () => {
    navigate(`${URLS.SUPPLIES_SETUP_URL}/new`);
  };

  return (
    <div className="prm-dashboard-container">
      <div className="prm-dashboard-container__body">
        <div className="prm-dashboard-container__body--header">
          <h4>Providers Assigned</h4>
          <SearchBox placeholder="Search for a Provider" />
        </div>
        <Grid
          columns={
          AssignedProvidersGridColumns(
            { ProviderNameClick: (name) => ProviderNameClickHanlder(name) },
          )
        }
          data={AssignedProvidersGridMockData()}
        />
        <div className="metrics-container">
          <div className="revnue-metrics-wrappper">
            <h4>Revenue Metrics</h4>
            <div className="box-wrapper">
              <div className="box">
                <div className="label">Revenue by month</div>
                <div className="value">$24,609</div>
              </div>
              <div className="box">
                <div className="label">Revenue by year</div>
                <div className="value">$398,009</div>
              </div>
            </div>
          </div>
          <div className="appointment-metrics-wrappper">
            <h4>Providers Appointment Metrics</h4>
            <div className="box-wrapper">
              <div className="box">
                <div className="label">Appointment by month</div>
                <div className="value">130</div>
              </div>
              <div className="box right-zero">
                <div className="label">Appointment by year</div>
                <div className="value">800</div>
              </div>
            </div>
          </div>
        </div>
        <div className="goals-container">
          <h4>Goals</h4>
          <Grid
            columns={GoalsGridColumns()}
            data={GoalGridMockData()}
          />
        </div>
        <div className="reviews-container">
          <div className="d-flex">
            <h4>Reviews</h4>
            <SearchBox placeholder="Search for a Review" />
          </div>
          <Grid
            columns={ReviewsGridColumns()}
            data={ReviewGridMockData()}
          />
        </div>
        <div className="complaints-container">
          <h4>Complaints</h4>
          <Grid
            columns={ComplaintssGridColumns()}
            data={ComplaintGridMockData()}
          />
        </div>
      </div>
    </div>
  );
}

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { SPECIALITY_SETUPS_API_URL } from './specialitySetupUrls';

export const listSpecialitySetup = createAsyncThunk(
  'specialitySetupSlice/listSpecialitySetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(SPECIALITY_SETUPS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getSpecialitySetup = createAsyncThunk(
  'specialitySetupSlice/getSpecialitySetup',
  async (id) => {
    return axios.get(SPECIALITY_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createSpecialitySetup = createAsyncThunk(
  'specialitySetupSlice/createSpecialitySetup',
  async (params) => {
    return axios.post(
      SPECIALITY_SETUPS_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Speciality has been created');
      return data;
    });
  },
);

export const updateSpecialitySetup = createAsyncThunk(
  'specialitySetupSlice/updateSpecialitySetup',
  async ({ id, params }) => {
    return axios.patch(
      SPECIALITY_SETUPS_API_URL(id),
      params,
    ).then(({ data }) => {
      toast.success('Speciality has been updated');
      return data;
    });
  },
);

export const deleteSpecialitySetup = createAsyncThunk(
  'specialitySetupSlice/deleteSpecialitySetup',
  async (id, { dispatch }) => {
    return axios.patch(SPECIALITY_SETUPS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Speciality has been deleted');
        return dispatch(listSpecialitySetup());
      });
  },
);

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
// import AddNewSuppliesSetup from './components/AddNewSupply';
import { deleteBioMarkers, listBioMarkers } from 'redux-store/bioMarkers/bioMarkersActions';
import { useDispatch, useSelector } from 'react-redux';
import { BiomarkerGridColumns } from '../../../../../../utils/grid/columns';

function BioMakerList() {
  const SEARCH_KEY = 'where[labBiomarkerName][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [SEARCH_KEY]: '',
    sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { bioMarkers } = useSelector((state) => state.bioMarkers);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.BIOMARKER_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.BIOMARKER_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      [SEARCH_KEY]: v,
    });
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteBioMarkers(item.id))
        .then((payload) => {
          if (!payload.error) {
            if (params.page === 1) {
              dispatch(listBioMarkers(params));
            } else {
              setParams({ ...params, page: 1 });
            }
          }
        });
    }
  };

  useEffect(() => {
    dispatch(listBioMarkers(params));
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by Biomarker name" onChange={onSearch} />
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New Biomaker
          </Button>
        </div>
        <Grid
          columns={
            BiomarkerGridColumns(
              { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            )
          }
          data={bioMarkers.docs}
          pagination={{
            total: bioMarkers.totalDocs,
            current: params.page,
            defaultPageSize: 5,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}

export default BioMakerList;

/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listQuickLinkSection } from 'redux-store/quickLinks/quickLinksActions';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import './_quickLinkSectionsSelect.scss';
import DropDown from '../dropdown/DropDown';

function QuickLinkSectionsSelect({
  label,
  placeholder,
  value,
  onChange,
  className,
  disabled,
}) {
  const { quickLinkSections } = useSelector((s) => s.quickLinks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listQuickLinkSection({
      limit: 0,
    }));
  }, []);

  return (
    <label htmlFor="image">
      {label}
      <DropDown
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
        isOptionsObj
        options={quickLinkSections?.docs?.map((item) => ({
          value: item.id,
          label: item.section,
        }))}
      />
    </label>
  );
}

export default QuickLinkSectionsSelect;

/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCategorySetup } from 'redux-store/categorySetup/categorySetupActions';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import './_procedureSelect.scss';
import { listProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';
import DropDown from '../dropdown/DropDown';

function ProcedureSelect({
  label,
  placeholder,
  value,
  onChange,
  className,
  disabled,
}) {
  const { proceduresSetups, isLoading } = useSelector((s) => s.proceduresSetup);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProceduresSetup({
      limit: 0,
      ...getTermedFiltersOnField('procedureEffectiveDate', 'procedureTermDate'),
    }));
  }, []);

  return (
    <label htmlFor="image">
      {label}
      <DropDown
        disabled={disabled}
        loading={isLoading}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
        isOptionsObj
        options={proceduresSetups?.docs?.map((item) => ({
          value: item.id,
          label: item.procedureName,
        }))}
      />
    </label>
  );
}

export default ProcedureSelect;

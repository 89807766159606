/* eslint-disable no-debugger */
import { createSlice } from '@reduxjs/toolkit';
import { listProducts } from './productsActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  products: { docs: [] },
  selectedProducts: {},
};

export const productsSlice = createSlice({
  name: 'productsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProducts.rejected, (state) => {
      state.isLoading = false;
      state.products = { docs: [] };
    });
    builder.addCase(listProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    return builder;
  },
});

export const { clearData } = productsSlice.actions;

import React, { useState } from 'react';
import Button from 'shared/components/button/Button';
import InputWithLabel from 'shared/components/inputWithLabel/InputWithLabel';

export default function GiftCardPopup() {
  const [is25, set25] = useState(false);
  const [is50, set50] = useState(false);
  const [is100, set100] = useState(false);
  const [custom, setCustom] = useState(false);
  const [text, settext] = useState(false);
  const [email, setemail] = useState(false);
  return (
    <div className="gift-card-container">
      <div className="gift-card-container__amount">
        <label htmlFor="gift">Gift Card Amount</label>
        <div className="btns">
          <div className={is25 ? 'orng-amount' : 'amount'} onClick={() => set25(!is25)}>$25</div>
          <div className={is50 ? 'orng-amount' : 'amount'} onClick={() => set50(!is50)}>$50</div>
          <div className={is100 ? 'orng-amount' : 'amount'} onClick={() => set100(!is100)}>$100</div>
          <div className={custom ? 'orng-amount' : 'amount'} onClick={() => setCustom(!custom)}>Custom Amount</div>
        </div>
      </div>
      <div className="input-fields-wrapper">
        <InputWithLabel type="text" label="Who is this gift for? *" />
      </div>
      <div className="input-fields-wrapper">
        <InputWithLabel type="text" label="Optional Message (eg: I hope you enjoy this gift card!)" />
      </div>
      <div className="input-fields-wrapper">
        <InputWithLabel type="text" label="Who is this gift for? *" />
      </div>
      <div className="gift-card-container__amount">
        <label htmlFor="gift">Send Method</label>
        <div className="btns">
          <div className={text ? 'orng-amount' : 'amount'} onClick={() => settext(!text)}>Text</div>
          <div className={email ? 'orng-amount' : 'amount'} onClick={() => setemail(!email)}>Email</div>
        </div>
      </div>
      <div className="gift-card-container__action-btn">
        <Button variant={Button.variant.outlined}>Cancel</Button>
        <Button variant={Button.variant.filled} htmlType="submit">Send Gift Card</Button>
      </div>
    </div>
  );
}

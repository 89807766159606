import { createSlice } from '@reduxjs/toolkit';
import {
  createSpecialitySetup,
  deleteSpecialitySetup,
  getSpecialitySetup,
  listSpecialitySetup,
  updateSpecialitySetup,
} from './specialitySetupActions';

const INITIAL_STATE = {
  isLoading: false,
  specialitySetups: {},
  selectedSpecialitySetup: {},
};

export const specialitySetupSlice = createSlice({
  name: 'specialitySetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listSpecialitySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listSpecialitySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listSpecialitySetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.specialitySetups = action.payload;
    });
    builder.addCase(getSpecialitySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSpecialitySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSpecialitySetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSpecialitySetup = action.payload;
    });
    builder.addCase(createSpecialitySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSpecialitySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createSpecialitySetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSpecialitySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSpecialitySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSpecialitySetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSpecialitySetup = action.payload.doc;
    });
    builder.addCase(deleteSpecialitySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSpecialitySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteSpecialitySetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = specialitySetupSlice.actions;

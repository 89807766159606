import React, { useEffect } from 'react';
import Grid from 'shared/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { listAllProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';
import { ProcedureAddOnGridColumns } from '../../../../../../utils/grid/columns';
import './addon.scss';

export function AddOnList(props) {
  const dispatch = useDispatch();
  const { proceduresData, isLoading } = useSelector((state) => state.proceduresSetup);
  const {
    mode, pid, data, onSelection,
  } = props;

  const ActionClickHanlder = (identifier, item, flag) => {
    if (identifier === 'select') {
      onSelection(item, flag);
    } else if (identifier === 'delete') {
      onSelection(item, false);
    }
  };
  useEffect(() => {
    const payload = {
      limit: 0,
      page: 1,
      searchText: '',
      sort: '-updatedAt',
    };
    payload['where[addOnOnly][equals]'] = true;
    if (pid) {
      payload['where[id][not_equals]'] = pid;
    }
    if (mode === 'view') {
      dispatch(listAllProceduresSetup(payload));
    }
  }, []);

  return (
    <div className="addonlist">
      <div className="addonlist__container">
        { proceduresData.docs?.length > 0 && (
        <Grid
          loading={isLoading}
          columns={
            ProcedureAddOnGridColumns(
              mode !== 'view',
              {
                ActionClickHanlder:
                (identifier, item, flag) => ActionClickHanlder(identifier, item, flag),
              },
              props?.selectedItems || [],
            )
          }
          data={mode === 'view' ? proceduresData.docs?.filter((x) => data?.includes(x.id)) : proceduresData.docs}
          pagination={false}
        />
        )}
      </div>
    </div>
  );
}
export default AddOnList;

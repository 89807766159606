/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import chatTick from '../../../../assets/imgs/chatTick.png';

export default function SchoolDiploma() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);

  return (
    <div className="provider-pop-up-container__diploma">
      {!_.isEmpty(selectedMyProviders.undergraduateEducation) && (
        <div>
          <div>
            <Button
              variant={Button.variant.filled}
              onClick={() => window.open(_.get(selectedMyProviders, 'undergraduateEducation.diplomaImage.url', null), '_blank')}
              style={{
                width: '100%',
                marginBottom: 16,
              }}
            >
              <FontAwesomeIcon icon={faLink} className="checkIcon" />
              &nbsp;
              Open File
            </Button>
          </div>
          <div className="school">
            <h3>{_.get(selectedMyProviders, 'undergraduateEducation.universityName', null)}</h3>
            <span>{_.get(selectedMyProviders, 'undergraduateEducation.graduationYear', null)}</span>
            <span>{_.get(selectedMyProviders, 'undergraduateEducation.stateCode', null)}</span>
          </div>
          <div className="border" />
        </div>
      )}
      {!_.isEmpty(selectedMyProviders.graduateEducation) && (
        <div>
          <div>
            <Button
              variant={Button.variant.filled}
              onClick={() => window.open(_.get(selectedMyProviders, 'graduateEducation.diplomaImage.url', null), '_blank')}
              style={{
                width: '100%',
                marginBottom: 16,
              }}
            >
              <FontAwesomeIcon icon={faLink} className="checkIcon" />
              &nbsp;
              Open File
            </Button>
          </div>
          <div className="school">
            <h3>{_.get(selectedMyProviders, 'graduateEducation.universityName', null)}</h3>
            <span>{_.get(selectedMyProviders, 'graduateEducation.graduationYear', null)}</span>
            <span>{_.get(selectedMyProviders, 'graduateEducation.stateCode', null)}</span>
          </div>
          <div className="border" />
        </div>
      )}
      {!_.isEmpty(selectedMyProviders.internship) && (
        <div>
          <div className="d-flex">
            <span className="key">Internship Name</span>
            <span className="value">{_.get(selectedMyProviders, 'internship.internshipName', '')}</span>
          </div>
          <div className="d-flex">
            <span className="key">State</span>
            <span className="value">{_.get(selectedMyProviders, 'internship.stateCode', '')}</span>
          </div>
          <div className="border" />
        </div>
      )}
      {!_.isEmpty(selectedMyProviders.residency) && (
        <div>
          <div className="d-flex">
            <span className="key">Residency Name</span>
            <span className="value">{_.get(selectedMyProviders, 'residency.residencyName', '')}</span>
          </div>
          <div className="d-flex">
            <span className="key">State</span>
            <span className="value">{_.get(selectedMyProviders, 'residency.stateCode', '')}</span>
          </div>
          <div className="border" />
        </div>
      )}
      {!_.isEmpty(selectedMyProviders.fellowship) && (
        <div>
          <div className="d-flex">
            <span className="key">Fellowship Name</span>
            <span className="value">{_.get(selectedMyProviders, 'fellowship.fellowshipName', '')}</span>
          </div>
          <div className="d-flex">
            <span className="key">State</span>
            <span className="value">{_.get(selectedMyProviders, 'fellowship.stateCode', '')}</span>
          </div>
          <div className="border" />
        </div>
      )}
      {_.get(selectedMyProviders, 'certifications', []).map((c) => (
        <div>
          <Button
            variant={Button.variant.filled}
            onClick={() => window.open(_.get(c, 'certificationImage.url', null), '_blank')}
            style={{
              width: '100%',
              marginBottom: 16,
            }}
          >
            <FontAwesomeIcon icon={faLink} className="checkIcon" />
            &nbsp;
            Open File
          </Button>

          <div className="d-flex">
            <span className="key">Certification Name</span>
            <span className="value">{c?.certificationName}</span>
          </div>
        </div>
      ))}
      {_.get(selectedMyProviders, 'verificationChecklist.boardCertification.verified') && (
        <p style={{ alignItems: 'center', display: 'flex' }}>
          <img style={{ width: 16 }} src={chatTick} alt="" width={16} height={16} />
          Verified By Revdoc
        </p>
      )}
    </div>
  );
}

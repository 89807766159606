/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'shared/axios/axios';

import { SUPPLY_VENDORS_API_URL } from './supplyVendorsUrls';

export const listSupplyVendors = createAsyncThunk(
  'supplyVendorsSlice/listSupplyVendors',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(SUPPLY_VENDORS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listFeedbacks } from 'redux-store/feedbacks/feedbacksActions';
import { URLS } from 'routes';
import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { ProviderFeedbackGridColumns } from 'utils/grid/columns';

export default function ProviderFeedbackList() {
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
  };

  const [params, setParams] = useState(INITIAL_PARAMS);
  const [filterBy, setFilterBy] = useState(
    'where[or][0][createdAt][like]',
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { feedbacks } = useSelector((s) => s.feedbacks);

  const FeedbackIdClick = (item) => {
    navigate(`${URLS.SUGGESTION_DETAIL_PROVIDER_BASE_URL}/${item.id}`);
  };

  const onSearch = (v) => {
    if (v?.length > 0) {
      setParams({
        limit: 10,
        page: 1,
        [filterBy]: v,
      });
    } else {
      setParams({
        limit: 10,
        page: 1,
      });
    }
  };

  useEffect(() => {
    dispatch(listFeedbacks({ ...params, 'where[ownerRole][equals]': 'provider' }));
  }, [params]);

  return (
    <div>
      <div className="complaint-container">
        <div className="complaint-container__header">
          <SearchBox onChange={onSearch} placeholder="Search by .." />
          <DropDown
            placeholder="Filter By"
            isOptionsObj
            className="provider-list-dropdown"
            value={filterBy}
            onChange={setFilterBy}
            options={[
              {
                value: 'where[or][0][createdAt][like]',
                label: 'Date Received',
              },
              {
                value: 'where[or][0][resolution.resolutionDecision][like]',
                label: 'Feedback Status',
              },
              {
                value: 'where[or][0][ownerProfile.value.fullName][like]',
                label: 'User',
              },
            ]}
          />
        </div>
        <div className="provider-list-container__body">
          <Grid
            columns={ProviderFeedbackGridColumns(
              { FeedbackIdClick: (identifier, item) => FeedbackIdClick(identifier, item) },
            )}
            data={feedbacks.docs}
            pagination={{
              total: feedbacks.totalDocs,
              current: params.page,
              defaultPageSize: 10,
              onChange: (page, pageSize) => {
                setParams({ ...params, page, limit: pageSize });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

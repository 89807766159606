import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-solid-svg-icons';
import './_noAccess.scss';

function NoAccess() {
  return (
    <div className="noAccessContainer">
      <FontAwesomeIcon icon={faHand} size="10x" />
      <p>
        Welcome to Revdoc!
        <br />
        Your PRM profile is still being created.
        <br />

        Questions? Contact your HR representative.
      </p>
    </div>
  );
}

export default NoAccess;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_iconText.scss';

function IconText({ text, icon, ...props }) {
  return (
    <span className="iconText" {...props}>
      <span className="iconContainer">
        <FontAwesomeIcon icon={icon} />
      </span>
      {text}
    </span>
  );
}

export default IconText;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { INSURANCE_PLANS_API_URL, INSURANCE_SETUPS_API_URL } from './insuranceSetupUrls';

export const listInsuranceSetup = createAsyncThunk(
  'insuranceSetupSlice/listInsuranceSetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(INSURANCE_SETUPS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getInsuranceSetup = createAsyncThunk(
  'insuranceSetupSlice/getInsuranceSetup',
  async (id) => {
    return axios.get(INSURANCE_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createInsurancePlan = async (params) => {
  return axios.post(
    INSURANCE_PLANS_API_URL(),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const createInsuranceSetup = createAsyncThunk(
  'insuranceSetupSlice/createInsuranceSetup',
  async (params) => {
    let plans = params.plans.map((p) => createInsurancePlan(p));
    plans = await Promise.all(plans);
    plans = plans.map((p) => p?.doc?.id);
    return axios.post(
      INSURANCE_SETUPS_API_URL(),
      { ...params, plans },
    ).then(({ data }) => {
      toast.success('Insurance has been created');
      return data;
    });
  },
);

export const updateInsurancePlan = async ({ id, ...params }) => {
  return axios.patch(
    INSURANCE_PLANS_API_URL(id),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const updateInsuranceSetup = createAsyncThunk(
  'insuranceSetupSlice/updateInsuranceSetup',
  async ({ id, params }) => {
    let plansToUpdate = params.plans.filter((p) => p.id);
    plansToUpdate = plansToUpdate.map((p) => updateInsurancePlan(p));
    plansToUpdate = await Promise.all(plansToUpdate);
    plansToUpdate = plansToUpdate.map((p) => p?.doc?.id);

    let plansToCreate = params.plans.filter((p) => !p.id);
    plansToCreate = plansToCreate.map((p) => createInsurancePlan(p));
    plansToCreate = await Promise.all(plansToCreate);
    plansToCreate = plansToCreate.map((p) => p?.doc?.id);

    return axios.patch(
      INSURANCE_SETUPS_API_URL(id),
      { ...params, plans: [...plansToCreate, ...plansToUpdate] },
    ).then(({ data }) => {
      toast.success('Insurance has been updated');
      return data;
    });
  },
);

export const deleteInsuranceSetup = createAsyncThunk(
  'insuranceSetupSlice/deleteInsuranceSetup',
  async (id, { dispatch }) => {
    return axios.patch(INSURANCE_SETUPS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Insurance has been deleted');
        return dispatch(listInsuranceSetup());
      });
  },
);

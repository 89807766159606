/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { getTermedFiltersOnField } from 'shared/utils/filters';

function UsersSearchInput({
  value,
  placeholder,
  onChange,
}) {
  const { userManagementSetups } = useSelector((state) => state.userManagementSetups);
  const dispatch = useDispatch();
  const { id } = useParams();

  const onSearch = (v) => {
    dispatch(listUserManagementSetup({
      page: 1,
      limit: 0,
      'where[or][0][firstName][like]': v,
      ...getTermedFiltersOnField('startDate', 'endDate'),
    }));
  };

  useEffect(() => {
    onSearch('');
  }, []);

  return (
    <Select
      showSearch
      placeholder={placeholder}
      optionFilterProp="label"
      onChange={onChange}
      value={value}
      onSearch={onSearch}
      options={
        userManagementSetups
          .docs
          .filter((o) => o.id !== id)
          .map((v) => ({
            value: v.id,
            label: `${v.firstName} (${v.isPrm ? 'PRM' : 'Staff'})`,
          }))
      }
    />
  );
}

export default UsersSearchInput;

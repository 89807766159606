import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import TextAreaField from 'shared/components/textArea/TextArea';
import UploadButton from 'shared/components/upload/Upload';
// import CustomDatePicker from 'shared/components/datePicker/DatePicker';
import { clearData } from 'redux-store/howTos/howTosSlice';
import { createHowTos, getHowTos, updateHowTos } from 'redux-store/howTos/howTosActions';
import { isNew } from 'shared/utils/global';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import CustomDatePicker from 'shared/components/datePicker/DatePicker';
import moment from 'moment';

function AddNewProviderVideo() {
  const { id } = useParams();
  const { selectedHowTo } = useSelector((s) => s.howTos);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };

  const handleSubmit = (vals) => {
    vals = applyTrim(vals);
    if (isNew(id)) {
      dispatch(createHowTos(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateHowTos({ id, params: vals }));
    }
  };

  useEffect(() => {
    if (!isNew(id)) {
      dispatch(getHowTos(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedHowTo)) {
      form.setFieldsValue({
        ...selectedHowTo,
        media: _.get(selectedHowTo, 'media.id'),
        audience: 'Providers',
      });
    } else {
      form.setFieldsValue({ audience: 'Providers' });
    }
    forceUpdate();
  }, [selectedHowTo]);
  return (
    <div className="add-new-member-faq-container">
      <div className="add-new-member-faq-container__body">
        <div className="add-new-member-faq-container__heading">
          {!isNew(id) ? 'Edit ' : 'Add New '}
          Provider How To Video
        </div>
        <Form onFinish={handleSubmit} onValuesChange={() => forceUpdate((v) => !v)} form={form} onFinishFailed={() => toast.error('Please fill the required fields')}>
          <div className="video-input-wrapper">
            <Form.Item
              name="media"
              className="formfield"
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
            >
              <UploadButton accept=".mp4, " maxSize={50} label="Upload Video *" placeholder="Upload Video" />
            </Form.Item>
            {selectedHowTo.media?.url && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width="400" controls>
                <source src={selectedHowTo.media?.url} type="video/mp4" />
              </video>
            )}
          </div>
          <Form.Item
            name="audience"
            className="formfield"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
            ]}
          >
            <div />
          </Form.Item>
          <div className="video-input-wrapper">
            <Form.Item
              name="title"
              className="formfield"
              rules={[
                { validator: emptyFieldValidator, message: 'This field is required' },
                blackListedChars,
              ]}
            >
              <InputField
                label="Video Title *"
                placeholder="Title"
              />
            </Form.Item>
          </div>
          <div className="video-input-wrapper">
            <Form.Item
              name="description"
              className="formfield"
              rules={[
                { validator: emptyFieldValidator, message: 'This field is required' },
                blackListedChars,
              ]}
            >
              <TextAreaField maxLength={250} label="Video Description *" className="test" rows={5} placeholder="Add a short description of the video." />
            </Form.Item>
            <div className="lenght">
              {form.getFieldValue('description')?.length || 0}
              /250
            </div>
          </div>
          {/* <div className="video-input-wrapper">
            <CustomDatePicker label="Upload Date *" />
          </div> */}
          <div className="faq-date-wrapper">
            <Form.Item
              name="effectiveDate"
              className="formfield"
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              getValueProps={(i) => ({ value: i && moment(i) })}
            >
              <CustomDatePicker label="Effective Date *" />
            </Form.Item>
            <Form.Item
              name="termDate"
              className="formfield"
              getValueProps={(i) => ({ value: i && moment(i) })}
            >
              <CustomDatePicker
                label="Term Date "
              />
            </Form.Item>
            <div />
          </div>
          <div className="video-input-wrapper">
            <span className="video-label">Approximate Length *</span>
            <div className="d-flex">
              <Form.Item
                name="durationInSeconds"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <InputField type="number" />
              </Form.Item>
              <span>seconds</span>
            </div>
          </div>
          <div className="add-faq-action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            <Button variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddNewProviderVideo;

import React from 'react';
import { Input } from 'antd';
import './TextArea.scss'; // Import Ant Design CSS

function TextAreaField({
  label, rows, placeholder, ...props
}) {
  return (
    <div>
      <label htmlFor="label">{label}</label>
      <Input.TextArea {...props} className={['rv-text-area', props.className]} rows={rows} placeholder={placeholder} />
    </div>
  );
}

export default TextAreaField;

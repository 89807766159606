/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { PREPARATION_STEPS_API_URL } from './preparationStepsUrls';

export const getPreparationSteps = createAsyncThunk(
  'preparationStepsSlice/getPreparationSteps',
  async (id) => {
    return axios.get(PREPARATION_STEPS_API_URL(id)).then(({ data }) => data);
  },
);

export const savePreparationSteps = createAsyncThunk(
  'preparationStepsSlice/savePreparationSteps',
  async (params) => {
    return axios.post(PREPARATION_STEPS_API_URL(), params).then(({ data }) => {
      toast.success('Successfully saved preparation steps');
      return data;
    });
  },
);

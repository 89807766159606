/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import { SUPPLY_IMAGE } from 'shared/constants/global';

import { SUPPLIES_SETUPS_API_URL } from './suppliesSetupUrls';

export const listSuppliesSetup = createAsyncThunk(
  'suppliesSetupSlice/listSuppliesSetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(SUPPLIES_SETUPS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getSuppliesSetup = createAsyncThunk(
  'suppliesSetupSlice/getSuppliesSetup',
  async (id) => {
    return axios.get(SUPPLIES_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createSuppliesSetup = createAsyncThunk(
  'suppliesSetupSlice/createSuppliesSetup',
  async (params) => {
    return createMedia(params.uploadImage, SUPPLY_IMAGE).then((imgObj) => {
      return axios.post(
        SUPPLIES_SETUPS_API_URL(),
        {
          ...params,
          uploadImage: imgObj.doc.id,
        },
      ).then(({ data }) => {
        toast.success('Supply has been created');
        return data;
      });
    });
  },
);

export const updateSuppliesSetup = createAsyncThunk(
  'suppliesSetupSlice/updateSuppliesSetup',
  async ({ id, params }) => {
    let imgID = _.get(params, 'uploadImage', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.uploadImage, SUPPLY_IMAGE);
      imgID = img.doc.id;
    }
    return axios.patch(
      SUPPLIES_SETUPS_API_URL(id),
      {
        ...params,
        uploadImage: imgID,
      },
    ).then(({ data }) => {
      toast.success('Supply has been updated');
      return data;
    });
  },
);

export const deleteSuppliesSetup = createAsyncThunk(
  'suppliesSetupSlice/deleteSuppliesSetup',
  async (id, { dispatch }) => {
    return axios.patch(SUPPLIES_SETUPS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Supply has been deleted');
        return dispatch(listSuppliesSetup());
      });
  },
);

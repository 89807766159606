/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { Form } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createCategorySetup, getCategorySetup, listCategorySetup, updateCategorySetup,
} from 'redux-store/categorySetup/categorySetupActions';
import { clearData } from 'redux-store/categorySetup/categorySetupSlice';
import Button from 'shared/components/button/Button';
import CheckBox from 'shared/components/checkbox/CheckBox';
import DatePicker from 'shared/components/datePicker/DatePicker';
import InputField from 'shared/components/inputField/InputField';
import { isNew } from 'shared/utils/global';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import '../categorySetupList/_categorySetup.scss';
import DropDown from 'shared/components/dropdown/DropDown';
import { getTermedFiltersOnField } from 'shared/utils/filters';

export default function AddNewCategorySetup() {
  const { id } = useParams();
  const { isLoading, selectedCategorySetup, categorySetups } = useSelector((s) => s.categorySetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };
  const handleSubmit = (vals) => {
    vals = applyTrim(vals);
    if (id === 'new') {
      dispatch(createCategorySetup(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateCategorySetup({ id, data: vals }));
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getCategorySetup(id));
    }
    dispatch(listCategorySetup({
      limit: 0,
      ...getTermedFiltersOnField('procedureCategoryEffectiveDate', 'procedureCategoryTermDate'),
    }));
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedCategorySetup)) {
      form.setFieldsValue(selectedCategorySetup);
    }
  }, [selectedCategorySetup]);

  return (
    <div className="add-category-setup-container">
      <div className="add-category-setup-container__body">
        <h4>
          {isNew(id) ? 'Add New Category' : 'Edit Category'}
        </h4>
        <Form onFinish={handleSubmit} form={form} onFinishFailed={() => toast.error('Please fill the entire form')}>
          <div className="add-category-setup-container__body--input-field-wrapper">
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryName"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field is required' },
                  blackListedChars,
                ]}
              >
                <InputField
                  label="Category Name *"
                  placeholder="Category Name"
                />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryEffectiveDate"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                getValueProps={(i) => ({ value: i && moment(i) })}
              >
                <DatePicker label="Category Effective Date *" />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="procedureCategoryTermDate"
                className="formfield"
                getValueProps={(i) => ({ value: i && moment(i) })}
              >
                <DatePicker
                  label="Category Term Date "
                  disabledDate={(d) => moment(d).isBefore(form.getFieldValue('procedureCategoryEffectiveDate'))}
                />
              </Form.Item>
            </div>
          </div>
          <div className="add-category-setup-container__body--input-field-wrapper">
            <div className="label-input-wrapper">
              <Form.Item
                name="isRevPrivate"
                rules={[
                  // { validator: emptyFieldValidator, message: 'This Field is required' },
                ]}
                className="formfield"
                // style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Required for RevPrivate" />
              </Form.Item>
            </div>
            <div className="label-input-wrapper">
              <Form.Item
                name="icon"
                rules={[
                  { validator: emptyFieldValidator, message: 'This Field is required' },
                ]}
                className="formfield"
              >
                <DropDown
                  placeholder="Select"
                  className="rv-supply-dropdown"
                  label="Category Icon *"
                  isOptionsObj
                  options={[
                    { iconLabel: 'House', iconValue: 'home-grey', iconPath: 'House.png' },
                    { iconLabel: 'House-Arrow', iconValue: 'house-down-fill', iconPath: 'HouseArrow.png' },
                    { iconLabel: 'Hospital', iconValue: 'hospital-fill', iconPath: 'Hospital.png' },
                    { iconLabel: 'Sun', iconValue: 'sun', iconPath: 'Sun.png' },
                    { iconLabel: 'Hour-Glass', iconValue: 'hour-glass', iconPath: 'HourGlass.png' },
                    { iconLabel: 'Stack', iconValue: 'stack', iconPath: 'Stack.png' },
                    { iconLabel: 'Stars', iconValue: 'stars', iconPath: 'Stars.png' },
                    { iconLabel: 'Hearts', iconValue: 'hearts', iconPath: 'Hearts.png' },
                    { iconLabel: 'Sheild-Check', iconValue: 'sheild-check', iconPath: 'SheildCheck.png' },
                    { iconLabel: 'Flower', iconValue: 'flower', iconPath: 'Flower.png' },
                    { iconLabel: 'Person-Lines', iconValue: 'person-lines', iconPath: 'PersonLines.png' },
                    { iconLabel: 'Person-Arms', iconValue: 'person-arms', iconPath: 'PersonArms.png' },
                    { iconLabel: 'Person-Check', iconValue: 'person-check', iconPath: 'PersonCheck.png' },
                    { iconLabel: 'People', iconValue: 'people-fill', iconPath: 'Family.png' },
                    { iconLabel: 'Search-Heart', iconValue: 'search-heart', iconPath: 'SearchHeart.png' },
                    { iconLabel: 'Droplet', iconValue: 'droplet-fill', iconPath: 'Droplet.png' },
                    { iconLabel: 'Finger-Print', iconValue: 'finger-print', iconPath: 'FingerPrint.png' },
                    { iconLabel: 'Puzzle', iconValue: 'puzzle', iconPath: 'Puzzle.png' },
                    { iconLabel: 'Key', iconValue: 'key', iconPath: 'Key.png' },
                    { iconLabel: 'Xray', iconValue: 'x-ray', iconPath: 'XRay.png' },
                    { iconLabel: 'Eye', iconValue: 'eye', iconPath: 'Eye.png' },
                    { iconLabel: 'Card-Heart', iconValue: 'card-heart', iconPath: 'CardHeart.png' },
                    { iconLabel: 'Envelop-Heart', iconValue: 'invite-heart', iconPath: 'InviteHeart.png' },
                    { iconLabel: 'IV', iconValue: 'iv-orange', iconPath: 'IVOrange.png' },
                    { iconLabel: 'Egg', iconValue: 'egg', iconPath: 'Egg.png' },
                    { iconLabel: 'Stethoscope', iconValue: 'primary-care', iconPath: 'PrimaryCare.png' },
                    { iconLabel: 'Lab-Vial', iconValue: 'lab-vile', iconPath: 'LabVial.png' },
                    { iconLabel: 'Urgent-Care', iconValue: 'urgent-care', iconPath: 'UrgentCare.png' },
                    { iconLabel: 'Medication', iconValue: 'medication', iconPath: 'Medication.png' },
                    { iconLabel: 'Pharmacy', iconValue: 'pharmacy', iconPath: 'Pharmacy.png' },
                    { iconLabel: 'Gift', iconValue: 'gift', iconPath: 'Loyalty.png' },
                    { iconLabel: 'Star', iconValue: 'star', iconPath: 'star.png' },
                    { iconLabel: 'Trophy', iconValue: 'trophy', iconPath: 'Trophy.png' },
                    { iconLabel: 'Telephone', iconValue: 'telephone', iconPath: 'Telephone.png' },
                    { iconLabel: 'Medical', iconValue: 'medical', iconPath: 'Medical.png' },
                    { iconLabel: 'Virus', iconValue: 'virus', iconPath: 'Virus.png' },
                    { iconLabel: 'Ear', iconValue: 'ear', iconPath: 'Ear.png' },
                    { iconLabel: 'Thermometer', iconValue: 'thermometer', iconPath: 'thermometer.png' },
                    { iconLabel: 'Water', iconValue: 'water', iconPath: 'Water.png' },
                    { iconLabel: 'Spa', iconValue: 'spa', iconPath: 'Spa.png' },
                  ].filter((o) => !categorySetups.docs.map((c) => c.icon)
                    .includes(o.iconValue))
                    .map((i) => ({
                      label: (
                        <span className="iconSelect">
                          <img src={require(`assets/icons/${i.iconPath}`)} alt="icon" />
                          <p>{i.iconLabel}</p>
                        </span>
                      ),
                      value: i.iconValue,
                    }))}
                />
              </Form.Item>
            </div>
            <div className="label-input-wrapper" />
          </div>
          <div className="add-category-setup-container__body--btns-wrapper">
            <div>
              <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            </div>
            {!isNew(id) && (
              <div className="updated-date-wrapper">
                <span>Last Updated:</span>
                <span className="orange">{moment(selectedCategorySetup.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
              </div>
            )}
            <div>
              <Button isLoading={isLoading} variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

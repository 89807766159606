/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Input, Spin } from 'antd';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './_inputField.scss';

function InputField({
  isLoading,
  label,
  type,
  ...props
}) {
  return (
    <div>
      <label>
        {label}
        {isLoading && (<Spin />)}
        {type === 'password' ? (
          <Input.Password
            className="field"
            iconRender={(visible) => (visible ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            )
              : (
                <FontAwesomeIcon icon={faEye} />
              ))}
            {...props}
          />
        ) : (
          <Input className="field" {...props} type={type} />
        )}
      </label>
    </div>
  );
}

export default InputField;

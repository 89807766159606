/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { CATEGORY_SETUPS_API_URL } from './categorySetupUrls';

export const listCategorySetup = createAsyncThunk(
  'categorySetupSlice/listCategorySetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(CATEGORY_SETUPS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getCategorySetup = createAsyncThunk(
  'categorySetupSlice/getcategorySetup',
  async (id) => {
    return axios.get(CATEGORY_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createCategorySetup = createAsyncThunk(
  'categorySetupSlice/createCategorySetup',
  async (params) => {
    return axios.post(CATEGORY_SETUPS_API_URL(), params).then(({ data }) => {
      toast.success('Successfully created category');
      return data;
    });
  },
);

export const updateCategorySetup = createAsyncThunk(
  'categorySetupSlice/updateCategorySetup',
  async ({ id, data: params }) => {
    return axios.patch(CATEGORY_SETUPS_API_URL(id), params)
      .then(({ data }) => {
        toast.success('Category has been updated');
        return data;
      });
  },
);
export const deleteCategorySetup = createAsyncThunk(
  'categorySetupSlice/deleteCategorySetup',
  async (id) => {
    return axios.patch(CATEGORY_SETUPS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Category has been deleted');
      });
  },
);

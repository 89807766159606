import React from 'react';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_symptoms.scss';

function MenuItem({ isActive, title }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''}`}>
      <span>{title}</span>
    </div>
  );
}
function Symptoms() {
  return (
    <div className="dashBoardWrapper">
      <NavTabs>
        <NavTabs.Menu className="subNavMenu">
          <NavTabs.MenuItems isExactPath to={URLS.SYMPTOMS_URL}>
            <MenuItem title="Symptoms" />
          </NavTabs.MenuItems>
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default Symptoms;

import { createSlice } from '@reduxjs/toolkit';
import {
  createMemberFAQCategories,
  deleteMemberFAQCategories,
  getMemberFAQCategories,
  listMemberFAQCategories,
  updateMemberFAQCategories,
} from './memberFAQActions';

const INITIAL_STATE = {
  isLoading: false,
  memberFAQCategories: { docs: [] },
  selectedMemberFAQCategory: {},
};

export const memberFAQSlice = createSlice({
  name: 'memberFAQSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMemberFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMemberFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listMemberFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.memberFAQCategories = action.payload;
    });
    builder.addCase(getMemberFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberFAQCategory = action.payload;
    });
    builder.addCase(createMemberFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMemberFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMemberFAQCategories.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMemberFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberFAQCategory = action.payload.doc;
    });
    builder.addCase(deleteMemberFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMemberFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMemberFAQCategories.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = memberFAQSlice.actions;

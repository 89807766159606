/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import './_addNewBiomarker.scss';
import InputField from 'shared/components/inputField/InputField';
import TextAreaField from 'shared/components/textArea/TextArea';
import DropDown from 'shared/components/dropdown/DropDown';
import Button from 'shared/components/button/Button';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { createBioMarkers, getBioMarkers, updateBioMarkers } from 'redux-store/bioMarkers/bioMarkersActions';
import { clearData } from 'redux-store/bioMarkers/bioMarkersSlice';
import { isNew } from 'shared/utils/global';
import { toast } from 'react-toastify';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import RadioGroup from 'shared/components/radioGroup/RadioGroup';
import { HiddenInput } from 'shared/components/hiddenInput/HiddenInput';
import CustomDatePicker from 'shared/components/datePicker/DatePicker';
import moment from 'moment';

export default function AddNewBiomarker() {
  const { id } = useParams();
  const { isLoading, selectedBioMarker } = useSelector((s) => s.bioMarkers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };

  const handleSubmit = (vals) => {
    vals = applyTrim(vals);
    if (id === 'new') {
      dispatch(createBioMarkers(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateBioMarkers({ id, data: vals }));
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getBioMarkers(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedBioMarker)) {
      form.setFieldsValue(selectedBioMarker);
      forceUpdate((v) => !v);
    } else {
      form.setFieldsValue({
        referenceRanges: [{}],
      });
    }
  }, [selectedBioMarker]);

  return (
    <div className="add-biomarker-container">
      <div className="add-biomarker-container__body">
        <h4>
          {isNew(id) ? 'Add New ' : 'Update '}
          Biomarker
        </h4>
        <Form
          onFinish={handleSubmit}
          form={form}
          onValuesChange={(v) => {
            if (Object.keys(v)[0] === 'dependsOnGender') {
              if (v.dependsOnGender) {
                form.setFieldsValue({ referenceRanges: [{}, {}] });
              } else {
                form.setFieldsValue({ referenceRanges: [{}] });
              }
            }
            forceUpdate((n) => !n);
          }}
          onFinishFailed={() => toast.error('Please fill the form with required fields')}
        >
          <div className="input-field-wrapper">
            <Form.Item
              name="labBiomarkerName"
              className="formfield"
              rules={[
                { validator: emptyFieldValidator, message: 'This field is required' },
                blackListedChars,
              ]}
            >
              <InputField
                label=" Biomarker Name *"
                placeholder=" Biomarker Name"
                maxLength={250}
              />
            </Form.Item>
            <div className="length">
              {form.getFieldValue('labBiomarkerName')?.length || 0}
              /250
            </div>
          </div>
          <div className="input-field-wrapper">
            <Form.Item
              name="description"
              className="formfield"
              rules={[
                { validator: emptyFieldValidator, message: 'This field is required' },
                blackListedChars,
              ]}
            >
              <TextAreaField
                label=" Biomarker Description *"
                placeholder=" Biomarker Description"
                rows={13}
                maxLength={3000}
              />
            </Form.Item>
            <div className="length">
              {form.getFieldValue('description')?.length || 0}
              /3000
            </div>
          </div>
          <div className="biomarker-d-grid">
            <Form.Item
              name="vendorLabBiomarkerId"
              className="formfield"
            // rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
            >
              <InputField
                label="Vendor Biomarker ID "
                placeholder="Vendor Biomarker ID"
                type="number"
              />
            </Form.Item>
            <Form.Item
              name="unit"
              className="formfield"
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
            >
              <DropDown
                label="Biomarker Unit *"
                placeholder="Biomarker Unit"
                className="biomarker-dropdown"
                isOptionsObj
                options={[
                  { value: 'percent', label: 'percent' },
                  { value: 'mg/dL', label: 'mg/dL' },
                  { value: 'U/L', label: 'U/L' },
                  { value: 'pg/mL', label: 'pg/mL' },
                  { value: 'ng/mL', label: 'ng/mL' },
                  { value: 'ng/dL', label: 'ng/dL' },
                  { value: 'ug/dL', label: 'ug/dL' },
                  { value: 'g/dL', label: 'g/dL' },
                  { value: 'nmol/L', label: 'nmol/L' },
                  { value: 'pos/neg', label: 'pos/neg' },
                  { value: 'umol/L', label: 'umol/L' },
                  { value: 'uIU/mL', label: 'uIU/mL' },
                  { value: 'mIU/mL', label: 'mIU/mL' },
                ]}
              />
            </Form.Item>
          </div>

          <div className="biomarker-d-grid">
            <Form.Item
              name="effectiveDate"
              className="formfield"
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              getValueProps={(i) => ({ value: i && moment(i) })}
            >
              <CustomDatePicker label="Effective Date *" />
            </Form.Item>
            <Form.Item
              name="termDate"
              className="formfield"
              getValueProps={(i) => ({ value: i && moment(i) })}
            >
              <CustomDatePicker
                label="Term Date "
                disabledDate={(d) => moment(d).isBefore(form.getFieldValue('effectiveDate'))}
              />
            </Form.Item>
          </div>

          <div>
            <Form.Item
              name="typeOfTest"
              rules={[
                {
                  validator: emptyFieldValidator,
                  message: 'This field is required',
                },
              ]}
            >

              <RadioGroup
                label="Test Type *"
                options={[
                  { label: 'Range', value: 'range' },
                  { label: 'Positive/Negative', value: 'positive/negative' },
                ]}
              />
            </Form.Item>
          </div>

          {form.getFieldValue('typeOfTest') === 'range' && (
            <div>
              <div className="mt-10">
                <Form.Item
                  name="dependsOnGender"
                  rules={[
                    {
                      validator: (m, v) => (typeof (v) !== 'boolean' ? Promise.reject(m) : Promise.resolve()),
                      message: 'This field is required',
                    },
                  ]}
                >
                  <RadioGroup
                    label="Gender Dependent *"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="range-d-flex">
                <Form.List
                  name="referenceRanges"
                  rules={[{
                    validator: (m, v) => Promise.resolve(m, v),
                    message: '',
                  }]}
                >
                  {(fields) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div className="range-wrapper mt-10">
                          <label htmlFor="range">
                            {form.getFieldValue('dependsOnGender') ? (key === 0 ? 'Female ' : 'Male ') : ''}
                            Reference Range
                          </label>
                          <div className="range-flex">
                            <Form.Item
                              {...restField}
                              name={[name, 'low']}
                              className="formfield"
                            >
                              <InputField
                                type="number"
                                placeholder="Low"
                              />
                            </Form.Item>
                            {form.getFieldValue('dependsOnGender') && (
                              <Form.Item
                                {...restField}
                                name={[name, 'gender']}
                              >
                                <HiddenInput finalvalue={key === 0 ? 'Female' : 'Male'} />
                              </Form.Item>
                            )}
                            <Form.Item
                              {...restField}
                              name={[name, 'high']}
                              className="formfield"
                            >

                              <InputField
                                type="number"
                                placeholder="High"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </Form.List>
                {/* <div className="range-wrapper mt-10">
              <label htmlFor="range">Male Reference Range</label>
              <div className="range-flex">
                <Form.Item
                  name="referenceLowRange"
                  className="formfield"
                >

                  <InputField
                    type="number"
                    placeholder="Low"
                  />
                </Form.Item>
                <Form.Item
                  name="referenceHighRange"
                  className="formfield"
                >

                  <InputField
                    type="number"
                    placeholder="High"
                  />
                </Form.Item>
              </div>
            </div> */}
              </div>
              <div className="range-wrapper">
                <label htmlFor="range">Reportable Range</label>
                <div className="range-flex">
                  <Form.Item
                    name="reportableLowRange"
                    className="formfield"
                  >

                    <InputField
                      type="number"
                      placeholder="Low"
                    />
                  </Form.Item>
                  <Form.Item
                    name="reportableHighRange"
                    className="formfield"
                  >

                    <InputField
                      type="number"
                      placeholder="High"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {/* <div className="tri-grid">
            <Form.Item
              name="procedureName"
              className="formfield"
            >
              <InputField
                label=" Red Low End Values"
                placeholder="Red Low End Values"
              />
            </Form.Item>
            <Form.Item
              name="procedureName"
              className="formfield"
            >

              <InputField
                label=" Green Values (Normal)"
                placeholder="Green Values"
              />
            </Form.Item>
            <Form.Item
              name="procedureName"
              className="formfield"
            >
              <InputField
                label=" Red High End Values"
                placeholder="Red High End Values"
              />
            </Form.Item>
          </div> */}
          <div className="action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

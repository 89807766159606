/* eslint-disable jsx-a11y/media-has-caption */
import { Image } from 'antd';
import React from 'react';
import { VIEW_IMAGE, VIEW_VIDEO } from 'shared/constants/viewMediaType';
import Button from '../button/Button';
import './_viewMedia.scss';

export function ViewMedia({
  src,
  type,
  videoType,
  onDelete,
}) {
  return (
    <div className="viewMediaContainer">
      {type === VIEW_IMAGE && (
        <Image
          width={400}
          src={src}
          style={{ objectFit: 'contain' }}
        />
      )}
      {type === VIEW_VIDEO && (
        <video width="400" controls>
          <source src={src} type={videoType} />
          {/* <source src={src} type="video/mov" /> */}
        </video>
      )}
      <Button onClick={onDelete}>Delete</Button>
    </div>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listSymptoms } from 'redux-store/symptoms/symptomsActions';
import Loader from '../loader/Loader';
import './_symptomsMultiSelect.scss';

function SymptomsMultiSelect({
  label,
  placeholder,
  value,
  onChange,
  className,
}) {
  const { symptoms, isLoading } = useSelector((s) => s.symptoms);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listSymptoms({ limit: 0 }));
  }, []);

  return (
    <label htmlFor="image">
      {label}
      {isLoading ? <Loader /> : (
        <Select
          loading={isLoading}
          mode="multiple"
          placeholder={placeholder}
          className={className}
          listHeight={400}
          suffixIcon={(
            <div>
              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.495053 2.42424L5.95521 8.79612C6.04322 8.89877 6.1524 8.98117 6.27525 9.03766C6.3981 9.09415 6.53171 9.1234 6.66693 9.1234C6.80214 9.1234 6.93576 9.09415 7.05861 9.03766C7.18146 8.98117 7.29064 8.89877 7.37865 8.79612L12.8388 2.42424C13.3599 1.81604 12.9279 0.876587 12.1271 0.876587H1.20521C0.404428 0.876587 -0.027603 1.81604 0.495053 2.42424Z" fill="#393B3D" />
              </svg>
            </div>
        )}
          value={value}
          optionFilterProp="label"
          onChange={(v) => {
            onChange(v);
          }}
          style={{ width: '100%' }}
          options={symptoms.docs.map((item) => ({
            value: item.id,
            label: item.symptomName,
          }))}
        />
      )}
    </label>
  );
}

export default SymptomsMultiSelect;

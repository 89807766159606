import { createSlice } from '@reduxjs/toolkit';
import {
  createProviderFAQCategories,
  deleteProviderFAQCategories,
  getProviderFAQCategories,
  listProviderFAQCategories,
  updateProviderFAQCategories,
} from './providerFAQActions';

const INITIAL_STATE = {
  isLoading: false,
  providerFAQCategories: { docs: [] },
  selectedProviderFAQCategory: {},
};

export const providerFAQSlice = createSlice({
  name: 'providerFAQSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providerFAQCategories = action.payload;
    });
    builder.addCase(getProviderFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProviderFAQCategory = action.payload;
    });
    builder.addCase(createProviderFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProviderFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProviderFAQCategories.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProviderFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderFAQCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProviderFAQCategory = action.payload.doc;
    });
    builder.addCase(deleteProviderFAQCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProviderFAQCategories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProviderFAQCategories.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = providerFAQSlice.actions;

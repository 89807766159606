/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  List, Space, Typography, Form, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMessages, listMessages, markThreadRead, refreshMessagesFromInterval,
} from 'redux-store/messages/messagesActions';
import moment from 'moment';
import _ from 'lodash';
import InputWithLabel from 'shared/components/inputWithLabel/InputWithLabel';
import { ProfileImage } from 'shared/components/profileImage/ProfileImage';
import { clearData } from 'redux-store/messages/messagesSlice';
import './_chat.scss';
import uuid from 'react-uuid';
import { emptyFieldValidator } from 'shared/utils/validators';
import { threadIds } from 'shared/utils/thread-ids';
import { USER_TYPE } from 'shared/constants/global';

const { Text } = Typography;

function ChatUI({
  to, complaintId, memberFeedbackId, providerFeedbackId,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.auth.profile);
  const { isLoading, isSendingMessage } = useSelector((state) => state.messages);
  const ref = useRef();
  const intervalRef = useRef();
  const [messages, setMessages] = useState({ docs: [] });
  const [fetchingMessages, setFetchingMessages] = useState(false);

  const getThreadId = () => {
    if (complaintId) {
      return threadIds.memberEncounterComplaint.generate({ complaintId });
    }
    if (memberFeedbackId) {
      return threadIds.memberFeedback.generate({ feedbackId: memberFeedbackId });
    }
    if (providerFeedbackId) {
      return threadIds.providerFeedback.generate({ feedbackId: providerFeedbackId });
    }
    return threadIds.providerToPrm.generate({
      prmId: me?.id,
      providerId: to?.id,
    });
  };

  const INITIAL_PARAMS = {
    limit: 0,
    page: 1,
    'where[threadId][equals]': getThreadId(),
  };
  const [params, setParams] = useState(INITIAL_PARAMS);

  const fetchMessageList = () => {
    setFetchingMessages(true);
    listMessages(params)
      .then((p) => {
        const unreadMessages = p.docs.filter((m) => m.readStatus.find((u) => u.reader === me.id)?.read === false);
        setFetchingMessages(false);
        setMessages(p);
        if (unreadMessages.length) {
          dispatch(markThreadRead(getThreadId()));
        }
      })
      .catch(() => {
        setFetchingMessages(false);
      });
  };

  const sendMessage = (vals) => {
    const threadId = getThreadId();

    const msg = {
      participants: [
        {
          relationTo: 'admins',
          value: me.id,
        },
        {
          relationTo: complaintId ? 'members' : 'providers',
          value: to.id,
        },
      ],
      messageOwner: me.id,
      messageText: vals.message,
      threadId,
    };

    dispatch(createMessages(msg))
      .then((payload) => {
        if (!payload.error) {
          form.resetFields();

          // if (!messages.length) {
          fetchMessageList();
          // }
        }
      });
  };
  // const handleScroll = (e) => {
  //   e.stopPropagation();
  //   const top = (e.target.scrollHeight + e.target.scrollTop) === (e.target.clientHeight + 1);
  //   if (!isLoading && top) {
  //     setParams({ ...params, page: params.page + 1 });
  //   }
  // };

  // eslint-disable-next-line arrow-body-style
  const getMessageOwner = (message) => {
    const owner = _.get(message, 'participants', []).find((p) => p?.value?.id === message?.messageOwner)?.value;
    return {
      firstName: owner.firstName || owner?.demographics?.firstName,
      lastName: owner.lastName || owner?.demographics?.lastName,
    };
  };

  useEffect(() => {
    fetchMessageList();
  }, [params, to]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      fetchMessageList();
      // dispatch(refreshMessagesFromInterval(INITIAL_PARAMS));
    }, 3000);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => () => dispatch(clearData()), []);

  return (
    <div className="chatContainer">
      <div className="chat-header">
        <ProfileImage user={to} />
      </div>
      <List>
        <div
          className="ant-list-items"
        // onScroll={handleScroll}
        >
          {_.get(messages, 'docs', []).map((message) => (
            <List.Item style={{ display: 'flex', justifyContent: _.get(message, 'messageOwner') !== me.id ? 'flex-start' : 'flex-end' }}>
              <Space direction={_.get(message, 'messageOwner') !== me.id ? 'end' : 'start'}>
                <div>
                  <Text strong style={{ marginRight: '5px' }}>{`${getMessageOwner(message)?.firstName || ''} ${getMessageOwner(message)?.middleName || ''} ${getMessageOwner(message)?.lastName || ''}`}</Text>
                  <Text type="secondary">{`${moment(_.get(message, 'createdAt')).format('MM/DD/YYYY hh:mm A')}`}</Text>
                  <div style={{ maxWidth: 300 }} className={_.get(message, 'messageOwner') !== me.id ? 'user-message' : 'bot-message reveive-msg'}>
                    {_.get(message, 'messageText')}
                  </div>
                </div>
              </Space>
            </List.Item>
          ))}
          <div ref={ref} style={{ height: 10 }} />
        </div>
      </List>
      <Form
        form={form}
        onFinish={sendMessage}
      >
        <div className="relative-div">
          <Form.Item
            name="message"
            style={{ margin: 0, flex: 1 }}
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
            ]}
          >
            <InputWithLabel
              type="text"
              placeholder="Enter a Message"
              label="Enter a Message"
            />
          </Form.Item>
          <div ref={ref} className="submt-btn-icon" onClick={form.submit}>
            {isSendingMessage ? (
              <Spin />
            ) : (
              <SendMessageIcon />
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ChatUI;

function SendMessageIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6168 8.8696L18.6105 8.86687L1.92773 1.94734C1.78741 1.88861 1.63472 1.86557 1.48332 1.8803C1.33192 1.89502 1.18653 1.94705 1.06016 2.03171C0.926639 2.1192 0.816966 2.23851 0.741006 2.37891C0.665046 2.5193 0.625182 2.67638 0.625 2.83601V7.2614C0.625074 7.47962 0.701273 7.69098 0.840463 7.85905C0.979653 8.02712 1.17311 8.14137 1.3875 8.1821L10.4863 9.86453C10.5221 9.8713 10.5543 9.89035 10.5776 9.91838C10.6008 9.9464 10.6135 9.98165 10.6135 10.018C10.6135 10.0544 10.6008 10.0897 10.5776 10.1177C10.5543 10.1457 10.5221 10.1648 10.4863 10.1716L1.38789 11.854C1.17356 11.8946 0.980114 12.0087 0.840862 12.1766C0.70161 12.3445 0.625273 12.5558 0.625 12.7739V17.2001C0.624896 17.3525 0.662638 17.5026 0.734839 17.6368C0.80704 17.7711 0.91144 17.8853 1.03867 17.9692C1.19172 18.0709 1.37133 18.1252 1.55508 18.1255C1.68282 18.1254 1.80925 18.0997 1.92695 18.0501L18.6094 11.17L18.6168 11.1665C18.8414 11.07 19.0327 10.9098 19.1671 10.7056C19.3016 10.5015 19.3732 10.2625 19.3732 10.018C19.3732 9.77362 19.3016 9.53457 19.1671 9.33045C19.0327 9.12633 18.8414 8.96611 18.6168 8.8696Z" fill="#78797A" />
    </svg>
  );
}

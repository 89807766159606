import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session, { BooleanClaim } from 'supertokens-auth-react/recipe/session';

const SecondFactorClaim = new BooleanClaim({
  id: '2fa-completed',
  refresh: async () => {
    // This is something we have no way of refreshing, so this is a no-op
  },
  onFailureRedirection: () => '/second-factor',
});

export const SuperTokensConfig = {
  appInfo: {
    appName: 'revdoc-admin',
    apiDomain: process.env.REACT_APP_API_URL,
    websiteDomain: process.env.REACT_APP_WEBSITE_URL,
    apiBasePath: process.env.REACT_APP_API_BASE_PATH,
    websiteBasePath: process.env.REACT_APP_WEBSITE_BASE_PATH,
  },
  recipeList: [
    EmailPassword.init({
      getRedirectionURL: async (context) => {
        if (context.action === 'SUCCESS') {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return '/second-factor'; // context.redirectToPath;
          }
          return '/second-factor';
        }
        return '/second-factor';// undefined;
      },
    }),
    Session.init({
      override: {
        functions: (oI) => ({
          ...oI,
          getGlobalClaimValidators: ({ claimValidatorsAddedByOtherRecipes }) => ([
            ...claimValidatorsAddedByOtherRecipes,
            SecondFactorClaim.validators.isTrue(),
          ]),
        }),
      },
    }),
  ],
};

export const PROCEDURE_IMAGE = 'Procedure Image';
export const PROCEDURE_PDF = 'Procedure PDF';
export const PROCEDURE_VIDEO = 'Procedure Video';
export const PROFILE_PHOTO = 'Profile Photo';
export const PROVIDER_PHOTO = 'Provider Photo';
export const PROVIDER_VIDEO = 'Provider Video';
export const SUPPLY_IMAGE = 'Supply Image';
export const STAFF_PHOTO = 'Staff Photo';
export const PRM_PHOTO = 'PRM Photo';
export const STAFF_VIDEO = 'Staff Video';
export const PRM_VIDEO = 'PRM Video';
export const AT_HOME_LAB_IMAGE = 'At-Home Lab Image';
export const AT_HOME_LAB_PDF = 'At-Home Lab PDF';
export const AT_HOME_LAB_VIDEO = 'At-Home Lab Video';
export const HOW_TO_VIDEO = 'How-To Video';

export const PROVIDER_STATUS = [
  {
    label: 'Registered (Background check pending)',
    value: 'registered',
  },
  {
    label: 'Needs Supervisor (NP/PAs only)',
    value: 'needs-supervisor',
  },
  {
    label: 'Pending PRM Review (PRM to complete checklist)',
    value: 'prm-review',
  },
  {
    label: 'Active (Live on app/available to see patients)',
    value: 'active',
  },
  {
    label: 'Inactive (Provider inactivated themselves)',
    value: 'inactive',
  },
  {
    label: 'Terminated (Provider profile closed by PRM)',
    value: 'terminated',
  },
];

export const DELIVERY_METHODS = {
  'facility': 'Facility',
  'van': 'Van Call',
  'house-call': 'House Call',
  'telemedicine': 'Telemedicine',
};

export const USER_TYPE = {
  user: 1,
  provider: 2,
  prm: 3,
};

export const READ_STATUS = [
  {
    label: 'Read',
    value: true,
  },
  {
    label: 'Unread',
    value: false,
  },
];

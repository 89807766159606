import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { listMyProviders, updateMultipleMyProviders } from 'redux-store/myProviders/myProvidersActions';
import { clearData } from 'redux-store/myProviders/myProvidersSlice';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import Grid from 'shared/components/grid/Grid';
import { AssignedUserProvidersGridColumns } from 'utils/grid/columns';
import ReassignModal from './ReassignModal';

export default function AssignedProvidersList() {
  const [showReassign, setShowReassign] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const INITIAL_PARAMS = { limit: 10, page: 1, searchText: '' };
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { myProviders } = useSelector((state) => state.myProviders);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const fetchListOfProviders = () => {
    dispatch(listMyProviders({
      ...params,
      'where[providerRelationshipManager][in][0]': id,
    }));
  };
  const backToListPage = () => {
    navigate(`${URLS.USERS_URL}`);
  };

  const onCheck = (row, checked) => {
    setSelectedRows(
      _.union(
        selectedRows.filter((v) => v.id !== row.id),
        [row].filter(() => checked),
      ),
    );
  };
  const onSelectAll = () => {
    if (selectedRows?.length === myProviders?.docs?.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        myProviders.docs,
      );
    }
  };

  const onSelect = (row) => {
    setShowReassign();
    dispatch(updateMultipleMyProviders({
      params: {
        providerRelationshipManager: row.id,
      },
      query: selectedRows.reduce((acc, o, i) => {
        acc[`where[id][in][${i}]`] = o.id;
        return acc;
      }, {}),
    })).then((payload) => {
      if (!payload.error) {
        fetchListOfProviders();
      }
    });
  };

  const ProviderNameClick = (item) => {
    navigate(`${URLS.MY_PROVIDERS_URL}/${item.id}`);
  };

  useEffect(() => {
    fetchListOfProviders();
  }, [params]);

  useEffect(() => () => dispatch(clearData()), []);

  return (
    <div className="provider-list-container">
      <div className="provider-list-container__header">
        <div className="my-provider-container__header">
          <span onClick={backToListPage}>Users</span>
          <VectorSign />
          <h4>{`${_.get(location.state, 'firstName', '')} ${_.get(location.state, 'middleName', '')}, ${_.get(location.state, 'lastName', '')}`}</h4>
        </div>
        {selectedRows.length > 0 && (
          <Button variant={Button.variant.filled} onClick={() => setShowReassign(true)}>
            <div className="btn-flex">
              ReAssign Providers
            </div>
          </Button>
        )}
      </div>
      <div className="provider-list-container__body assign-provider-grid">
        <h3 className="assign-provider-grid__h3">Assigned Providers</h3>
        <Grid
          columns={AssignedUserProvidersGridColumns(
            selectedRows,
            (item) => ProviderNameClick(item),
            onCheck,
            onSelectAll,
            selectedRows?.length === myProviders?.docs?.length ? 'Deselect All' : 'Select All',
          )}
          data={myProviders.docs}
          pagination={{
            total: myProviders.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      {showReassign && (<ReassignModal onCancel={() => setShowReassign()} onSelect={onSelect} />)}
    </div>
  );
}

function VectorSign() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.1875 1.375L6.8125 7L1.1875 12.625" stroke="#78797A" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

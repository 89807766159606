/* eslint-disable arrow-body-style */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { MEDICATION_SET_API_URL } from './medicationSetUrls';

export const listMedicationSet = createAsyncThunk(
  'medicationSetsSlice/listMedicationSet',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios
      .get(MEDICATION_SET_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getMedicationSet = createAsyncThunk(
  'medicationSetsSlice/getMedicationSet',
  async (id) => {
    return axios.get(MEDICATION_SET_API_URL(id)).then(({ data }) => data);
  },
);

export const createMedicationSet = createAsyncThunk(
  'medicationSetsSlice/createMedicationSet',
  async (params) => {
    return axios.post(MEDICATION_SET_API_URL(), params).then(({ data }) => {
      toast.success('Successfully created medication sets');
      return data;
    });
  },
);

export const updateMedicationSet = createAsyncThunk(
  'medicationSetsSlice/updateMedicationSet',
  async ({ id, data: params }) => {
    return axios.patch(MEDICATION_SET_API_URL(id), params).then(({ data }) => {
      toast.success('Medication Sets has been updated');
      return data;
    });
  },
);

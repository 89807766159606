import { createSlice } from '@reduxjs/toolkit';
import {
  createQuickLinks,
  deleteQuickLinks,
  getQuickLinks,
  listQuickLinkSection,
  listQuickLinks,
  updateQuickLinks,
} from './quickLinksActions';

const INITIAL_STATE = {
  isLoading: false,
  isLoadingSections: false,
  isDeleteing: false,
  quickLinks: { docs: [] },
  quickLinkSections: { docs: [] },
  selectedQuickLinks: {},
};

export const quickLinksSlice = createSlice({
  name: 'quickLinksSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listQuickLinks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listQuickLinks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listQuickLinks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.quickLinks = action.payload;
    });
    builder.addCase(listQuickLinkSection.pending, (state) => {
      state.isLoadingSections = true;
    });
    builder.addCase(listQuickLinkSection.rejected, (state) => {
      state.isLoadingSections = false;
    });
    builder.addCase(listQuickLinkSection.fulfilled, (state, action) => {
      state.isLoadingSections = false;
      state.quickLinkSections = action.payload;
    });
    builder.addCase(getQuickLinks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getQuickLinks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getQuickLinks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedQuickLinks = action.payload;
    });
    builder.addCase(createQuickLinks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createQuickLinks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createQuickLinks.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateQuickLinks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateQuickLinks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateQuickLinks.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteQuickLinks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteQuickLinks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteQuickLinks.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = quickLinksSlice.actions;

/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../button/Button';
import CountiesDropDown from '../statesDropDown/CountiesDropDown';
// import DropDown from '../dropdown/DropDown';
import StatesDropDown from '../statesDropDown/StatesDropDown';
import './_countiesDropDown.scss';

function StateCountiesDropDown({
  value = [],
  onChange,
  multiple = true,
  placeholder,
}) {
  return (
    <div>
      {value.map((obj, i) => (
        <div className="stateCountyContainer">
          <StatesDropDown
            value={obj.stateCode}
            onChange={(v) => {
              obj.stateCode = v;
              obj.counties = [];
              onChange([...value]);
            }}
            placeholder="Select State"
          />
          <CountiesDropDown
            multiple={multiple}
            value={_.get(obj, 'counties', [])}
            onChange={(v) => {
              obj.counties = v;
              onChange([...value]);
            }}
            state={obj.stateCode}
            placeholder={placeholder || 'Select Counties'}
          />
          {i > 0 && (
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                // eslint-disable-next-line prefer-const
                let interim = JSON.parse(JSON.stringify(value));
                interim.splice(i, 1);
                onChange([...interim]);
              }}
              icon={faTrash}
            />
          )}
        </div>
      ))}
      {multiple && (
        <Button
          variant={Button.variant.filled}
          onClick={() => {
            onChange([...value, {}]);
          }}
        >
          + Add New
        </Button>
      )}
    </div>
  );
}

export default StateCountiesDropDown;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { PROVIDER_TERMS_API_URL } from './providerTermsUrls';

export const listProviderTerms = createAsyncThunk(
  'providerTermsSlice/listProviderTerms',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROVIDER_TERMS_API_URL(`versions?${query}`)).then(({ data }) => data);
  },
);

export const getProviderTermsLive = createAsyncThunk(
  'providerTermsSlice/getProviderTermsLive',
  async () => {
    return axios.get(PROVIDER_TERMS_API_URL()).then(({ data }) => data);
  },
);

export const getProviderTerms = createAsyncThunk(
  'providerTermsSlice/getProviderTerms',
  async (id) => {
    return axios.get(PROVIDER_TERMS_API_URL(`versions/${id}`)).then(({ data }) => data);
  },
);

export const createProviderTerms = createAsyncThunk(
  'providerTermsSlice/createProviderTerms',
  async (params) => {
    return axios.post(
      PROVIDER_TERMS_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      params,
    ).then(({ data }) => {
      toast.success('Provider Terms has been created');
      return data;
    });
  },
);

export const updateProviderTerms = createAsyncThunk(
  'providerTermsSlice/updateProviderTerms',
  async ({ id, params }) => {
    return axios.post(
      PROVIDER_TERMS_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      { ...params, id },
    ).then(({ data }) => {
      toast.success('Provider Terms has been updated');
      return data;
    });
  },
);

export const deleteProviderTerms = createAsyncThunk(
  'providerTermsSlice/deleteProviderTerms',
  async (id, { dispatch }) => {
    return axios.patch(PROVIDER_TERMS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Provider Terms has been deleted');
        return dispatch(listProviderTerms());
      });
  },
);

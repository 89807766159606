/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clearResetPasswordData } from 'redux-store/auth/authSlice';
import { URLS } from 'routes';
import '../_authentication.scss';
import EmailForm from './components/emailForm/EmailForm';
import MessageForm from './components/messageForm/MessageForm';
import PasswordForm from './components/passwordForm/PasswordForm';

function PasswordReset() {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const { linkEmailed, passwordChanged } = useSelector((state) => state.auth.resetPassword);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const rid = searchParams.get('rid');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToLogin = () => {
    navigate(URLS.LOGIN_URL);
  };

  useEffect(() => {
    dispatch(clearResetPasswordData());
  }, []);

  const getForm = () => {
    if (passwordChanged) {
      return (
        <MessageForm
          actionText="Continue to log in"
          title="Password Reset"
          description="You have successfully reset your password!"
          showAction
          action={goToLogin}
        />
      );
    }
    if (token) {
      if (showPasswordForm) {
        return (<PasswordForm />);
      }
      return (
        <MessageForm
          actionText="Reset Password"
          title="Reset Password"
          description="Click the button below if you made this request."
          action={() => setShowPasswordForm(true)}
        />
      );
    }
    if (linkEmailed) {
      return (
        <MessageForm
          title="A password reset email has been sent to the email address."
          description="Please follow the instructions in the email to reset your password."
          actionText="Go Back To Login"
          action={goToLogin}
        />
      );
    }
    return (<EmailForm />);
  };

  return (
    <div className="container">
      {getForm()}
    </div>
  );
}

export default PasswordReset;

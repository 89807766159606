import _ from 'lodash';

/* eslint-disable no-unused-vars */
export const emptyFieldValidator = (m, v) => {
  if (v) {
    if (typeof (v) === 'string' && !v.trim()) {
      return Promise.reject(m);
    }
    if (typeof (v) === 'object' && _.isEmpty(v)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const failSafeEmptyFieldValidator = (m, v) => {
  if (v !== null || v !== undefined) {
    if (typeof (v) === 'string' && !v.trim()) {
      return Promise.reject(m);
    }
    if (typeof (v) === 'object' && _.isEmpty(v)) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const passwordValidator = (m, v) => {
  if (!v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(v)) {
    return Promise.resolve();
  }
  return Promise.reject(m);
};

export const applyTrim = (vals = {}) => Object
  .entries(vals)
  .reduce((acc, [key, val]) => {
    acc[key] = typeof (val) === 'string' ? val.trim() : val;
    return acc;
  }, {});

export const blackListedChars = { pattern: /^[^~^*]*$/, message: 'This field does not allow ~ ^ * ' };
export const allowOnlyNumbers = { pattern: /^[0-9]+$/, message: 'This field only allows numbers ' };

import React from 'react';
import { Table } from 'antd';
import './_grid.scss';

function Grid({
  columns,
  data,
  expandable,
  ...props
}) {
  return (
    <Table
      columns={columns}
      dataSource={data}
      expandable={expandable}
      {...props}
    />
  );
}

export default Grid;

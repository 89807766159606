import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getMyProviders } from 'redux-store/myProviders/myProvidersActions';
import { URLS } from 'routes';
import { ProviderBanner } from 'shared/components/providerBanner/ProviderBanner';
import About from './About';
import Delivery from './Delivery';
import EducationCareer from './EducationCareer';

export default function Providers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getMyProviders(id));
  }, []);

  return (
    <div className="my-provider-container">
      <ProviderBanner
        fromPage={{
          label: 'My Providers',
          onClick: () => navigate(`${URLS.MY_PROVIDERS_URL}`),
        }}
      />
      <About />
      <EducationCareer />
      <Delivery />
    </div>
  );
}

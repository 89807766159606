/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

// eslint-disable-next-line no-unused-vars
import { MEMBER_TERMS_API_URL } from './memberTermsUrls';

export const listMemberTerms = createAsyncThunk(
  'memberTermsSlice/listMemberTerms',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(MEMBER_TERMS_API_URL(`versions?${query}`)).then(({ data }) => data);
  },
);

export const getMemberTermsLive = createAsyncThunk(
  'memberTermsSlice/getMemberTermsLive',
  async () => {
    return axios.get(MEMBER_TERMS_API_URL()).then(({ data }) => data);
  },
);

export const getMemberTerms = createAsyncThunk(
  'memberTermsSlice/getMemberTerms',
  async (id) => {
    return axios.get(MEMBER_TERMS_API_URL(`versions/${id}`)).then(({ data }) => data);
  },
);

export const createMemberTerms = createAsyncThunk(
  'memberTermsSlice/createMemberTerms',
  async (params) => {
    return axios.post(
      MEMBER_TERMS_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      params,
    ).then(({ data }) => {
      toast.success('Member Terms has been created');
      return data;
    });
  },
);

export const updateMemberTerms = createAsyncThunk(
  'memberTermsSlice/updateMemberTerms',
  async ({ id, params }) => {
    return axios.post(
      MEMBER_TERMS_API_URL(params.type === 'draft' ? '?draft=true' : ''),
      { ...params, id },
    ).then(({ data }) => {
      toast.success('Member Terms has been updated');
      return data;
    });
  },
);

export const deleteMemberTerms = createAsyncThunk(
  'memberTermsSlice/deleteMemberTerms',
  async (id, { dispatch }) => {
    return axios.patch(MEMBER_TERMS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Member Terms has been deleted');
        return dispatch(listMemberTerms());
      });
  },
);

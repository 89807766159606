/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import {
  PRM_PHOTO,
  PRM_VIDEO,
  STAFF_PHOTO,
  STAFF_VIDEO,
} from 'shared/constants/global';

import { COUNTIES_API_URL, SUPER_TOKEN_USER_CREATE_API_URL, USER_MANAGEMENT_SETUP_API_URL } from './userManagementSetupUrls';

export const listUserApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(USER_MANAGEMENT_SETUP_API_URL(`?${query}`)).then(({ data }) => data);
};

export const listUserManagementSetup = createAsyncThunk(
  'userManagementSetupSlice/listUserManagementSetup',
  async (filters) => {
    return listUserApiCall(filters);
  },
);

export const getUserManagementSetup = createAsyncThunk(
  'userManagementSetupSlice/getUserManagementSetup',
  async (id) => {
    return axios.get(USER_MANAGEMENT_SETUP_API_URL(id)).then(({ data }) => data);
  },
);

export const createSuperTokenUser = async (params) => {
  return axios.post(
    SUPER_TOKEN_USER_CREATE_API_URL(),
    params,
  ).then(({ data }) => {
    return data;
  }).catch((e) => {
    toast.error(e?.response?.data?.detail);
    return Promise.reject();
  });
};

export const createUserManagementSetup = createAsyncThunk(
  'userManagementSetupSlice/createUserManagementSetup',
  async (params) => {
    const supertokenuser = await createSuperTokenUser({
      email: params.revdocEmail,
      first_name: params.firstName,
      last_name: params.lastName,
      phone: params.mobilePhoneNumber,
    });
    return axios.post(
      USER_MANAGEMENT_SETUP_API_URL(),
      { ...params, id: _.get(supertokenuser, '[0].user_info.user_id') },
    ).then(({ data }) => {
      toast.success('User has been created');
      return data;
    });
  },
);

export const updateUserManagementSetup = createAsyncThunk(
  'userManagementSetupSlice/updateUserManagementSetup',
  async ({ id, params }) => {
    let imgID = _.get(params, 'profilePhoto');
    let videoID = _.get(params, 'profileVideo');

    if (!_.isEmpty(params.profilePhoto) && typeof (params.profilePhoto) === 'object') {
      const img = await createMedia(
        params.profilePhoto,
        params.isPRM ? PRM_PHOTO : STAFF_PHOTO,
      );
      imgID = img.doc.id;
    }
    if (!_.isEmpty(params.profileVideo) && typeof (params.profileVideo) === 'object') {
      const video = await createMedia(
        params.profileVideo,
        params.isPRM ? PRM_VIDEO : STAFF_VIDEO,
      );
      videoID = video.doc.id;
    }
    return axios.patch(
      USER_MANAGEMENT_SETUP_API_URL(id),
      {
        ...params,
        profilePhoto: imgID,
        profileVideo: videoID,
      },
    ).then(({ data }) => {
      toast.success('User has been updated');
      return data;
    });
  },
);

export const deleteUserManagementSetup = createAsyncThunk(
  'userManagementSetupSlice/deleteUserManagementSetup',
  async (id, { dispatch }) => {
    return axios.patch(USER_MANAGEMENT_SETUP_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('User has been deleted');
        return dispatch(listUserManagementSetup());
      });
  },
);

export const listCounties = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(COUNTIES_API_URL(`?${query}`)).then(({ data }) => data);
};
export const listStates = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(COUNTIES_API_URL(`unique-states?${query}`)).then(({ data }) => data);
};

import { createSlice } from '@reduxjs/toolkit';
import {
  createComplaints,
  deleteComplaints,
  getComplaints,
  listComplaints,
  updateComplaints,
} from './complaintsActions';

const INITIAL_STATE = {
  isLoading: false,
  complaints: {},
  selectedComplaints: {},
};

export const complaintsSlice = createSlice({
  name: 'complaintsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listComplaints.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listComplaints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.complaints = action.payload;
    });
    builder.addCase(getComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getComplaints.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getComplaints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedComplaints = action.payload;
    });
    builder.addCase(createComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createComplaints.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createComplaints.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateComplaints.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateComplaints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedComplaints = action.payload.doc;
    });
    builder.addCase(deleteComplaints.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteComplaints.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteComplaints.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = complaintsSlice.actions;

import React, { useState } from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { sendPasswordResetEmail } from 'redux-store/auth/authActions';
import { doesEmailExist } from 'supertokens-web-js/recipe/emailpassword';
import { emptyFieldValidator } from 'shared/utils/validators';
import appIcon from '../../../../../assets/imgs/logo.png';
import '../../../_authentication.scss';
import '../../_passwordReset.scss';

function EmailForm() {
  const [checkingEmail, setCheckingEmail] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmit = (vals) => {
    dispatch(sendPasswordResetEmail(vals));
  };

  return (
    <div className="formBox emailformBox">
      <img src={appIcon} alt="" width={212} height={48} />
      <div className="resetWelcomeText">
        {/* <h2>
          Welcome to RevDoc
        </h2> */}
        <p>
          Enter your email address and we will send you a link to reset your password.
        </p>
      </div>
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="email"
          className="formfield"
          validateTrigger="onBlur"
          rules={[
            { validator: emptyFieldValidator, message: 'This field is required' },
            { type: 'email', message: 'This is not a valid email' },
            {
              message: 'This email does not exist',
              type: 'email',
              validator: (_, email) => {
                if (!email) return Promise.resolve();
                setCheckingEmail(true);
                return doesEmailExist({ email })
                  .then((res) => {
                    setCheckingEmail(false);
                    if (res.doesExist) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  }).catch((err) => {
                    setCheckingEmail(false);
                    if (err.isSuperTokensGeneralError === true) {
                      return Promise.reject(new Error(err.message));
                    }
                    return Promise.reject(new Error('Oops! Something went wrong.'));
                  });
              },
            },
          ]}
        >
          <InputField isLoading={checkingEmail} label="Email Address" placeholder="Email Address" />
        </Form.Item>
        <div className="actionContainer">
          <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">
            Send link to email
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EmailForm;

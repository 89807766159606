import { createSlice } from '@reduxjs/toolkit';
import {
  createProviderTerms,
  deleteProviderTerms,
  getProviderTerms,
  getProviderTermsLive,
  listProviderTerms,
  updateProviderTerms,
} from './providerTermsActions';

const INITIAL_STATE = {
  isLoading: false,
  providerTerms: { docs: [] },
  selectedProviderTerms: {},
  liveProviderTerms: {},
};

export const providerTermsSlice = createSlice({
  name: 'providerTermsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providerTerms = action.payload;
    });
    builder.addCase(getProviderTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProviderTerms = action.payload;
    });
    builder.addCase(createProviderTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProviderTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProviderTerms.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProviderTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProviderTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProviderTerms = action.payload.doc;
    });
    builder.addCase(deleteProviderTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProviderTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProviderTerms.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderTermsLive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderTermsLive.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderTermsLive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.liveProviderTerms = action.payload;
    });
    return builder;
  },
});

export const {
  clearData,
} = providerTermsSlice.actions;

import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { URLS } from 'routes';
import NoAccess from 'shared/components/noAccess/NoAccess';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_suggestion.scss';

function MenuItem({ isActive, title, className }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''} ${className ? 'tabs-heading-wrapper__heading' : ''}`}>
      <span>{title}</span>
    </div>
  );
}
function Suggestion() {
  const params = useParams();
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  if (!Object.values(access).find((v) => v)) {
    return (
      <NoAccess />
    );
  }
  return (
    <div className={params?.id ? 'dashBoardWrapper suggestion-detail-wrapper' : 'dashBoardWrapper'}>
      <div className="tabs-heading-wrapper">
        <NavTabs>
          <NavTabs.Menu className="subNavMenu">
            {(access?.memberFeedback) && (
              <NavTabs.MenuItems isExactPath to={URLS.SUGGESTION_MEMBER_URL}>
                <MenuItem title="Member Feedback" />
              </NavTabs.MenuItems>
            )}
            {(access?.providerFeedback) && (
              <NavTabs.MenuItems isExactPath to={URLS.SUGGESTION_PROVIDER_URL}>
                <MenuItem title="Provider Feedback" />
              </NavTabs.MenuItems>
            )}
          </NavTabs.Menu>
          <NavTabs.Content>
            <Outlet />
          </NavTabs.Content>
        </NavTabs>
      </div>
    </div>
  );
}

export default Suggestion;

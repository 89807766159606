/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'routes';
import { getFeedbacks, updateFeedbacks } from 'redux-store/feedbacks/feedbacksActions';
import { VectorSign } from 'shared/components/providerBanner/ProviderBanner';
import DropDown from 'shared/components/dropdown/DropDown';
import moment from 'moment';
import ChatUI from '../../../prmDashboard/components/providerList/Chat';
import '../../_banner.scss';

export default function ProviderFeedbackDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedFeedback } = useSelector((s) => s.feedbacks);

  useEffect(() => {
    dispatch(getFeedbacks(id));
  }, []);

  return (
    <div className="suggestion-container">
      <div>
        <div className="profile-banner-container__header">
          <span onClick={() => navigate(`${URLS.SUGGESTION_PROVIDER_URL}`)}>Feedback</span>
          <VectorSign />
          <h4>
            {`Date Received: ${_.get(selectedFeedback, 'createdAt', '') ? moment(_.get(selectedFeedback, 'createdAt', '')).format('MM/DD/YYYY') : null}`}
            {' '}
            <span className="seprator">• </span>
            {' '}
            {` Received From: ${_.get(selectedFeedback, 'ownerProfile.value.fullName', '')},  ${_.get(selectedFeedback, 'ownerProfile.value.providerType.providerTypeName', '')}`}
          </h4>
        </div>
        <div className="profile-banner-container__body">
          <div className="profile-banner-container__body--header">
            <div className="left">
              <div className="left-left">
                {_.get(selectedFeedback, 'ownerProfile.value.biography.providerPhoto.url', '') && (
                  <img
                    src={_.get(selectedFeedback, 'ownerProfile.value.biography.providerPhoto.url', '')}
                    alt=""
                    width={80}
                    height={80}
                  />
                )}
                <div className="info">
                  <h3>
                    {`${_.get(selectedFeedback, 'ownerProfile.value.fullName', '')}, ${_.get(selectedFeedback, 'ownerProfile.value.providerType.providerTypeName', '')}`}
                  </h3>

                  <div className="address">
                    {`
                ${_.get(selectedFeedback, 'ownerProfile.value.homeAddress.city', '')}, 
                ${_.get(selectedFeedback, 'ownerProfile.value.homeAddress.stateCode', '')}
              `}
                  </div>
                  <div className="phone">
                    {_.get(selectedFeedback, 'ownerProfile.value.mobilePhoneNumber', '')
                      ? ` ${_.get(selectedFeedback, 'ownerProfile.value.mobilePhoneNumber', '')}`
                      : ' '}
                  </div>
                  <div className="phone">
                    {_.get(selectedFeedback, 'ownerProfile.value.email', '')
                      ? ` ${_.get(selectedFeedback, 'ownerProfile.value.email', '')}`
                      : ' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <label>
                Feedback Status
                <DropDown
                  placeholder="Select"
                  className="provider-list-dropdown"
                  value={_.get(selectedFeedback, 'resolution.resolutionDecision', '')}
                  onChange={(v) => {
                    dispatch(updateFeedbacks({
                      id,
                      data: { resolution: { resolutionDecision: v } },
                    }))
                      .then(() => {
                        dispatch(getFeedbacks(id));
                      });
                  }}
                  options={['Awaiting Review', 'In Process', 'Resolved', 'Denied']}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="suggestion-container__body">
        <div className="feedback">
          <div className="suggestion-container__body__header">
            <span className="lbl_feedback">Feedback Details</span>
            <h4>
              {`Date Received: ${_.get(selectedFeedback, 'createdAt', '') ? moment(_.get(selectedFeedback, 'createdAt', '')).format('MM/DD/YYYY') : null}`}
              {' '}
              <span className="seprator">• </span>
              {' '}
              {` Received From: ${_.get(selectedFeedback, 'ownerProfile.value.fullName', '')}, ${_.get(selectedFeedback, 'ownerProfile.value.providerType.providerTypeName', '')}`}
            </h4>
          </div>
          <div className="suggestion-container__body__content">
            {selectedFeedback?.feedbackBody || ''}
          </div>
        </div>
        <div style={{ paddingLeft: 10 }}>
          <div className="suggestion-container__body__header">
            <span className="lbl_feedback">Message Provider</span>
          </div>
          <ChatUI
            to={{ ..._.get(selectedFeedback, 'ownerProfile.value', {}) }}
            providerFeedbackId={id}
          />
        </div>
      </div>

    </div>
  );
}

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { QUICK_LINKS_API_URL, QUICK_LINK_SECTIONS_API_URL } from './quickLinksUrls';

export const listQuickLinks = createAsyncThunk(
  'quickLinksSlice/listQuickLinks',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(QUICK_LINKS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const listQuickLinkSection = createAsyncThunk(
  'quickLinksSlice/listQuickLinkSection',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(QUICK_LINK_SECTIONS_API_URL(`?${query}`))
      .then(({ data }) => data);
  },
);

export const getQuickLinks = createAsyncThunk(
  'quickLinksSlice/getquickLinks',
  async (id) => {
    return axios.get(QUICK_LINKS_API_URL(id)).then(({ data }) => data);
  },
);

export const createQuickLinks = createAsyncThunk(
  'quickLinksSlice/createQuickLinks',
  async (params) => {
    return axios.post(QUICK_LINKS_API_URL(), params).then(({ data }) => {
      toast.success('Successfully created quick link');
      return data;
    });
  },
);

export const updateQuickLinks = createAsyncThunk(
  'quickLinksSlice/updateQuickLinks',
  async (vals, { dispatch }) => {
    const newVals = vals.filter((v) => !v.id && !v.deletedInfoField.isDeleted)
      .map((o) => axios.post(QUICK_LINKS_API_URL(), o));
    const oldVals = vals.filter((v) => v.id && !v.deletedInfoField.isDeleted)
      .map((o) => axios.patch(QUICK_LINKS_API_URL(o.id), o));
    return Promise.all([...newVals, ...oldVals])
      .then(({ data }) => {
        toast.success('Quick Link has been updated');
        dispatch(listQuickLinks());
        return data;
      });
  },
);
export const deleteQuickLinks = createAsyncThunk(
  'quickLinksSlice/deleteQuickLinks',
  async (id, { dispatch }) => {
    return axios.patch(QUICK_LINKS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Quick Link has been deleted');
        return dispatch(listQuickLinks());
      });
  },
);

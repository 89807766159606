import { createSlice } from '@reduxjs/toolkit';
import {
  createMyProviders,
  deleteMyProviders,
  getMyProviders,
  listMyProviders,
  updateMultipleMyProviders,
  updateMyProviders,
  getSupervisors,
  updateConciergePractices,
  getAvailabilities,
  getSuperVisionRequest,
} from './myProvidersActions';

const INITIAL_STATE = {
  isLoading: false,
  myProviders: { docs: [] },
  selectedMyProviders: {},
  supervisors: { docs: [] },
  supervisionRequests: { docs: [] },
  availability: { docs: [] },
};

export const myProvidersSlice = createSlice({
  name: 'myProvidersSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listMyProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.myProviders = action.payload;
    });
    builder.addCase(getMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMyProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMyProviders = action.payload;
    });
    builder.addCase(createMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMyProviders.fulfilled, (state) => {
      state.isLoading = false;
      // state.selectedMyProviders = action.payload.doc;
    });
    builder.addCase(updateMultipleMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMultipleMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleMyProviders.fulfilled, (state) => {
      state.isLoading = false;
      // state.selectedMyProviders = action.payload.doc;
    });
    builder.addCase(deleteMyProviders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMyProviders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMyProviders.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSupervisors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupervisors.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSupervisors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supervisors = action.payload;
    });
    builder.addCase(getSuperVisionRequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuperVisionRequest.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSuperVisionRequest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supervisionRequests = action.payload;
    });
    builder.addCase(updateConciergePractices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateConciergePractices.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateConciergePractices.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAvailabilities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAvailabilities.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAvailabilities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.availability = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = myProvidersSlice.actions;

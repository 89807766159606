/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { MESSAGES_API_URL } from './messagesUrls';

export const refreshMessagesFromInterval = createAsyncThunk(
  'messagesSlice/refreshMessagesFromInterval',
  async (filters, { getState }) => {
    const { docs } = getState().messages.messages;
    const query = new URLSearchParams(filters).toString();
    return axios.get(MESSAGES_API_URL(`?${query}`)).then(({ data }) => {
      const allMessages = [...data.docs, ...docs];
      return ({ ...data, docs: _.uniqBy(allMessages, 'id') });
    });
  },
);

export const listMessages = async (
  filters,
  // , { getState }
) => {
  // const { docs } = getState().messages.messages;
  const query = new URLSearchParams(filters).toString();
  // return axios.get(MESSAGES_API_URL(`?${query}`)).then(({ data }) =>
  // ({ ...data, docs: [...docs, ...data.docs] }));
  return axios.get(MESSAGES_API_URL(`?${query}`)).then(({ data }) => data);
};

export const getMessagesInbox = createAsyncThunk(
  'messagesSlice/getMessagesInbox',
  async (
    filters,
    // , { getState }
  ) => {
    // const { docs } = getState().messages.messages;
    const query = new URLSearchParams(filters).toString();
    // return axios.get(MESSAGES_API_URL(`?${query}`)).then(({ data }) =>
    // ({ ...data, docs: [...docs, ...data.docs] }));
    return axios.get(MESSAGES_API_URL(`inbox/?${query}`)).then(({ data }) => data);
  },
);

export const getMessages = createAsyncThunk(
  'messagesSlice/getMessages',
  async (id) => {
    return axios.get(MESSAGES_API_URL(id)).then(({ data }) => data);
  },
);

export const createMessageApiCall = (params) => {
  return axios.post(
    MESSAGES_API_URL(),
    params,
  );
};

export const createMessages = createAsyncThunk(
  'messagesSlice/createMessages',
  async (params) => {
    return createMessageApiCall(params).then(({ data }) => {
      toast.success('Message has been sent');
      return data;
    });
  },
);

export const updateMessages = createAsyncThunk(
  'messagesSlice/updateMessages',
  async ({ id, params }) => {
    return axios.patch(
      MESSAGES_API_URL(id),
      params,
    ).then(({ data }) => {
      toast.success('Message has been updated');
      return data;
    });
  },
);

export const markThreadRead = createAsyncThunk(
  'messagesSlice/markThreadRead',
  async (threadId) => {
    return axios.post(
      MESSAGES_API_URL(`mark-thread-as-read/${threadId}`),
    ).then(({ data }) => {
      return data;
    });
  },
);

export const deleteMessages = createAsyncThunk(
  'messagesSlice/deleteMessages',
  async (id, { dispatch }) => {
    return axios.patch(MESSAGES_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Message has been deleted');
        return dispatch(listMessages());
      });
  },
);

import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

function VerifyAccess({ name, children }) {
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  const isLoading = useSelector((s) => s.auth?.isLoading);

  return _.get(access, name, false)
    ? (children)
    : (!isLoading && <h2>Forbiden</h2>);
}
export default VerifyAccess;

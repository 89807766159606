import _ from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getChecklists } from 'shared/utils/checklist';
import chatTick from '../../../assets/imgs/chatTick.png';

export function ProfileImage({ user }) {
  const { supervisors } = useSelector((state) => state.myProviders);

  const verificationchecklist = useMemo(
    () => getChecklists(user),
    [supervisors],
  );

  return (
    <div className="img-text-wrapper">
      <div className="img">
        {!Object.entries(verificationchecklist || {})
          .find(([, checklist]) => !checklist.verified) && (
            <div className="chat-tick"><img src={chatTick} alt="" width={16} height={16} /></div>)}
        <img src={user?.biography?.providerPhoto?.url} alt="" width={40} height={40} />
      </div>
      <span className="text">
        {`${_.get(user, 'firstName', '')} ${_.get(user, 'middleName', '')} ${_.get(user, 'lastName', '')}`}
        {_.get(user, 'providerType.providerTypeName', '') ? `, ${_.get(user, 'providerType.providerTypeName', '')}` : ''}
      </span>
    </div>
  );
}

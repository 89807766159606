import React, { useEffect, useState } from 'react';
import './_addNewMedicationSet.scss';
import Grid from 'shared/components/grid/Grid';
import { AddNewProcedureGridColumns } from 'utils/grid/columns';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { listProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';

const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
  sort: '-updatedAt',
};
export default function AddNewProcedureList({ value, onChange }) {
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { proceduresSetups } = useSelector((state) => state.proceduresSetup);

  useEffect(() => {
    if (value) {
      setSelectedRows([value]);
    }
  }, [value]);

  const onSelect = (row, selected) => {
    if (selected) {
      setSelectedRows([row]);
    } else {
      setSelectedRows([]);
    }
  };

  const onAdd = () => {
    onChange(selectedRows);
  };

  useEffect(() => {
    dispatch(listProceduresSetup({ ...params }));
  }, [params]);

  return (
    <div className="added-procedure-container">
      <div className="added-procedure-container__body">
        <Grid
          columns={AddNewProcedureGridColumns(onSelect, selectedRows)}
          data={proceduresSetups.docs}
          pagination={{
            total: proceduresSetups.totalDocs,
            current: params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <Button
        variant={Button.variant.filled}
        onClick={onAdd}
        disabled={!selectedRows.length}
      >
        Add Procedure
      </Button>
    </div>
  );
}

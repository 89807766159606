/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { DatePicker } from 'antd';
import React from 'react';
import momentPickerConfig from 'rc-picker/lib/generate/moment';
import './_datePicker.scss';

const MomentDatePicker = DatePicker.generatePicker(momentPickerConfig);

function CustomDatePicker({
  isLoading,
  label,
  placeholder,
  ...props
}) {
  return (
    <div className="custom-ant-date-picker">
      <label className="add-cat-label" htmlFor="termDate">
        {label}
      </label>
      <MomentDatePicker
        placeholder={placeholder}
        format="MM/DD/YYYY"
        {...props}
        suffixIcon={(
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 4.99994C18.75 4.3369 18.4866 3.70102 18.0178 3.23218C17.5489 2.76333 16.913 2.49994 16.25 2.49994H15.625V1.89252C15.625 1.55619 15.3664 1.26752 15.0301 1.25072C14.9456 1.24665 14.8611 1.25978 14.7818 1.28931C14.7026 1.31884 14.6301 1.36417 14.5688 1.42253C14.5076 1.48089 14.4588 1.55108 14.4255 1.62885C14.3922 1.70662 14.375 1.79034 14.375 1.87494V2.49994H5.625V1.89252C5.625 1.55619 5.36641 1.26752 5.03008 1.25072C4.94557 1.24665 4.86112 1.25978 4.78184 1.28931C4.70255 1.31884 4.63009 1.36417 4.56884 1.42253C4.50759 1.48089 4.45883 1.55108 4.42551 1.62885C4.39219 1.70662 4.37501 1.79034 4.375 1.87494V2.49994H3.75C3.08696 2.49994 2.45107 2.76333 1.98223 3.23218C1.51339 3.70102 1.25 4.3369 1.25 4.99994V5.46869C1.25 5.51013 1.26646 5.54988 1.29576 5.57918C1.32507 5.60848 1.36481 5.62494 1.40625 5.62494H18.5938C18.6352 5.62494 18.6749 5.60848 18.7042 5.57918C18.7335 5.54988 18.75 5.51013 18.75 5.46869V4.99994ZM1.25 16.2499C1.25 16.913 1.51339 17.5489 1.98223 18.0177C2.45107 18.4865 3.08696 18.7499 3.75 18.7499H16.25C16.913 18.7499 17.5489 18.4865 18.0178 18.0177C18.4866 17.5489 18.75 16.913 18.75 16.2499V7.03119C18.75 6.98975 18.7335 6.95001 18.7042 6.92071C18.6749 6.8914 18.6352 6.87494 18.5938 6.87494H1.40625C1.36481 6.87494 1.32507 6.8914 1.29576 6.92071C1.26646 6.95001 1.25 6.98975 1.25 7.03119V16.2499Z" fill="#78797A" />
          </svg>
        )}
      />
    </div>
  );
}

export default CustomDatePicker;

import React, { useEffect, useState } from 'react';
import Grid from 'shared/components/grid/Grid';
import { MedicationProcedureGridColumns } from 'utils/grid/columns';
import Button from 'shared/components/button/Button';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import AddNewProcedureList from './AddNewProcedureList';

export default function LinkedProcedure({
  value,
  onChange,
  procedureActionClickHanlder,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [procedure, setProcedure] = useState([]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      setProcedure([value]);
    } else {
      setProcedure([]);
    }
  }, [value]);

  function linkProcedure() {
    setDialogOpen(true);
  }

  const onSelect = (v) => {
    onChange(v?.[0] || null);
    setDialogOpen(false);
  };

  return (
    <>
      <div className="input-field-wrapper">
        <label htmlFor="procedure_link_btn">Procedure to link to *</label>
        <br />
        <Button
          id="procedure_link_btn"
          onClick={linkProcedure}
          variant={Button.variant.filled}
          disabled={!!value}
        >
          Add Procedure
        </Button>
      </div>
      <br />
      <div className="input-field-wrapper">
        <label htmlFor="linked_procedures">Linked Procedures</label>
        <Grid
          columns={MedicationProcedureGridColumns({
            ActionClickHanlder: (i) => {
              switch (i) {
                case 'edit':
                  setDialogOpen(true);
                  break;
                default:
                  return procedureActionClickHanlder(i);
              }
              return null;
            },
          })}
          data={procedure}
          pagination={false}
        />
      </div>
      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen}
        title="Select Procedure"
        handleOk={() => {}}
        handleCancel={() => setDialogOpen(false)}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <AddNewProcedureList value={value} onChange={onSelect} />
      </DialogPopup>
    </>
  );
}

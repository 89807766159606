import { createSlice } from '@reduxjs/toolkit';
import {
  createHowTos,
  deleteHowTos,
  updateHowTos,
  listHowTos,
  getHowTos,
} from './howTosActions';

const INITIAL_STATE = {
  isLoading: false,
  howTos: { docs: [] },
  selectedHowTo: {},
};

export const howToSlice = createSlice({
  name: 'howToSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listHowTos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listHowTos.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listHowTos.fulfilled, (state, action) => {
      state.isLoading = false;
      state.howTos = action.payload;
    });
    builder.addCase(getHowTos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHowTos.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getHowTos.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedHowTo = action.payload;
    });
    builder.addCase(createHowTos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createHowTos.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createHowTos.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateHowTos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateHowTos.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateHowTos.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedHowTo = action.payload.doc;
    });
    builder.addCase(deleteHowTos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHowTos.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteHowTos.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = howToSlice.actions;

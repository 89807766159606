import { createSlice } from '@reduxjs/toolkit';
import {
  createBioMarkers,
  deleteBioMarkers,
  getBioMarkers,
  listBioMarkers,
  updateBioMarkers,
} from './bioMarkersActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  bioMarkers: { docs: [] },
  selectedBioMarker: {},
};

export const bioMarkersSlice = createSlice({
  name: 'bioMarkersSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listBioMarkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listBioMarkers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listBioMarkers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bioMarkers = action.payload;
    });
    builder.addCase(getBioMarkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBioMarkers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBioMarkers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedBioMarker = action.payload;
    });
    builder.addCase(createBioMarkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createBioMarkers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createBioMarkers.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBioMarkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBioMarkers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBioMarkers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedBioMarker = action.payload.doc;
    });
    builder.addCase(deleteBioMarkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteBioMarkers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteBioMarkers.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = bioMarkersSlice.actions;

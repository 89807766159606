import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateComplaints } from 'redux-store/complaints/complaintsActions';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import TextAreaField from 'shared/components/textArea/TextArea';
import { emptyFieldValidator } from 'shared/utils/validators';

export default function ResolutionDetail({ onCancel }) {
  const { selectedComplaints } = useSelector((state) => state.complaints);
  const disptach = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = (vals) => {
    disptach(updateComplaints({ id: selectedComplaints?.id, params: vals }))
      .then((p) => {
        if (!p.error) {
          onCancel();
        }
      });
  };

  useEffect(() => {
    form.setFieldsValue(selectedComplaints);
  }, [selectedComplaints]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
    >
      <div className="resolution-detail-container">
        <div className="input-field-wrapper">
          <label htmlFor="res">Resolution Decision *</label>
          <Form.Item
            name={['resolution', 'resolutionDecision']}
            rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
          >
            <DropDown
              placeholder="Resolution Decision"
              className="resolution-dropdown"
              options={[
                'Awaiting Review',
                'In Process',
                'Resolved',
                'Denied',
              ]}
            />
          </Form.Item>
        </div>
        <div className="input-field-wrapper">
          <Form.Item
            name={['resolution', 'resolutionBody']}
            rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
          >
            <TextAreaField label="Note *" rows={10} />
          </Form.Item>
        </div>
        <div className="resolution-detail-container__action-btn">
          <Button variant={Button.variant.outlined} onClick={onCancel}>Cancel</Button>
          <Button variant={Button.variant.filled} htmlType="submit">Save</Button>
        </div>
      </div>
    </Form>
  );
}

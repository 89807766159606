/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import {
  AT_HOME_LAB_IMAGE,
  AT_HOME_LAB_PDF,
  AT_HOME_LAB_VIDEO,
} from 'shared/constants/global';

import { AT_HOME_LABS_API_URL } from './atHomeLabsUrls';

export const listAtHomeLab = createAsyncThunk(
  'atHomeLabSlice/listAtHomeLab',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(AT_HOME_LABS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getAtHomeLab = createAsyncThunk(
  'atHomeLabSlice/getAtHomeLab',
  async (id) => {
    return axios.get(AT_HOME_LABS_API_URL(id)).then(({ data }) => data);
  },
);

export const createAtHomeLab = createAsyncThunk(
  'atHomeLabSlice/createAtHomeLab',
  async (params) => {
    let pdf = null;
    let img = null;
    let video = null;
    if (_.get(params, 'labImage', null)) {
      img = await createMedia(params.labImage, AT_HOME_LAB_IMAGE);
    }
    if (_.get(params, 'labVideo', null)) {
      video = await createMedia(params.labVideo, AT_HOME_LAB_VIDEO);
    }
    if (_.get(params, 'labPdf', null)) {
      pdf = await createMedia(params.labPdf, AT_HOME_LAB_PDF);
    }

    return axios.post(
      AT_HOME_LABS_API_URL(),
      {
        ...params,
        labImage: _.get(img, 'doc.id'),
        labVideo: _.get(video, 'doc.id'),
        labPdf: _.get(pdf, 'doc.id'),
      },
    ).then(({ data }) => {
      toast.success('At Home Lab has been created');
      return data;
    });
  },
);

export const updateAtHomeLab = createAsyncThunk(
  'atHomeLabSlice/updateAtHomeLab',
  async ({ id, params }) => {
    let videoID = _.get(params, 'labVideo', null);
    let imgID = _.get(params, 'labImage', null);
    let pdfID = _.get(params, 'labPdf', null);

    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.labImage, AT_HOME_LAB_IMAGE);
      imgID = img.doc.id;
    }
    if (videoID && typeof (videoID) === 'object') {
      const video = await createMedia(params.labVideo, AT_HOME_LAB_VIDEO);
      videoID = video.doc.id;
    }
    if (pdfID && typeof (pdfID) === 'object') {
      const pdf = await createMedia(params.labPdf, AT_HOME_LAB_PDF);
      pdfID = pdf.doc.id;
    }
    return axios.patch(
      AT_HOME_LABS_API_URL(id),
      {
        ...params,
        labImage: imgID,
        labVideo: videoID,
        labPdf: pdfID,
      },
    ).then(({ data }) => {
      toast.success('At Home Lab has been updated');
      return data;
    });
  },
);

export const deleteAtHomeLab = createAsyncThunk(
  'atHomeLabSlice/deleteAtHomeLab',
  async (id, { dispatch }) => {
    return axios.patch(AT_HOME_LABS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('At Home Lab has been deleted');
        return dispatch(listAtHomeLab());
      });
  },
);

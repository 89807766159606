/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Button as AntButton } from 'antd';
import './_button.scss';

function Button({ variant, ...props }) {
  return (
    <AntButton className={`revdocButton ${variant}`} {...props} />
  );
}
Button.variant = { filled: 'filled', outlined: 'outlined' };
Button.propTypes = AntButton.propTypes;

export default Button;

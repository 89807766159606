/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { listStates } from 'redux-store/userManagementSetup/userManagementSetupActions';
import DropDown from '../dropdown/DropDown';

function StatesDropDown({
  value,
  placeholder,
  onChange,
}) {
  const [states, setStates] = useState([]);
  const [isStatesLoading, setIsStatesLoading] = useState();

  useEffect(() => {
    setIsStatesLoading(true);
    listStates({ limit: 0, page: 1 })
      .then((data) => {
        setStates(data.states.sort((a, b) => a.stateName.localeCompare(b.stateName)));
        setIsStatesLoading(false);
      })
      .catch(() => {
        setIsStatesLoading();
      });
  }, []);
  return (
    <>
      <DropDown
        placeholder={placeholder}
        isOptionsObj
        className="rv-user-dropdown"
        onChange={onChange}
        value={value}
        options={states.map(({ stateCode, stateName }) => ({
          value: stateCode,
          label: stateName,
        }))}
      />
      {isStatesLoading && <Spin />}

    </>
  );
}

export default StatesDropDown;

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from 'shared/components/button/Button';
import appIcon from '../../../../../assets/imgs/logo.png';
import '../../../_authentication.scss';
import '../../_passwordReset.scss';

function MessageForm({
  title,
  description,
  actionText,
  action,
}) {
  return (
    <div className="formBox messageBox">
      <img src={appIcon} alt="" width={212} height={48} />
      <div className="messageBody">
        <div className="resetWelcomeText">
          <h2>
            <FontAwesomeIcon icon={faCircleCheck} className="checkIcon" />
            {title}
          </h2>
          <p>
            {description}
          </p>
        </div>
        {action && (
          <div className="actionContainer">
            <Button variant={Button.variant.filled} htmlType="submit" onClick={action}>
              {actionText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageForm;

import { createSlice } from '@reduxjs/toolkit';
import {
  listProviderAppoinments,
} from './appoinmentActions';

const INITIAL_STATE = {
  isLoading: false,
  appoinments: { docs: [] },
};

export const appoinments = createSlice({
  name: 'appoinments',
  initialState: INITIAL_STATE,
  reducers: {
    clearAppoinmentData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderAppoinments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderAppoinments.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderAppoinments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.appoinments = {
        ...action.payload,
        docs: state.appoinments.docs.concat(action.payload.docs),
      };
    });

    return builder;
  },
});

export const {
  clearAppoinmentData,
} = appoinments.actions;

import React, { useEffect, useState } from 'react';
import './_addNewBiomarker.scss';
import Grid from 'shared/components/grid/Grid';
import { AddNewBiomarkerGridColumns } from 'utils/grid/columns';
import { useDispatch, useSelector } from 'react-redux';
import { listBioMarkers } from 'redux-store/bioMarkers/bioMarkersActions';
import Button from 'shared/components/button/Button';
import { getTermedFiltersOnField } from 'shared/utils/filters';

export default function AddNewBiomarker({ value, onChange }) {
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    sort: '-updatedAt',
    ...getTermedFiltersOnField('effectiveDate', 'termDate'),
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { bioMarkers } = useSelector((state) => state.bioMarkers);

  const onSelect = (row, selected) => {
    if (selected) {
      setSelectedRows([...selectedRows.filter((x) => x.id !== row.id), row]);
    } else {
      setSelectedRows([...selectedRows.filter((x) => x.id !== row.id)]);
    }
  };

  const onAdd = () => {
    onChange(selectedRows);
  };

  useEffect(() => {
    dispatch(listBioMarkers(params));
  }, [params]);

  useEffect(() => {
    setSelectedRows(value || []);
  }, [value]);

  return (
    <div className="added-biomarker-container">
      <div className="added-biomarker-container__body">
        <Grid
          columns={
            AddNewBiomarkerGridColumns(onSelect, selectedRows)
          }
          data={bioMarkers.docs}
          pagination={{
            total: bioMarkers.totalDocs,
            current: params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <Button variant={Button.variant.filled} onClick={onAdd}>Add Biomarkers</Button>
    </div>
  );
}

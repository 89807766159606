/* eslint-disable max-len */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import NoAccess from 'shared/components/noAccess/NoAccess';
import { useSelector } from 'react-redux';
import MenuItem from '../common/menuItem/MenuItem';
import procedureMgmtIconActive from '../../assets/imgs/procedureMgmt-icon-active.png';
import procedure from '../../assets/imgs/procedure.png';
import cardIconActive from '../../assets/imgs/card.png';
import cardInactive from '../../assets/imgs/cardInactive.png';
import handshakeIconActive from '../../assets/imgs/handshakeactive.png';
import familyIconActive from '../../assets/imgs/family.png';
import familyIconInActive from '../../assets/imgs/familyInactive.png';
import handshakeIcon from '../../assets/imgs/handshake.png';
import medicalIcon from '../../assets/imgs/medical.png';
import medicalIconActive from '../../assets/imgs/medicalIconActive.png';
import specialityIcon from '../../assets/imgs/speciality.png';
import specialitySelected from '../../assets/imgs/specialitySelected.png';
import contentActive from '../../assets/imgs/contentActive.png';
import contentInactive from '../../assets/imgs/contentInactive.png';
import suggestionActive from '../../assets/imgs/send.png';
import suggestionInactive from '../../assets/imgs/sendinactive.png';
import './_dashboard.scss';

function Dashboard() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  const { alerts } = useSelector((s) => s.alerts);

  if (!Object.values(access).find((v) => v)) {
    return (
      <NoAccess />
    );
  }
  return (
    <div className="dashBoardContainer">
      <NavTabs>
        <NavTabs.Menu className="navMenu">
          {access?.userManagement && (
            <NavTabs.MenuItems to={URLS.USERS_URL}>
              <MenuItem icon={familyIconInActive} activeIcon={familyIconActive} title="Users Mgmt." />
            </NavTabs.MenuItems>
          )}
          {(access?.procedureSetup || access?.suppliesSetup || access?.categorySetup || access?.labs || access?.biomarkerSetup || access?.medicationSetSetup || access?.quicklinksSetup) && (
            <NavTabs.MenuItems to={URLS.PROCEDURE_MGMT_URL}>
              <MenuItem icon={procedure} activeIcon={procedureMgmtIconActive} title="Procedure Mgmt." />
            </NavTabs.MenuItems>
          )}
          {access?.insurancePlans && (
            <NavTabs.MenuItems to={URLS.INSURANCE_PLAN_URL}>
              <MenuItem icon={cardInactive} activeIcon={cardIconActive} title="Insurance Plan" />
            </NavTabs.MenuItems>
          )}
          {access?.specialistSetup && (
            <NavTabs.MenuItems to={URLS.SPECIALTY_URL}>
              <MenuItem icon={specialityIcon} activeIcon={specialitySelected} title="Speciality" />
            </NavTabs.MenuItems>
          )}
          {access?.symptoms && (
            <NavTabs.MenuItems to={URLS.SYMPTOM_URL}>
              <MenuItem icon={medicalIcon} activeIcon={medicalIconActive} title="Symptoms" />
            </NavTabs.MenuItems>
          )}
          {(access?.profileSetup || access?.myProviders || access?.complaints) && (
            <NavTabs.MenuItems to={URLS.PRM_DASHBOARD_URL}>
              <MenuItem icon={handshakeIcon} activeIcon={handshakeIconActive} title="PRM" counter={alerts.docs?.filter((a) => a.isRead === false).length || ''} />
            </NavTabs.MenuItems>
          )}
          {(
            access?.providerFaq
            || access?.providerTerms
            || access?.providerHowTos
            || access?.memberFaq
            || access?.memberTerms
            || access?.memberHowTos
            || access?.privacyPolicy
            || access?.howToPrepare
          )
            && (
              <NavTabs.MenuItems
                to={URLS.CONTENT_DASHBOARD_URL}
              >
                <MenuItem icon={contentInactive} activeIcon={contentActive} title="Content" />
              </NavTabs.MenuItems>
            )}
          {(
            access?.memberFeedback
            || access?.providerFeedback
          )
            && (
              <NavTabs.MenuItems
                to={URLS.SUGGESTION_DASHBOARD_URL}
              >
                <MenuItem
                  icon={suggestionInactive}
                  activeIcon={suggestionActive}
                  title="Suggestions"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                />
              </NavTabs.MenuItems>
            )}
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default Dashboard;

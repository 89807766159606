import { createSlice } from '@reduxjs/toolkit';
import {
  createCategorySetup,
  deleteCategorySetup,
  getCategorySetup,
  listCategorySetup,
  updateCategorySetup,
} from './categorySetupActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  categorySetups: { docs: [] },
  selectedCategorySetup: {},
};

export const categorySetupSlice = createSlice({
  name: 'categorySetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listCategorySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listCategorySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listCategorySetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categorySetups = action.payload;
    });
    builder.addCase(getCategorySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategorySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCategorySetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedCategorySetup = action.payload;
    });
    builder.addCase(createCategorySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createCategorySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createCategorySetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCategorySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCategorySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCategorySetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCategorySetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCategorySetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCategorySetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = categorySetupSlice.actions;

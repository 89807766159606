/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteSuppliesSetup, listSuppliesSetup } from 'redux-store/suppliesSetup/suppliesSetupActions';
import { URLS } from 'routes';
// import AddNewSuppliesSetup from './components/AddNewSupply';
import { SuppliesSetupGridColumns } from '../../../../../../utils/grid/columns';
import './_suppliesSetup.scss';

function SuppliesSetupList() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, 'where[or][0][supplyName][like]': '', sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { suppliesSetups } = useSelector((state) => state.suppliesSetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.SUPPLIES_SETUP_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.SUPPLIES_SETUP_URL}/${id}`);
  };

  const SuppliesIdClickHandler = (item) => {
    editClick(item?.id);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteSuppliesSetup(item.id));
    }
  };
  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      'where[or][0][supplyName][like]': v,
    });
  };
  useEffect(() => {
    dispatch(listSuppliesSetup(params));
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by Supply name " onChange={onSearch} waitTime={1300} />
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New Supply
          </Button>
        </div>
        <Grid
          columns={
            SuppliesSetupGridColumns(
              { SuppliesIdClick: (item) => SuppliesIdClickHandler(item) },
              { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            )
          }
          pagination={{
            total: suppliesSetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
          data={suppliesSetups.docs}
        // expandable={{
        //   isExpandable: true,
        //   expandedRowRender: (record) => (
        //     <AddNewSuppliesSetup data={record} />
        //   ),
        // }}
        />
      </div>
    </div>
  );
}

export default SuppliesSetupList;

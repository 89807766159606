/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { SYMPTOMS_API_URL } from './symptomsUrls';

export const listSymptoms = createAsyncThunk(
  'symptomsSlice/listSymptoms',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(SYMPTOMS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getSymptoms = createAsyncThunk(
  'symptomsSlice/getSymptoms',
  async (id) => {
    return axios.get(SYMPTOMS_API_URL(id)).then(({ data }) => data);
  },
);

export const createSymptom = createAsyncThunk(
  'symptomsSlice/createSymptom',
  async (params) => {
    return axios.post(
      SYMPTOMS_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Symptom has been created');
      return data;
    });
  },
);

export const updateSymptom = createAsyncThunk(
  'symptomsSlice/updateSymptom',
  async ({ id, params }) => {
    return axios.patch(
      SYMPTOMS_API_URL(id),
      params,
    ).then(({ data }) => {
      toast.success('Symptom has been updated');
      return data;
    });
  },
);

export const deleteSymptoms = createAsyncThunk(
  'symptomsSlice/deleteSymptoms',
  async (id) => {
    return axios.patch(SYMPTOMS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Symptom has been deleted');
      });
  },
);

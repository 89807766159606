import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import SearchBox from 'shared/components/searchbox/SearchBox';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import { deleteUserManagementSetup, listUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { useDispatch, useSelector } from 'react-redux';
import { sendPasswordResetEmail } from 'redux-store/auth/authActions';
import _ from 'lodash';
import { clearData } from 'redux-store/userManagementSetup/userManagementSetupSlice';
import { UsersGridColumns } from '../../../../utils/grid/columns';
import './_userManagement.scss';
import ResetPassword from './components/resetPassword/resetPassword';

function UserManagement() {
  const INITIAL_PARAMS = { limit: 10, page: 1, searchText: '' };
  // eslint-disable-next-line no-unused-vars
  const [filterKey, setFilterKey] = useState('where[or][0][firstName][like]');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [user, setUser] = useState();
  const { userManagementSetups } = useSelector((state) => state.userManagementSetups);
  const me = useSelector((state) => state.auth.profile);
  const { isLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.USERS_URL}/new`);
  };

  const editClick = (id) => {
    navigate(`${URLS.USERS_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      searchText: v,
    });
  };
  const onNameClick = (u) => {
    navigate(`${URLS.USERS_URL}/${u.id}/providers`, { state: u });
  };
  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteUserManagementSetup(item.id));
    } else if (identifier === 'key') {
      setUser(item);
    }
  };

  const resetUserPassword = () => {
    dispatch(sendPasswordResetEmail({ email: user.revdocEmail })).then((payload) => {
      if (!payload.error) {
        setUser(null);
      }
    });
  };

  useEffect(() => {
    dispatch(listUserManagementSetup({ ...params, [filterKey]: params.searchText, sort: '-updatedAt' }));
  }, [params]);

  useEffect(() => () => dispatch(clearData()), []);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by  .." waitTime={1300} onChange={onSearch} />
          <div>
            <DropDown
              placeholder="Filter By"
              className="rv-setup-dropdown"
              isOptionsObj
              value={filterKey}
              onChange={setFilterKey}
              options={[
                { label: 'First Name', value: 'where[or][0][firstName][like]' },
                { label: 'Middle Name', value: 'where[or][0][middleName][like]' },
                { label: 'Last Name', value: 'where[or][0][lastName][like]' },
                { label: 'Revdoc ID', value: 'where[or][0][revDocId][like]' },
              ]}
            />
          </div>
          {!me.isPrm && (
            <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
              + Add New User
            </Button>
          )}
        </div>
        <Grid
          columns={
            UsersGridColumns(
              (identifier, item) => ActionClickHanlder(identifier, item),
              onNameClick,
            )
          }
          data={userManagementSetups.docs.filter((u) => u.id !== me.id)}
          pagination={{
            total: userManagementSetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      {

      }
      <DialogPopup
        open={!_.isEmpty(user)}
        title="Reset Password"
        handleOk={resetUserPassword}
        handleCancel={() => setUser()}
        cancelBtn="Cancel"
        showActionButton
        saveBtn="Send Link to Email"
        okLoading={isLoading}
      >
        <ResetPassword isSendClick={false} />
      </DialogPopup>
    </div>
  );
}

export default UserManagement;

import { createSlice } from '@reduxjs/toolkit';
import {
  getProviderAlerts,
  listProviderAlerts, updateAlerts, updateMultipleAlerts,
} from './alertsActions';

const INITIAL_STATE = {
  isLoading: false,
  alerts: { docs: [] },
  providerAlerts: { docs: [] },
};

export const alerts = createSlice({
  name: 'alerts',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.alerts = {
        ...action.payload,
        docs: action.payload?.docs?.map((x) => ({ ...x, key: x.id })),
      };
    });
    builder.addCase(getProviderAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProviderAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProviderAlerts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providerAlerts = action.payload;
    });
    builder.addCase(updateAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateAlerts.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleAlerts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMultipleAlerts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMultipleAlerts.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = alerts.actions;

/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';

import Grid from 'shared/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInsuranceSetup, listInsuranceSetup } from 'redux-store/insuranceSetup/insuranceSetupActions';
import { InsuranceGridColumns } from '../../../../../../utils/grid/columns';
import './_insurancePlansList.scss';

function InsurancePlansList() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, 'where[or][0][company][like]': '', sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { insuranceSetups } = useSelector((state) => state.insuranceSetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.INSURANCE_PLANS_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.INSURANCE_PLANS_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      limit: 10,
      page: 1,
      'where[or][0][company][like]': v,
    });
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteInsuranceSetup(item.id));
    }
  };

  useEffect(() => {
    dispatch(listInsuranceSetup(params));
  }, [params]);

  return (
    <div className="revdoc-setup-container">
      <div className="revdoc-setup-container__header">
        <SearchBox onChange={onSearch} placeholder="Search by Insurance Organization Name " />
        <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
          + Add New Insurance Organization
        </Button>
      </div>
      <Grid
        columns={
          InsuranceGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )
        }
        data={insuranceSetups.docs}
        pagination={{
          total: insuranceSetups.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
      />
    </div>
  );
}

export default InsurancePlansList;

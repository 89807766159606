/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { clearData } from 'redux-store/userManagementSetup/userManagementSetupSlice';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import InputField from 'shared/components/inputField/InputField';
import StateCountiesDropDown from 'shared/components/stateCountiesDropDown/StateCountiesDropDown';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import { ProviderReassignmentPRMColumns } from 'utils/grid/columns';
import './_reassignModal.scss';

function ReassignModal({ onCancel, onSelect }) {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { userManagementSetups } = useSelector((state) => state.userManagementSetups);
  const [params, setParams] = useState({
    'where[firstName][like]': '',
    'where[lastName][like]': '',
    'where[revDocId][like]': '',
    'where[isPrm][like]': true,
    ...getTermedFiltersOnField('startDate', 'endDate'),
    limit: 10,
    page: 1,
  });
  const { id } = useParams();

  const onSubmit = (vals) => {
    const filters = {
      'where[and][0][firstName][like]': vals.firstName || '',
      'where[and][1][lastName][like]': vals.lastName || '',
      'where[and][2][revDocId][like]': vals.revDocId || '',
    };
    _.get(vals, 'counties', [])
      .map((c, i) => c
        .counties
        .map((o, k) => {
          filters[`where[and][3][counties][in][${(i * c.counties.length) + k}]`] = o;
        }));
    setParams({
      ...Object
        .entries(params)
        .reduce((acc, [k, v]) => {
          if (!k.includes('counties')) {
            acc[k] = v;
          }
          return acc;
        }, {}),
      page: 1,
      ...filters,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      counties: [{}],
    });
  }, []);

  useEffect(() => {
    dispatch(listUserManagementSetup(params));
  }, [params]);

  useEffect(() => () => dispatch(clearData()), []);
  return (
    <DialogPopup
      open
      title="Reassign Providers"
      handleOk={form.submit}
      handleCancel={onCancel}
      cancelBtn="Cancel"
      saveBtn="Search"
      showActionButton
      className="reassignModalContainer"
    >
      <Form form={form} onFinish={onSubmit}>
        <p className="paragraph-search">Search for the PRMs you wish to reassign the Providers to</p>
        <label className="counties-dropdown-width">
          State*  / County*
          <Form.Item
            name="counties"
            rules={[
              {
                validator: (m, v) => {
                  if (!_.isEmpty(v) && !Object.values(v).find((o) => !o.counties?.length)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(m);
                },
                message: 'Please select state and county',
              },
            ]}
          >
            <StateCountiesDropDown />
          </Form.Item>
        </label>
        <div className="nameSection">
          <Form.Item
            name="firstName"
            rules={[
              // { validator: emptyFieldValidator, message: 'This field is required' },
            ]}
          >
            <InputField label="PRM First Name" placeholder="PRM First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              // { validator: emptyFieldValidator, message: 'This field is required' },
            ]}
          >
            <InputField label="PRM Last Name" placeholder="PRM Last Name" />
          </Form.Item>
          <Form.Item
            name="revDocId"
          >
            <InputField label="PRM Code" placeholder="PRM Code" />
          </Form.Item>
        </div>
      </Form>
      <Grid
        columns={
          ProviderReassignmentPRMColumns(
            onSelect,
          )
        }
        data={userManagementSetups.docs.filter((u) => u.id !== id)}
        pagination={{
          total: userManagementSetups.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
      />
    </DialogPopup>
  );
}

export default ReassignModal;

import { createSlice } from '@reduxjs/toolkit';
import {
  createSuppliesSetup,
  deleteSuppliesSetup,
  getSuppliesSetup,
  listSuppliesSetup,
  updateSuppliesSetup,
} from './suppliesSetupActions';

const INITIAL_STATE = {
  isLoading: false,
  suppliesSetups: {},
  selectedSuppliesSetup: {},
};

export const suppliesSetupSlice = createSlice({
  name: 'suppliesSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listSuppliesSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listSuppliesSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listSuppliesSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.suppliesSetups = action.payload;
    });
    builder.addCase(getSuppliesSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuppliesSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSuppliesSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSuppliesSetup = action.payload;
    });
    builder.addCase(createSuppliesSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSuppliesSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createSuppliesSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSuppliesSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSuppliesSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSuppliesSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSuppliesSetup = action.payload.doc;
    });
    builder.addCase(deleteSuppliesSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSuppliesSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteSuppliesSetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = suppliesSetupSlice.actions;

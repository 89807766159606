/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategorySetup, listCategorySetup } from 'redux-store/categorySetup/categorySetupActions';
import { CategorySetupGridColumns } from '../../../../../../utils/grid/columns';
import './_categorySetup.scss';

function CategorySetupList() {
  const SEARCH_KEY = 'where[or][0][procedureCategoryName][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [SEARCH_KEY]: '',
    sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { categorySetups } = useSelector((state) => state.categorySetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.CATEGORY_SETUP_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.CATEGORY_SETUP_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      [SEARCH_KEY]: v,
    });
  };

  const CategoryNameClickHandler = (item) => {
    editClick(item?.id);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteCategorySetup(item.id))
        .then((payload) => {
          if (!payload.error) {
            if (params.page === 1) {
              dispatch(listCategorySetup(params));
            } else {
              setParams({ ...params, page: 1 });
            }
          }
        });
    }
  };

  useEffect(() => {
    dispatch(listCategorySetup(params));
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by Category name " onChange={onSearch} waitTime={1300} />
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New Category
          </Button>
        </div>
        <Grid
          columns={
            CategorySetupGridColumns(
              { CategoryNameClick: (item) => CategoryNameClickHandler(item) },
              { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            )
          }
          data={categorySetups.docs}
          pagination={{
            total: categorySetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}

export default CategorySetupList;

import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'redux-store';
import { AppRoutes } from 'routes';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { SuperTokensConfig } from 'shared/config/superTokenConfig';
// import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
// import Session from 'supertokens-auth-react/recipe/session';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'shared/components/loader/Loader';

// SuperTokens.init({
//   appInfo: {
//     apiDomain: 'https://api.localhost:3000',
//     apiBasePath: '/auth',
//     appName: 'revdoc-admin',
//     websiteDomain: 'localhost:3000',
//   },
//   recipeList: [
//     Session.init(),
//     EmailPassword.init(),
//   ],
// });
SuperTokens.init(SuperTokensConfig);
function App() {
  return (
    <SuperTokensWrapper>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Loader />
        <AppRoutes />
      </Provider>
    </SuperTokensWrapper>
  );
}

export default App;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import { HOW_TO_VIDEO } from 'shared/constants/global';

// eslint-disable-next-line no-unused-vars
import { HOW_TOS_API_URL } from './howTosUrls';

export const listHowTos = createAsyncThunk(
  'howToSlice/listHowTos',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(HOW_TOS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getHowTos = createAsyncThunk(
  'howToSlice/getHowTos',
  async (id) => {
    return axios.get(HOW_TOS_API_URL(id)).then(({ data }) => data);
  },
);

export const createHowTos = createAsyncThunk(
  'howToSlice/createHowTos',
  async (params) => {
    let media = null;
    if (_.get(params, 'media', null)) {
      media = await createMedia(params.media, HOW_TO_VIDEO);
    }
    return axios.post(
      HOW_TOS_API_URL(),
      { ...params, media: media.doc.id },
    ).then(({ data }) => {
      toast.success('How To Record has been created');
      return data;
    });
  },
);

export const updateHowTos = createAsyncThunk(
  'howToSlice/updateHowTos',
  async ({ id, params }) => {
    let media = _.get(params, 'media', null);
    if (typeof (media) === 'object') {
      media = await createMedia(params.media, HOW_TO_VIDEO);
      media = media.doc.id;
    }
    return axios.patch(
      HOW_TOS_API_URL(id),
      { ...params, media },
    ).then(({ data }) => {
      toast.success('How To Record has been updated');
      return data;
    });
  },
);

export const deleteHowTos = createAsyncThunk(
  'howToSlice/deleteHowTos',
  async ({ id }) => {
    return axios.patch(HOW_TOS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('How To Record has been deleted');
      });
  },
);

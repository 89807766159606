import React from 'react';
import { Popover } from 'antd';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import IconText from '../iconText/IconText';

export function PasswordRequirements() {
  return (
    <Popover
      content={(
        <div>
          Length: Your password must be at least 8 characters
          long and should include a combination of the following:
          <br />
          Uppercase letters (A-Z)
          <br />
          Lowercase letters (a-z)
          <br />
          Numbers (0-9)
          <br />
          Do not use personal information, such as your name or birthdate.
        </div>
      )}
      trigger="click"
    >
      <IconText style={{ cursor: 'pointer' }} icon={faCircleInfo} text="Password Requirements" />
    </Popover>
  );
}

/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getChecklists } from 'shared/utils/checklist';
import pdfIcon from '../../../../assets/imgs/pdfIcon.png';

export default function VerificationStatus() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);
  const [verificationItems, setVerificationItems] = useState({});

  const getVerificationFormsItems = () => {
    const verificationCheckList = getChecklists(selectedMyProviders);
    setVerificationItems(verificationCheckList);
  };

  useEffect(() => {
    getVerificationFormsItems();
  }, [selectedMyProviders]);

  return (
    <div className="provider-pop-up-container__verification">
      <div className="top">
        <div className="dd-flex">
          <TickMark />
          <sapn>Thank you for registering!</sapn>
        </div>
        <p>
          Your information will be sent to iprospectcheck to verify.
          Results will be shared with your PRM, who will contact you.
          This process takes 3 – 5 business days. We look forward to working together!
        </p>
      </div>
      <div className="border" />
      {selectedMyProviders?.backgroundCheck?.resultsPdf && (
        <div style={{ margin: '16px 0px' }}>
          <div className="demographic">iProspect Background Check</div>
          <div style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#E0E0E0',
            padding: '8px 16px',
            borderRadius: 18,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
            <img src={pdfIcon} width={36} height={36} alt="" style={{ marginRight: 16 }} />
            <div>
              <a
                style={{ color: '#F16924', fontSize: 16, fontWeight: 700 }}
                href={selectedMyProviders?.backgroundCheck?.resultsPdf?.url}
                target="_blank"
                rel="noreferrer"
              >
                {selectedMyProviders?.backgroundCheck?.resultsPdf?.filename}
              </a>
              <p style={{
                color: '#626466', fontWeight: 400, fontSize: 14, margin: 0,
              }}
              >
                {moment(selectedMyProviders?.backgroundCheck?.resultsPdf?.createdAt).format('MMMM, DD YYYY')}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="border" />

      <div className="demographic">Criminal check from iprospect</div>
      <div className="d-flex">
        <div className="key-value-pair">
          <div className="value">County criminal</div>
        </div>
        {selectedMyProviders?.backgroundCheck?.passedCountyCriminal ? (
          <div className="tick-wrapper">
            <Tick />
          </div>
        )
          : (
            <div className="close-wrapper">
              <CloseIcon />
            </div>
          )}
      </div>
      <div className="d-flex">
        <div className="key-value-pair">
          <div className="value">National criminal + Sex Offender</div>
        </div>
        {selectedMyProviders?.backgroundCheck?.passedNationalCriminalSexOffender ? (
          <div className="tick-wrapper">
            <Tick />
          </div>
        )
          : (
            <div className="close-wrapper">
              <CloseIcon />
            </div>
          )}
      </div>
      <div className="d-flex">
        <div className="key-value-pair">
          <div className="value">FACIS Level III</div>
        </div>
        {selectedMyProviders?.backgroundCheck?.passedFacisLevelThree ? (
          <div className="tick-wrapper">
            <Tick />
          </div>
        )
          : (
            <div className="close-wrapper">
              <CloseIcon />
            </div>
          )}
      </div>

      {(selectedMyProviders?.backgroundCheck?.medicalLicenses || []).map((s) => (
        <div className="d-flex">
          <div className="key-value-pair">
            <div className="value">
              {s.stateCode}
              {' '}
              License
            </div>
          </div>
          {(s.passedMedicalLicense) ? (
            <div className="tick-wrapper">
              <Tick />
            </div>
          )
            : (
              <div className="close-wrapper">
                <CloseIcon />
              </div>
            )}
        </div>
      ))}

      {(selectedMyProviders?.backgroundCheck?.education || []).map((s) => (
        <div className="d-flex">
          <div className="key-value-pair">
            <div className="value">
              {s.universityName}
              {' '}
              University
            </div>
          </div>
          {(s.passedEducation) ? (
            <div className="tick-wrapper">
              <Tick />
            </div>
          )
            : (
              <div className="close-wrapper">
                <CloseIcon />
              </div>
            )}
        </div>
      ))}
      <div className="border" />
      <div className="profile-and-background-wrapper">
        <div className="top">
          <TickMark />
          <p>
            Your profile and background check results have been shared with your PRM.
            Your PRM will be in touch with you shortly for your final review.
            We cannot wait for you to be a part of the RevDoc community!
          </p>
        </div>
        {selectedMyProviders?.backgroundCheck?.receivedByPrmDate && (

          <span>
            Received by PRM:
            {moment(selectedMyProviders?.backgroundCheck?.receivedByPrmDate).format('MMMM dd')}
          </span>
        )}
      </div>
      <div className="border" />
      <div className="d-flex">
        <span className="dark">HIPAA Status</span>
        {verificationItems?.hipaaTraining?.verified ? (
          <span className="pill green">Complete</span>
        )
          : (
            <span className="pill blue">Pending</span>
          )}
      </div>
      <div className="border" />
      <div className="d-flex">
        <span className="dark">PRM Approval Status</span>
        {Object.values(verificationItems).filter((val) => !val?.verified).length ? (
          <span className="pill blue">Pending</span>
        )
          : (
            <span className="pill green">Complete</span>
          )}
      </div>
      <div className="border" />
      <div className="my-prm-container">
        <div className="my-prm">My PRM</div>
        <div className="my-prm-container__inner">
          <img src={_.get(selectedMyProviders, 'providerRelationshipManager.profilePhoto.url', 'https://www.eclosio.ong/wp-content/uploads/2018/08/default.png')} alt="" width={80} height={80} />
          <div className="name-wrapper">
            <div className="name">
              {_.get(selectedMyProviders, 'providerRelationshipManager.firstName', '')}
              {' '}
              {_.get(selectedMyProviders, 'providerRelationshipManager.middleName', '')}
              {' '}
              {_.get(selectedMyProviders, 'providerRelationshipManager.lastName', '')}
            </div>
            <div className="state-info">
              {_.get(selectedMyProviders, 'providerRelationshipManager.counties', []).map((c) => `${c.countyName} ${c.stateCode}`).join(', ')}
            </div>
            {_.get(selectedMyProviders, 'providerRelationshipManager.startDate') && (
              <div className="state-info">
                {moment.duration(moment().diff(moment(_.get(selectedMyProviders, 'providerRelationshipManager.startDate')))).humanize()}
                {' '}
                of experience
              </div>
            )}
          </div>
        </div>
        {/* <div className="msg-prm">Message PRM</div> */}
        {/* <div className="border" />
        <div className="verified">
          <TickMark />
          <span>Verified by RevDoc</span>
        </div> */}
      </div>
    </div>
  );
}

function TickMark() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1.5C4.41594 1.5 1.5 4.41594 1.5 8C1.5 11.5841 4.41594 14.5 8 14.5C11.5841 14.5 14.5 11.5841 14.5 8C14.5 4.41594 11.5841 1.5 8 1.5ZM11.3828 5.82156L7.18281 10.8216C7.13674 10.8764 7.07941 10.9208 7.01471 10.9516C6.95001 10.9823 6.87945 10.9989 6.80781 11H6.79938C6.72929 11 6.66 10.9852 6.59599 10.9567C6.53198 10.9282 6.47468 10.8865 6.42781 10.8344L4.62781 8.83438C4.5821 8.78589 4.54654 8.72876 4.52322 8.66633C4.4999 8.60391 4.4893 8.53745 4.49203 8.47087C4.49477 8.40429 4.51078 8.33892 4.53914 8.27862C4.56749 8.21831 4.60761 8.16429 4.65715 8.11971C4.70668 8.07514 4.76463 8.04091 4.82757 8.01905C4.89052 7.99719 4.95721 7.98813 5.02371 7.9924C5.09021 7.99668 5.15518 8.01421 5.21481 8.04396C5.27444 8.0737 5.32752 8.11507 5.37094 8.16562L6.78625 9.73812L10.6172 5.17844C10.7031 5.07909 10.8247 5.01754 10.9556 5.00711C11.0866 4.99668 11.2164 5.03819 11.317 5.12268C11.4175 5.20717 11.4808 5.32784 11.4931 5.45862C11.5055 5.5894 11.4658 5.71977 11.3828 5.82156Z" fill="#F16924" />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.75C6.62391 2.75 2.25 7.12391 2.25 12.5C2.25 17.8761 6.62391 22.25 12 22.25C17.3761 22.25 21.75 17.8761 21.75 12.5C21.75 7.12391 17.3761 2.75 12 2.75ZM15.5302 14.9698C15.6027 15.0388 15.6608 15.1216 15.7008 15.2133C15.7409 15.305 15.7622 15.4039 15.7635 15.504C15.7648 15.6041 15.746 15.7034 15.7083 15.7961C15.6706 15.8889 15.6147 15.9731 15.5439 16.0439C15.4731 16.1147 15.3889 16.1706 15.2961 16.2083C15.2034 16.246 15.1041 16.2648 15.004 16.2635C14.9039 16.2622 14.805 16.2409 14.7133 16.2008C14.6216 16.1608 14.5388 16.1027 14.4698 16.0302L12 13.5608L9.53016 16.0302C9.38836 16.1649 9.19955 16.2389 9.00398 16.2364C8.8084 16.2339 8.62155 16.1551 8.48325 16.0168C8.34495 15.8785 8.26614 15.6916 8.26364 15.496C8.26114 15.3005 8.33513 15.1116 8.46984 14.9698L10.9392 12.5L8.46984 10.0302C8.33513 9.88836 8.26114 9.69955 8.26364 9.50398C8.26614 9.3084 8.34495 9.12155 8.48325 8.98325C8.62155 8.84495 8.8084 8.76614 9.00398 8.76364C9.19955 8.76114 9.38836 8.83513 9.53016 8.96984L12 11.4392L14.4698 8.96984C14.6116 8.83513 14.8005 8.76114 14.996 8.76364C15.1916 8.76614 15.3785 8.84495 15.5168 8.98325C15.6551 9.12155 15.7339 9.3084 15.7364 9.50398C15.7389 9.69955 15.6649 9.88836 15.5302 10.0302L13.0608 12.5L15.5302 14.9698Z" fill="#866A0E" />
    </svg>
  );
}

function Tick() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.75C6.62391 2.75 2.25 7.12391 2.25 12.5C2.25 17.8761 6.62391 22.25 12 22.25C17.3761 22.25 21.75 17.8761 21.75 12.5C21.75 7.12391 17.3761 2.75 12 2.75ZM17.0742 9.23234L10.7742 16.7323C10.7051 16.8147 10.6191 16.8812 10.5221 16.9273C10.425 16.9735 10.3192 16.9983 10.2117 17H10.1991C10.0939 17 9.99 16.9778 9.89398 16.935C9.79797 16.8922 9.71202 16.8297 9.64172 16.7516L6.94172 13.7516C6.87315 13.6788 6.81981 13.5931 6.78483 13.4995C6.74986 13.4059 6.73395 13.3062 6.73805 13.2063C6.74215 13.1064 6.76617 13.0084 6.8087 12.9179C6.85124 12.8275 6.91142 12.7464 6.98572 12.6796C7.06002 12.6127 7.14694 12.5614 7.24136 12.5286C7.33579 12.4958 7.43581 12.4822 7.53556 12.4886C7.63531 12.495 7.73277 12.5213 7.82222 12.5659C7.91166 12.6106 7.99128 12.6726 8.05641 12.7484L10.1794 15.1072L15.9258 8.26766C16.0547 8.11863 16.237 8.02631 16.4335 8.01066C16.6299 7.99501 16.8246 8.05728 16.9754 8.18402C17.1263 8.31075 17.2212 8.49176 17.2397 8.68793C17.2582 8.8841 17.1988 9.07966 17.0742 9.23234Z" fill="#148051" />
    </svg>
  );
}

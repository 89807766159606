/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'shared/axios/axios';
import { APPOINMENT_API_URL } from './appoinmentUrls';

export const getAllAppoinmetsApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(APPOINMENT_API_URL(`?${query}`)).then(({ data }) => data);
};

export const listProviderAppoinments = createAsyncThunk(
  'appoinment/listProviderAppoinments',
  async (filters) => {
    // eslint-disable-next-line no-unused-vars
    return getAllAppoinmetsApiCall({
      ...filters,
    });
  },
);

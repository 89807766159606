import React from 'react';
import './_menuItem.scss';

export default function MenuItem({
  isActive,
  icon,
  activeIcon,
  title,
  counter,
  ...props
}) {
  return (

    <div className={`menuItem ${isActive ? 'active' : ''}`} {...props}>
      <div className="icon">
        <img src={isActive ? activeIcon : icon} width={24} height={24} alt="" />
        {counter && <div className="counter">{`${counter >= 10 ? '9+' : counter}`}</div>}
      </div>
      <span>{title}</span>
    </div>
  );
}

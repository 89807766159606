import moment from 'moment';

export const getTermedFiltersOnField = (ed, td) => ({
  [`where[${ed}][less_than]`]: moment().format('YYYY-MM-DDTHH:mm:ss'),
  [`where[or][0][${td}][greater_than]`]: moment().format('YYYY-MM-DDTHH:mm:ss'),
  [`where[or][1][${td}][equals]`]: null,
});

export const getCurrentSpan = (ed, td, datetimeFrom, datetimeTo) => ({
  [`where[${ed}][greater_than]`]: datetimeFrom,
  [`where[or][0][${td}][less_than]`]: datetimeTo,
  [`where[or][1][${td}][equals]`]: null,
});

export const getNonDeletedRecordsFilter = () => ({
  'where[deletedInfoField.isDeleted][ne]': false,
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteSpecialitySetup, listSpecialitySetup } from 'redux-store/specialitySetup/specialitySetupActions';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
// import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { SpecialtyGridColumns } from 'utils/grid/columns';

export default function Specialty() {
  const INITIAL_PARAMS = { limit: 10, page: 1, searchText: '' };
  // eslint-disable-next-line no-unused-vars
  const [filterKey, setFilterKey] = useState('where[or][0][providerSpecialtyName][like]');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { specialitySetups } = useSelector((state) => state.specialitySetups);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.SPECIALTY_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      searchText: v,
      page: 1,
    });
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.SPECIALTY_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteSpecialitySetup(item.id));
    }
  };

  useEffect(() => {
    dispatch(listSpecialitySetup({ ...params, [filterKey]: params.searchText }));
  }, [params]);

  useEffect(() => {
    setParams({ ...params, page: specialitySetups?.page || 1 });
  }, [specialitySetups?.page]);

  return (
    <div className="provider-list-container">
      <div className="provider-list-container__header">
        <SearchBox placeholder="Search by Name" onChange={onSearch} />
        {/* <DropDown
          placeholder="Filter By"
          isOptionsObj
          className="provider-list-dropdown"
          options={[]}
        /> */}
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Speciality
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={SpecialtyGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={specialitySetups.docs}
          pagination={{
            total: specialitySetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}

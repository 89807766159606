import { createSlice } from '@reduxjs/toolkit';
import {
  listSupplyVendors,
} from './supplyVendorsActions';

const INITIAL_STATE = {
  isLoading: false,
  supplyVendors: { docs: [] },
};

export const supplyVendorsSlice = createSlice({
  name: 'supplyVendorsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listSupplyVendors.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listSupplyVendors.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listSupplyVendors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplyVendors = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = supplyVendorsSlice.actions;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
// import AddNewSuppliesSetup from './components/AddNewSupply';
import { deleteAtHomeLab, listAtHomeLab } from 'redux-store/atHomeLabs/atHomeLabsActions';
import { AtHomeLabGridColumns } from '../../../../../../utils/grid/columns';
import './_atHomeLab.scss';

function AtHomeLabList() {
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    'where[or][0][labName][like]': '',
    sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { atHomeLabs } = useSelector((state) => state.atHomeLabs);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.AT_HOME_LABS_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.AT_HOME_LABS_URL}/${id}`);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteAtHomeLab(item.id));
    }
  };
  const onSearch = (v) => {
    setParams({
      ...params,
      limit: 10,
      page: 1,
      'where[or][0][labName][like]': v,
    });
  };
  useEffect(() => {
    dispatch(listAtHomeLab(params));
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by At Home Lab name " onChange={onSearch} waitTime={1300} />
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New At Home Lab
          </Button>
        </div>
        <Grid
          columns={
            AtHomeLabGridColumns(
              { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            )
          }
          pagination={{
            total: atHomeLabs.totalDocs,
            defaultPageSize: 10,
            current: params.page,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
          data={atHomeLabs.docs}
        // expandable={{
        //   isExpandable: true,
        //   expandedRowRender: (record) => (
        //     <AddNewSuppliesSetup data={record} />
        //   ),
        // }}
        />
      </div>
    </div>
  );
}

export default AtHomeLabList;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */
import { Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { listCounties } from 'redux-store/userManagementSetup/userManagementSetupActions';
import './_statesCounties.scss';

function CountiesDropDown({
  value,
  placeholder,
  onChange,
  state,
  multiple = true,
}) {
  const [counties, setCounties] = useState({ docs: [] });
  const [isCountiesLoading, setIsCountiesLoading] = useState();
  useEffect(() => {
    if (state) {
      setIsCountiesLoading(true);
      listCounties({
        limit: 0, page: 1, 'where[or][0][stateCode][like]': state, sort: 'countyName',
      })
        .then((data) => {
          setCounties(data);
          setIsCountiesLoading(false);
        })
        .catch(() => {
          setIsCountiesLoading();
        });
    }
  }, [state]);

  return (
    <div className="statesCountiesContainer">
      <Select
        {...(
          multiple ? { mode: 'multiple' } : {}
        )}
        placeholder={placeholder}
        optionFilterProp="children"
        className="rv-user-dropdown"
        onChange={onChange}
        value={value}
        options={counties.docs.map((c) => ({
          value: c.id,
          label: c.countyName,
        }))}
      />
      {isCountiesLoading && <Spin />}
    </div>

  );
}

export default CountiesDropDown;

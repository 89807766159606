import { createSlice } from '@reduxjs/toolkit';
import {
  createMemberTerms,
  deleteMemberTerms,
  getMemberTerms,
  getMemberTermsLive,
  listMemberTerms,
  updateMemberTerms,
} from './memberTermsActions';

const INITIAL_STATE = {
  isLoading: false,
  memberTerms: { docs: [] },
  selectedMemberTerms: {},
  liveMemberTerms: {},
};

export const memberTermsSlice = createSlice({
  name: 'memberTermsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMemberTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMemberTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listMemberTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.memberTerms = action.payload;
    });
    builder.addCase(getMemberTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberTerms = action.payload;
    });
    builder.addCase(createMemberTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMemberTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMemberTerms.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMemberTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMemberTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMemberTerms = action.payload.doc;
    });
    builder.addCase(deleteMemberTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMemberTerms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteMemberTerms.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberTermsLive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberTermsLive.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMemberTermsLive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.liveMemberTerms = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = memberTermsSlice.actions;

import React from 'react';
import './_addMedicationSet.scss';
import InputField from 'shared/components/inputField/InputField';
import { Form, Popconfirm, Space } from 'antd';
import { blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import { TrashIcon } from 'utils/grid/columns';
import CheckBox from 'shared/components/checkbox/CheckBox';
import DropDown from 'shared/components/dropdown/DropDown';
import { ReactComponent as SearchProduct } from '../../../../../../assets/svgs/field-search.svg';
import { QUANTITY_UOM_OPTIONS } from './AddNewMedicationSet';

export default function Product({
  productObject,
  removeProduct,
}) {
  return (
    <div className="medication-multi-fields-wrapper">
      <Form.Item
        name={[productObject.name, 'productId', 'id']}
        className="formfield"
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        <InputField
          prefix={<SearchProduct />}
          label=" Product ID *"
          placeholder=" Product ID"
          disabled
        />
      </Form.Item>
      <Form.Item
        name={[productObject.name, 'productName']}
        className="formfield"
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        <InputField
          label=" Supply Name *"
          placeholder=" Supply Name"
          maxLength={250}
        />
      </Form.Item>
      <Form.Item
        name={[productObject.name, 'quantityUOM', 'ncpdpPreferredTerm']}
        className="formfield"
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        <DropDown
          label=" Quantity UOM *"
          placeholder=" Quantity UOM"
          className="medication-set-dropdown"
          isOptionsObj
          options={QUANTITY_UOM_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        name={[productObject.name, 'quantity']}
        className="formfield"
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        <InputField
          label=" Quantity *"
          placeholder=" Quantity"
          maxLength={250}
        />
      </Form.Item>
      <label htmlFor="default_check" className="default_checkbox">
        Default&nbsp;&nbsp;
        <Form.Item
          name={[productObject.name, 'defaultProduct']}
          style={{ display: 'flex' }}
          valuePropName="checked"
        >
          <CheckBox id="default_check" />
        </Form.Item>
      </label>
      <Space size="middle" className="item_action">
        <Popconfirm
          title="Are you sure?"
          description="Do you want to delete this product?"
          okText="Yes"
          cancelText="No"
          onConfirm={removeProduct}
        >
          <a>
            <TrashIcon />
          </a>
        </Popconfirm>
      </Space>
    </div>
  );
}

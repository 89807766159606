import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { atHomeLabSlice } from './atHomeLabs/atHomeLabsSlice';
import { authSlice } from './auth/authSlice';
import { bioMarkersSlice } from './bioMarkers/bioMarkersSlice';
import { medicationSetSlice } from './medicationSet/medicationSetSlice';
import { productsSlice } from './products/productsSlice';
import { categorySetupSlice } from './categorySetup/categorySetupSlice';
import { complaintsSlice } from './complaints/complaintsSlice';
import { deliveryMethodSlice } from './deliveryMethod/deliveryMethodSlice';
import { howToSlice } from './howTos/howTosSlice';
import { insuranceSetupSlice } from './insuranceSetup/insuranceSetupSlice';
import { mediaSlice } from './media/mediaSlice';
import { memberFAQSlice } from './memberFAQ/memberFAQSlice';
import { memberTermsSlice } from './memberTerms/memberTermsSlice';
import { messagesSlice } from './messages/messagesSlice';
import { myProvidersSlice } from './myProviders/myProvidersSlice';
import { appoinments } from './appoinments/appoinmentSlice';
import { alerts } from './alerts/alertsSlice';
import { privacyPolicySlice } from './privacyPolicy/privacyPolicySlice';
import { proceduresSetupSlice } from './proceduresSetup/proceduresSetupSlice';
import { providerFAQSlice } from './providerFAQ/providerFAQSlice';
import { providerTermsSlice } from './providerTerms/providerTermsSlice';
import { providerTypeSlice } from './providerType/providerTypeSlice';
import { specialitySetupSlice } from './specialitySetup/specialitySetupSlice';
import { suppliesSetupSlice } from './suppliesSetup/suppliesSetupSlice';
import { supplyVendorsSlice } from './supplyVendors/supplyVendorsSlice';
import { symptomsSlice } from './symptoms/symptomsSlice';
import { userManagementSetupSlice } from './userManagementSetup/userManagementSetupSlice';
import { quickLinksSlice } from './quickLinks/quickLinksSlice';
import { feedbacksSlice } from './feedbacks/feedbacksSlice';
import { preparationStepsSlice } from './preparationSteps/preparationStepsSlice';

const middleware = [...getDefaultMiddleware()];

// The store is configured with the state and the corresponding reducers.
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    categorySetup: categorySetupSlice.reducer,
    suppliesSetup: suppliesSetupSlice.reducer,
    proceduresSetup: proceduresSetupSlice.reducer,
    providerType: providerTypeSlice.reducer,
    deliveryMethod: deliveryMethodSlice.reducer,
    insuranceSetup: insuranceSetupSlice.reducer,
    media: mediaSlice.reducer,
    supplyVendors: supplyVendorsSlice.reducer,
    userManagementSetups: userManagementSetupSlice.reducer,
    myProviders: myProvidersSlice.reducer,
    appoinments: appoinments.reducer,
    alerts: alerts.reducer,
    specialitySetups: specialitySetupSlice.reducer,
    complaints: complaintsSlice.reducer,
    symptoms: symptomsSlice.reducer,
    messages: messagesSlice.reducer,
    atHomeLabs: atHomeLabSlice.reducer,
    memberFAQs: memberFAQSlice.reducer,
    providerFAQs: providerFAQSlice.reducer,
    memberTerms: memberTermsSlice.reducer,
    howTos: howToSlice.reducer,
    providerTerms: providerTermsSlice.reducer,
    privacyPolicies: privacyPolicySlice.reducer,
    bioMarkers: bioMarkersSlice.reducer,
    quickLinks: quickLinksSlice.reducer,
    medicationSet: medicationSetSlice.reducer,
    products: productsSlice.reducer,
    feedbacks: feedbacksSlice.reducer,
    preparationSteps: preparationStepsSlice.reducer,
  },
  middleware,
});

export default store;

import React from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import './_navTabs.scss';

function NavTabs({ children }) {
  return (
    <div className="navPageContainer">
      {children}
    </div>
  );
}
function NavTabsMenu({ children, ...props }) {
  return (
    <div {...props}>
      {children}
    </div>
  );
}
function NavTabsMenuItems({
  children,
  isExactPath,
  to,
  ...props
}) {
  const location = useLocation();
  const childrenWithActiveProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isActive: isExactPath ? (location.pathname === to)
          : (location.pathname.includes(to)),
      });
    }
    return child;
  });
  return (
    <NavLink to={`${to}?p=${Math.random()}`}>
      <div {...props}>
        {childrenWithActiveProps}
      </div>
    </NavLink>
  );
}
function NavTabsContent({ children, ...props }) {
  const [searchParams] = useSearchParams();

  return (
    <div {...props} key={searchParams.get('p')}>
      {children}
    </div>
  );
}
NavTabs.Menu = NavTabsMenu;
NavTabs.MenuItems = NavTabsMenuItems;
NavTabs.Content = NavTabsContent;
export default NavTabs;

import { createSlice } from '@reduxjs/toolkit';
import {
  getPreparationSteps,
  savePreparationSteps,
} from './preparationStepsActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  preparationSteps: { },
};

export const preparationStepsSlice = createSlice({
  name: 'categorySetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getPreparationSteps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPreparationSteps.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPreparationSteps.fulfilled, (state, action) => {
      state.isLoading = false;
      state.preparationSteps = action.payload;
    });
    builder.addCase(savePreparationSteps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(savePreparationSteps.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(savePreparationSteps.fulfilled, (state, action) => {
      state.isLoading = false;
      state.preparationSteps = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = preparationStepsSlice.actions;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { PROVIDER_FAQ_API_URL, PROVIDER_FAQ_CATEGORIES_API_URL } from './providerFAQUrls';

export const listProviderFAQCategories = createAsyncThunk(
  'providerFAQSlice/listProviderFAQCategories',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROVIDER_FAQ_CATEGORIES_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getProviderFAQCategories = createAsyncThunk(
  'providerFAQSlice/getProviderFAQCategories',
  async (id) => {
    return axios.get(PROVIDER_FAQ_CATEGORIES_API_URL(id)).then(({ data }) => data);
  },
);

export const createFAQ = async (params) => {
  return axios.post(
    PROVIDER_FAQ_API_URL(),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const createProviderFAQCategories = createAsyncThunk(
  'providerFAQSlice/createProviderFAQCategories',
  async (params) => {
    let faqs = params.faqs.map((p) => createFAQ(p));
    faqs = await Promise.all(faqs);
    faqs = faqs.map((p) => p?.doc?.id);
    return axios.post(
      PROVIDER_FAQ_CATEGORIES_API_URL(),
      { ...params, faqs },
    ).then(({ data }) => {
      toast.success('Provider FAQ Category has been created');
      return data;
    });
  },
);

export const updateFAQ = async ({ id, ...params }) => {
  return axios.patch(
    PROVIDER_FAQ_API_URL(id),
    params,
  ).then(({ data }) => {
    return data;
  });
};

export const updateProviderFAQCategories = createAsyncThunk(
  'providerFAQSlice/updateProviderFAQCategories',
  async ({ id, params }) => {
    let faqsToUpdate = params.faqs.filter((p) => p.id);
    faqsToUpdate = faqsToUpdate.map((p) => updateFAQ(p));
    faqsToUpdate = await Promise.all(faqsToUpdate);
    faqsToUpdate = faqsToUpdate.map((p) => p?.doc?.id);

    let faqsToCreate = params.faqs.filter((p) => !p.id);
    faqsToCreate = faqsToCreate.map((p) => createFAQ(p));
    faqsToCreate = await Promise.all(faqsToCreate);
    faqsToCreate = faqsToCreate.map((p) => p?.doc?.id);

    return axios.patch(
      PROVIDER_FAQ_CATEGORIES_API_URL(id),
      { ...params, faqs: [...faqsToCreate, ...faqsToUpdate] },
    ).then(({ data }) => {
      toast.success('Provider FAQ Category has been updated');
      return data;
    });
  },
);

export const deleteProviderFAQCategories = createAsyncThunk(
  'providerFAQSlice/deleteProviderFAQCategories',
  async (id) => {
    return axios
      .patch(PROVIDER_FAQ_CATEGORIES_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Provider FAQ Category has been deleted');
      });
  },
);

/* eslint-disable import/prefer-default-export */
export function CategorySetupGridMockData() {
  const data = [
    {
      key: '1',
      category_name: 'Urgent Care',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
    {
      key: '2',
      category_name: 'Primary Care',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
    {
      key: '3',
      category_name: 'Age-Defying',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
    {
      key: '4',
      category_name: 'Men’s Health',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
    {
      key: '5',
      category_name: 'Women’s Health',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
    {
      key: '6',
      category_name: 'IV Therapy',
      category_effective_date: '9/20/2023',
      category_term_date: '12/31/2025',
    },
  ];

  return data;
}

export function SuppliesSetupGridMockData() {
  const data = [
    {
      key: '1',
      supplies_id: '1223-221',
      supplies_name: 'Supplies Name',
      short_name: 'Short Name',
      supplies_price: '$190',
      admin_fee: '3%',
      final_price: '$200',
      effective_date: '6/13/2023',
      term_date: '6/13/2023',
    },
  ];

  return data;
}

export function ProcedureSetupGridMockData() {
  const data = [
    {
      key: '1',
      procedure_name: 'Botox',
      category: 'Age-Defying',
      delivery_method: 'House Call, Van, Facility',
      last_updated: '6/13/2023, 4:34 PM',
      status: 'Live',
    },
  ];

  return data;
}

export function InsurancePlanGridMockData() {
  const data = [
    {
      key: '1',
      insuranceName: 'United',
      status: 'Live',
    },
  ];

  return data;
}

export function AssignedProvidersGridMockData() {
  const data = [
    {
      key: '1',
      provider: 'Samantha Smith, MD',
      supervisor: 'Y',
      emailAddress: 'samsmith@email.com',
      mobilePhone: '(222) 222-2222',
      conciergePatients: '11',
      region: 'Tampa South',
    },
    {
      key: '2',
      provider: 'John Robinson, MD',
      supervisor: 'N',
      emailAddress: 'jrobb@jnfj.com',
      mobilePhone: '(222) 222-2223',
      conciergePatients: '201',
      region: 'Miami',
    },
    {
      key: '3',
      provider: 'Chelsea Cooke, RN',
      supervisor: 'N',
      emailAddress: 'chelseacooke@gmail.com',
      mobilePhone: '(222) 222-2224',
      conciergePatients: '1',
      region: 'North Miami',
    },
    {
      key: '4',
      provider: 'Rebecca Marsh, PA',
      supervisor: 'Y',
      emailAddress: 'rmarsh40@email.com',
      mobilePhone: '(222) 222-2225',
      conciergePatients: '4',
      region: 'South Miami',
    },
  ];

  return data;
}

export function UsersGridMockData() {
  const data = [
    {
      key: '1',
      name: 'James Ruskin',
      revdocID: '1234',
      startDate: '6/20/2023, 9:00 AM',
      termDate: '10/20/2025, 9:00 AM',
    },
    {
      key: '2',
      name: 'Joan Smith',
      revdocID: '1234',
      startDate: '6/20/2023, 9:00 AM',
      termDate: '10/20/2025, 9:00 AM',
    },
    {
      key: '3',
      name: 'Sam Thomas',
      revdocID: '1234',
      startDate: '6/20/2023, 9:00 AM',
      termDate: '10/20/2025, 9:00 AM',
    },
    {
      key: '4',
      name: 'Peter Jones',
      revdocID: '1234',
      startDate: '6/20/2023, 9:00 AM',
      termDate: '10/20/2025, 9:00 AM',
    },
  ];

  return data;
}

export function GoalGridMockData() {
  const data = [
    {
      key: '1',
      kpi: '50 Providers',
      byMonth: '-',
      actualmonth: '-',
      byYear: '-',
      actualyear: '-',
    },
    {
      key: '2',
      kpi: '70 Providers',
      byMonth: '-',
      actualmonth: '-',
      byYear: '-',
      actualyear: '-',
    },
  ];

  return data;
}

export function ReviewGridMockData() {
  const data = [
    {
      key: '1',
      name: 'Review 1',
    },
    {
      key: '2',
      name: 'Review 2',
    },
  ];

  return data;
}

export function ComplaintGridMockData() {
  const data = [
    {
      key: '1',
      provider: 'Rebecca Marsh, PA',
      complaintStatus: 'Active',
      numberOfComplaints: 1,
    },
  ];

  return data;
}

export function ProviderGridMockData() {
  const data = [
    {
      key: '1',
      providername: 'Rebecca Marsh',
      supervisor: 'Y',
      countdown: '2 days',
      rating: '4.5',
      status: 'Active',
      apptsToDate: 49,
      lastactivity: '30/12/2023, 09:33 AM',
    },
    {
      key: '2',
      providername: 'Rebecca Marsh',
      supervisor: 'Y',
      countdown: '2 days',
      rating: '4.5',
      status: 'Active',
      apptsToDate: 49,
      lastactivity: '30/12/2023, 09:33 AM',
    },
  ];

  return data;
}

export function SymptomGridMockData() {
  const data = [
    {
      key: '1',
      symptom_category: 'Category 1',
      symptom_name: 'Fever',
      effective_date: '6/13/2023',
      term_date: 'N/A',
    },
    {
      key: '1',
      symptom_category: 'Category 1',
      symptom_name: 'Chills',
      effective_date: '6/13/2023',
      term_date: 'N/A',
    },
  ];

  return data;
}

export function ComplaintsGridMockData() {
  const data = [
    {
      key: '1',
      providername: 'Rebecca Marsh, PA',
      status: 'In Process',
      numberOfComplaint: '1',
    },
    {
      key: '2',
      providername: 'Joe Wallace, PA',
      status: 'Awaiting Review',
      numberOfComplaint: '1',
    },
    {
      key: '3',
      providername: 'Rebecca Marsh, PA',
      status: 'Resolved',
      numberOfComplaint: '1',
    },
  ];

  return data;
}

export function SpecialtyGridMockData() {
  const data = [
    {
      key: '1',
      name: 'Cardiologist',
      date: '6/13/2023',
      term: 'N/A',
    },
  ];

  return data;
}

export function MemberFAQGridMockData() {
  const data = [
    {
      key: '1',
      faqCategory: 'About RevDoc',
      faqQuestion: 'How do I make sure that my availability i...',
      faqAnswer: 'The best way to confirm that your availability i...',
    },
  ];

  return data;
}

export function MemberVideoGridMockData() {
  const data = [
    {
      key: '1',
      title: 'Upload Your Bio Like a Pro',
      description: 'In this video we walk you through tipson how to update your profile Bio',
      duration: '4 minutes',
      date: '11/11/2023',
    },
  ];

  return data;
}

export function TermAndConditionGridMockData() {
  const data = [
    {
      key: '1',
      version: '1.0',
      title: 'RevDoc Member Terms and Conditions',
      status: 'Published',
    },
  ];

  return data;
}

export function BiomarkerGridMockData() {
  const data = [
    {
      key: '1',
      bioName: 'HbA1c',
      bioID: 'HbA1c',
      bioUnit: '%',
      refLow: '3.8',
      refHigh: '6.3',
      repLow: '2.7',
      repHigh: '5.6',
    },
  ];

  return data;
}

export function AddedBiomarkerGridMockData() {
  const data = [
    {
      key: '1',
      bioName: 'HbA1c',
      bioUnit: '%',
      refLow: '3.8',
      refHigh: '6.3',
      repLow: '2.7',
      repHigh: '5.6',
    },
    {
      key: '2',
      bioName: 'Total Cholesterol',
      bioUnit: 'mg/dL',
      refLow: '0',
      refHigh: '200',
      repLow: '29',
      repHigh: '790',
    },
  ];

  return data;
}

export function AddNewBiomarkerGridMockData() {
  const data = [
    {
      key: '1',
      bioName: 'HbA1c',
      bioUnit: '%',
      refLow: '3.8',
      refHigh: '6.3',
      repLow: '2.7',
      repHigh: '5.6',
    },
    {
      key: '2',
      bioName: 'Total Cholesterol',
      bioUnit: 'mg/dL',
      refLow: '0',
      refHigh: '200',
      repLow: '29',
      repHigh: '790',
    },
  ];

  return data;
}

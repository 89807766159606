/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-for */

import { Input } from 'antd';
import React from 'react';
import uuid from 'react-uuid';
import './InputWithLabel.scss';

function InputWithLabel({
  label,
  onChange,
  value,
  disabled,
}) {
  const id = uuid();
  return (
    <div className="transition-input">
      <Input disabled={disabled} onChange={onChange} value={value} type="text" id={id} placeholder={label} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default InputWithLabel;

/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';

import { PROVIDER_TYPE_API_URL } from './providerTypeUrls';

export const listProviderType = createAsyncThunk(
  'ProviderTypeSlice/listProviderType',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROVIDER_TYPE_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getProviderType = createAsyncThunk(
  'ProviderTypeSlice/getProviderType',
  async (id) => {
    return axios.get(PROVIDER_TYPE_API_URL(id)).then(({ data }) => data);
  },
);

export const createProviderType = createAsyncThunk(
  'ProviderTypeSlice/createProviderType',
  async (params) => {
    return createMedia(params.uploadImage).then((imgObj) => {
      return axios.post(
        PROVIDER_TYPE_API_URL(),
        {
          ...params,
          supplierVendor: '64e6b70fb6c17d18b027bfd3',
          uploadImage: imgObj.doc.id,
        },
      ).then(({ data }) => {
        toast.success('Supply has been created');
        return data;
      });
    });
  },
);

export const updateProviderType = createAsyncThunk(
  'ProviderTypeSlice/updateProviderType',
  async ({ id, params }) => {
    let imgID = _.get(params, 'uploadImage.id', null);
    if (!imgID) {
      const img = await createMedia(params.uploadImage);
      imgID = img.id;
    }
    return axios.patch(
      PROVIDER_TYPE_API_URL(id),
      {
        ...params,
        uploadImage: imgID,
      },
    ).then(({ data }) => {
      toast.success('Supply has been updated');
      return data;
    });
  },
);

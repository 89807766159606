import { createSlice } from '@reduxjs/toolkit';
import {
  createSymptom,
  deleteSymptoms,
  getSymptoms,
  listSymptoms,
  updateSymptom,
} from './symptomsActions';

const INITIAL_STATE = {
  isLoading: false,
  symptoms: { docs: [] },
  selectedSymptom: {},
};

export const symptomsSlice = createSlice({
  name: 'symptomsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listSymptoms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listSymptoms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listSymptoms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.symptoms = action.payload;
    });
    builder.addCase(getSymptoms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSymptoms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSymptoms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSymptom = action.payload;
    });
    builder.addCase(createSymptom.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSymptom.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createSymptom.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSymptom.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSymptom.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateSymptom.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedSymptom = action.payload.doc;
    });
    builder.addCase(deleteSymptoms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSymptoms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteSymptoms.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = symptomsSlice.actions;

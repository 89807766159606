/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'shared/axios/axios';
// import Session from 'supertokens-auth-react/recipe/session';

import { LARGE_MEDIA_API_URL, MEDIA_API_URL, MEDIA_TYPE_API_URL } from './mediaUrls';

export const uploadLargeMedia = async (params, type) => {
  const { data: urlReq } = await axios.get(LARGE_MEDIA_API_URL('?collection=media'));
  await axios.put(
    urlReq.url,
    params,
    {
      timeout: 30 * 60000,
      headers: {
        'Content-Type': params.type,
      },
    },
  );
  return axios.post(
    MEDIA_API_URL('register'),
    {
      filename: urlReq.key,
      mediaType: type,
    },
  ).then(({ data }) => ({ doc: data }));
};

export const createMedia = (params, type) => {
  if ((params.size / 1024 / 1024) > 10) {
    return uploadLargeMedia(params, type);
  }
  const formData = new FormData();
  formData.append('_payload', `{"mediaType":"${type}"}`);
  formData.append('file', params);

  return axios.post(MEDIA_API_URL(), formData, { timeout: 30 * 60000 }).then(({ data }) => data);
};

export const listMediaTypes = createAsyncThunk(
  'mediaSlice/listMediaTypes',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(MEDIA_TYPE_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createUserManagementSetup, getUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { USER_MANAGEMENT_SETUP_API_URL } from 'redux-store/userManagementSetup/userManagementSetupUrls';
import {
  signIn,
  signUp,
  sendPasswordResetEmail as sendResetEmail,
  submitNewPassword,
  signOut,
} from 'supertokens-web-js/recipe/emailpassword';
import axios from 'shared/axios/axios';
import { listProviderAlerts } from 'redux-store/alerts/alertsActions';
import { initialiseUser } from './authSlice';

export const logout = createAsyncThunk('auth/logout', async () => signOut());

export const initialiseApp = createAsyncThunk(
  'auth/initialiseApp',
  async (p, { dispatch, getState }) => {
    await dispatch(initialiseUser());
    dispatch(listProviderAlerts({ 'where[isRead][equals]': false, limit: 0, depth: 0 }));
    const id = _.get(getState(), 'auth.user.id');
    return axios.get(USER_MANAGEMENT_SETUP_API_URL(id))
      .then(({ data }) => data);
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { dispatch }) => {
    return signIn({
      formFields: [{
        id: 'email',
        value: email,
      }, {
        id: 'password',
        value: password,
      }],
    })
      .then((response) => {
        if (response.status === 'FIELD_ERROR') {
          toast.error('Please use valid values');
          return Promise.reject();
        } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
          toast.error('The email and password combination you entered is incorrect.');
          return Promise.reject();
        }
        localStorage.setItem('user', JSON.stringify(response.user));
        return dispatch(getUserManagementSetup(response.user.id))
          .then((action) => Promise.resolve({ user: response.user, profile: action.payload }));
        // return Promise.resolve(response.user);
      }).catch((err) => {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          toast.error(err.message);
          return Promise.reject();
        } else {
          toast.error('Oops! Something went wrong.');
          return Promise.reject();
        }
      });
  },
);

export const signup = createAsyncThunk(
  'auth/signup',
  async ({
    email,
    password,
    firstName,
    lastName,
    phone,
  }, { dispatch }) => {
    return signUp({
      formFields: [{
        id: 'email',
        value: email,
      }, {
        id: 'password',
        value: password,
      }, {
        id: 'first_name',
        value: firstName,
      },
      {
        id: 'last_name',
        value: lastName,
      },
      {
        id: 'phone',
        value: phone,
      }],
    })
      .then((response) => {
        if (response.status === 'FIELD_ERROR') {
          response.formFields.forEach((formField) => {
            if (formField.id === 'password') {
              toast.error(formField.error);
            }
          });
          toast.error('Please use valid values');
          return Promise.reject();
        }
        localStorage.setItem('user', JSON.stringify(response.user));
        return dispatch(createUserManagementSetup({
          id: response.user.id,
          firstName,
          lastName,
          personalEmail: email,
          mobilePhoneNumber: phone,
          isPrm: true,
          accessFields: {
            profileSetup: false,
            complaints: false,
            myProviders: false,
            userManagement: false,
            categorySetup: false,
            procedureSetup: false,
            insurancePlans: false,
            suppliesSetup: false,
            specialistSetup: false,
          },
        })).then((action) => Promise.resolve({ user: response.user, profile: action.payload.doc }));
      }).catch((err) => {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          toast.error(err.message);
          return Promise.reject();
        } else {
          toast.error('Oops! Something went wrong.');
          return Promise.reject();
        }
      });
  },
);

export const sendPasswordResetEmail = createAsyncThunk(
  'auth/sendPasswordResetEmail',
  async ({ email }) => {
    return sendResetEmail({
      formFields: [{
        id: 'email',
        value: email,
      }],
    })
      .then((response) => {
        if (response.status === 'FIELD_ERROR') {
          toast.error('Please use valid values');
          return Promise.reject();
        }
        toast.success('Please check your email for password reset link');
        return Promise.resolve();
      }).catch((err) => {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          toast.error(err.message);
          return Promise.reject();
        } else {
          toast.error('Oops! Something went wrong.');
          return Promise.reject();
        }
      });
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ password }) => {
    return submitNewPassword({
      formFields: [{
        id: 'password',
        value: password,
      }],
    })
      .then((response) => {
        if (response.status === 'FIELD_ERROR') {
          toast.error('Password validation failed');
          return Promise.reject();
        } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
          toast.error('Password reset token is invalid or expired please try again later');
          return Promise.reject();
        }
        toast.success('Your password has been changed successfully');
        return Promise.resolve();
      }).catch((err) => {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          toast.error(err.message);
          return Promise.reject();
        } else {
          toast.error('Oops! Something went wrong.');
          return Promise.reject();
        }
      });
  },
);

import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNew } from 'shared/utils/global';
import { createProviderTerms, getProviderTerms, updateProviderTerms } from 'redux-store/providerTerms/providerTermsActions';
import _ from 'lodash';
import { clearData } from 'redux-store/providerTerms/providerTermsSlice';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RichTextEditor } from 'shared/components/richTextEditor/RichTextEditor';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';

function AddNewProviderTermCondition() {
  const { id } = useParams();
  const { selectedProviderTerms } = useSelector((s) => s.providerTerms);
  const { profile } = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };

  const handleSubmit = (type) => (vals) => {
    vals.type = type;
    vals._status = type;
    vals.createdBy = profile.id;
    vals.policyText = vals.policyText.replace('&nsbp;', ' ');
    vals = applyTrim(vals);
    if (isNew(id)) {
      dispatch(createProviderTerms(vals)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateProviderTerms({ id, params: vals }))
        .then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
    }
  };
  const onPublish = () => {
    form.validateFields()
      .then((values) => {
        handleSubmit('published')(values);
      }).catch(() => {
        toast.error('Please fill the required fields');
      });
  };

  useEffect(() => {
    if (!isNew(id)) {
      dispatch(getProviderTerms(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedProviderTerms)) {
      form.setFieldsValue(selectedProviderTerms.version);
      forceUpdate();
    }
  }, [selectedProviderTerms]);

  return (
    <div className="add-new-member-faq-container">
      <div className="add-new-member-faq-container__body">
        <div className="add-new-member-faq-container__heading">
          {isNew(id) ? 'Add New ' : 'Update '}
          Terms and Condition
        </div>
        <Form
          onFinish={handleSubmit('draft')}
          onValuesChange={() => forceUpdate((v) => !v)}
          form={form}
          onFinishFailed={() => toast.error('Please fill the required fields')}
        >
          <Form.Item
            name="policyText"
            className="formfield"
            rules={[{
              validator: (m, v) => {
                if (v && v !== '<p></p>') {
                  if (v.length < 1000) {
                    return Promise.reject(new Error('This field should have minimum length of 1000 characters'));
                  }
                  return Promise.resolve();
                }
                return Promise.reject(new Error('This field is required'));
              },
            }]}
          >
            <RichTextEditor />
          </Form.Item>
          <Form.Item
            name="versionName"
            className="formfield"
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              blackListedChars,
            ]}
          >
            <InputField payload="Version Name" label="Version Name *" />
          </Form.Item>

          <div className="add-faq-action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            {!isNew(id) && (
              <div>
                <span className="grey-text">Added on:</span>
                <span className="orng-text">{moment(selectedProviderTerms?.version?.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
              </div>
            )}
            {/* <div>
              <span className="grey-text">Added By:</span>
              <span className="orng-text">Smith</span>
            </div> */}
            <div className="mr-10">
              <Button variant={Button.variant.filled} onClick={onPublish}>Publish</Button>
              <Button variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddNewProviderTermCondition;

import { Form } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createMessageApiCall } from 'redux-store/messages/messagesActions';
import { getAllProvidersApiCall } from 'redux-store/myProviders/myProvidersActions';
import Button from 'shared/components/button/Button';
import TextAreaField from 'shared/components/textArea/TextArea';
import { threadIds } from 'shared/utils/thread-ids';
import { blackListedChars, emptyFieldValidator } from 'shared/utils/validators';

export default function MessageAllProvider({ onCancel }) {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const me = useSelector((state) => state.auth.profile);

  const sendMessage = async ({ message }) => {
    try {
      setIsLoading(true);
      const providers = await getAllProvidersApiCall({
        limit: 0,
        'where[providerRelationshipManager][like]': me.id,
      });
      _.get(providers, 'docs', []).forEach(async (provider) => {
        const threadId = threadIds.providerToPrm.generate({
          prmId: me.id,
          providerId: provider.id,
        });
        await createMessageApiCall({
          participants: [
            { relationTo: 'admins', value: me.id },
            { relationTo: 'providers', value: provider.id },
          ],
          messageOwner: me.id,
          messageText: message,
          threadId,
        });
      });
      toast.success('Message has been sent');
      onCancel();
      setIsLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.detail);
    }
  };

  return (
    <Form
      form={form}
      onFinish={sendMessage}
      onValuesChange={() => forceUpdate((n) => !n)}
      onFinishFailed={() => toast.error('Please fill the form with required fields')}

    >
      <div className="message-all-provider-container">
        <div className="message-all-provider-container__header">
          <AlertIcon />
          <span>This message will be sent to ALL of your Providers.</span>
        </div>
        <div className="message-all-provider-container__text-are">
          <Form.Item
            name="message"
            style={{ margin: 0, flex: 1 }}
            rules={[
              { validator: emptyFieldValidator, message: 'This field is required' },
              blackListedChars,
            ]}
          >
            <TextAreaField maxLength={5000} placeholder="Input the message" rows={15} />
          </Form.Item>
          <div className="length">
            {form.getFieldValue('message')?.length || 0}
            /5,000 characters
          </div>
        </div>
        <div className="message-all-provider-container__btn">
          <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">Send</Button>
          <Button variant={Button.variant.outlined} onClick={onCancel}>Cancel</Button>
        </div>
      </div>
    </Form>
  );
}

function AlertIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 3.375C9.93586 3.375 3.375 9.93586 3.375 18C3.375 26.0641 9.93586 32.625 18 32.625C26.0641 32.625 32.625 26.0641 32.625 18C32.625 9.93586 26.0641 3.375 18 3.375ZM18 25.8687C17.7219 25.8687 17.45 25.7862 17.2187 25.6317C16.9875 25.4772 16.8072 25.2575 16.7008 25.0006C16.5944 24.7436 16.5665 24.4609 16.6208 24.1881C16.675 23.9153 16.809 23.6647 17.0056 23.4681C17.2023 23.2714 17.4529 23.1375 17.7257 23.0832C17.9984 23.0289 18.2812 23.0568 18.5381 23.1632C18.7951 23.2697 19.0147 23.4499 19.1693 23.6812C19.3238 23.9124 19.4062 24.1843 19.4062 24.4624C19.4062 24.8354 19.2581 25.1931 18.9944 25.4568C18.7306 25.7205 18.373 25.8687 18 25.8687ZM19.5272 11.7253L19.1236 20.3034C19.1236 20.6018 19.0051 20.888 18.7941 21.0989C18.5831 21.3099 18.297 21.4284 17.9986 21.4284C17.7002 21.4284 17.4141 21.3099 17.2031 21.0989C16.9921 20.888 16.8736 20.6018 16.8736 20.3034L16.47 11.7295V11.726C16.4612 11.5199 16.4941 11.3142 16.5669 11.1212C16.6396 10.9282 16.7507 10.752 16.8934 10.603C17.0361 10.4541 17.2074 10.3356 17.3971 10.2546C17.5868 10.1736 17.7909 10.1319 17.9972 10.1319C18.2034 10.1319 18.4076 10.1736 18.5973 10.2546C18.787 10.3356 18.9583 10.4541 19.101 10.603C19.2437 10.752 19.3547 10.9282 19.4275 11.1212C19.5003 11.3142 19.5332 11.5199 19.5244 11.726L19.5272 11.7253Z" fill="#F16924" />
    </svg>
  );
}

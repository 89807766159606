import { createSlice } from '@reduxjs/toolkit';
import {
  createPrivacyPolicy,
  deletePrivacyPolicy,
  getPrivacyPolicy,
  getPrivacyPolicyLive,
  listPrivacyPolicy,
  updatePrivacyPolicy,
} from './privacyPolicyActions';

const INITIAL_STATE = {
  isLoading: false,
  privacyPolicies: { docs: [] },
  selectedPrivacyPolicy: {},
  livePrivacyPolicy: {},
};

export const privacyPolicySlice = createSlice({
  name: 'privacyPolicySlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listPrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listPrivacyPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listPrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.privacyPolicies = action.payload;
    });
    builder.addCase(getPrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPrivacyPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPrivacyPolicy = action.payload;
    });
    builder.addCase(createPrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPrivacyPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPrivacyPolicy.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePrivacyPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPrivacyPolicy = action.payload.doc;
    });
    builder.addCase(deletePrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deletePrivacyPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deletePrivacyPolicy.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPrivacyPolicyLive.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPrivacyPolicyLive.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPrivacyPolicyLive.fulfilled, (state, action) => {
      state.isLoading = false;
      state.livePrivacyPolicy = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = privacyPolicySlice.actions;

import React from 'react';
import { Form } from 'antd';
import InputField from 'shared/components/inputField/InputField';
import Button from 'shared/components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'redux-store/auth/authActions';
import '../../../_authentication.scss';
import '../../_passwordReset.scss';
import { PasswordRequirements } from 'shared/components/passwordRequirements/PasswordRequirements';
import { emptyFieldValidator } from 'shared/utils/validators';
import appIcon from '../../../../../assets/imgs/logo.png';

function passwordForm() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmit = (vals) => {
    dispatch(resetPassword(vals));
  };

  return (
    <div className="formBox emailformBox">
      <img src={appIcon} alt="" width={212} height={48} />
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="password"
          className="formfield"
          rules={[{
            validator: emptyFieldValidator, message: 'This field is required',
          }]}
        >
          <InputField label="New Password" type="password" placeholder="Enter New Password" />
        </Form.Item>
        <div className="formfield">
          <PasswordRequirements />
        </div>
        <div className="actionContainer">
          <Button loading={isLoading} variant={Button.variant.filled} htmlType="submit">
            Reset Password
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default passwordForm;

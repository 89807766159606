import React, { useEffect, useState } from 'react';
import './_addNewMedicationSet.scss';
import Grid from 'shared/components/grid/Grid';
import { AddNewProductGridColumns } from 'utils/grid/columns';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import { listProducts } from 'redux-store/products/productsActions';
import SearchBox from 'shared/components/searchbox/SearchBox';

const SEARCH_KEY = 'where[id][like]';
const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
  [SEARCH_KEY]: '',
  sort: '-updatedAt',
};

export default function AddNewProductList({ value, ms, onChange }) {
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (ms?.supplyGroup) {
      setSelectedRows(ms.supplyGroup || []);
    }
  }, [ms?.supplyGroup]);

  useEffect(() => {
    dispatch(listProducts(params));
  }, [params]);

  function onSelect(row, selected) {
    if (selected) {
      setSelectedRows([...selectedRows.filter((x) => x.id !== row.id), row]);
    } else {
      setSelectedRows([...selectedRows.filter((x) => x.id !== row.id)]);
    }
  }

  function onAdd() {
    const existingProductIds = ms?.supplyGroup.map((i) => i.id) || [];
    onChange(selectedRows.filter((i) => !existingProductIds.includes(i.id)));
  }

  function onSearch(v) {
    setParams({
      ...params,
      page: 1,
      [SEARCH_KEY]: v,
    });
  }

  console.log('value', value);

  return (
    <div className="added-procedure-container">
      <div className="added-procedure-container__body">
        <div className="added-procedure-container__body__search">
          <SearchBox placeholder="Search by Product Id" onChange={onSearch} />
        </div>
        <Grid
          columns={AddNewProductGridColumns(onSelect, selectedRows)}
          data={products.docs.map((a) => {
            const product = ms
              ? ms?.supplyGroup.find((b) => b.id === a.id)
              : null;
            if (product) {
              const quantValue = product?.quantityUOM?.ncpdpPreferredTerm || null;
              return {
                ...a,
                quantityUOM: product?.quantityValue || quantValue,
              };
            }
            return a;
          })}
          pagination={{
            total: products.totalDocs,
            current: params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => setParams({ ...params, page, limit: pageSize }),
          }}
        />
      </div>
      <Button
        variant={Button.variant.filled}
        onClick={onAdd}
        disabled={!selectedRows.length}
      >
        Add Product
      </Button>
    </div>
  );
}

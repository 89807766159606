/* eslint-disable */
/**
 * These should be unique. Value is used as a prefix to the thread.
 */
export const threadIdTypes = {
  providerToProvider: `0`,
  providerToMember: `1`,
  providerToPrm: `2`,
  encounter: `3`,
  privatePracticeProvidersToMember: `4`,
  memberEncounterComplaint: `5`,
  memberFeedback: `6`,
  providerFeedback: `7`
};

/**
 * Helper to create a thread ID of the desired form.
 * ```
 */
function createThreadId({ prefix, ids, order }) {
  for (const id of Object.values(ids)) {
    if (!id) {
      throw new Error(
        'Invalid parameters passed to threadIds generator function, please ensure all passed parameters are defined.'
      );
    }
  }
  const idsArray =
    order.orderType === 'key-name'
      ? order.keyOrder.map((key) => {
        return ids[key];
      })
      : Object.values(ids).sort((a, b) => (a < b ? -1 : 1));
  const idsString = idsArray.map((id) => `${id}`).join('|');

  return `${prefix}|${idsString}`;
}

/**
 * Creates a generator and parsing function for a thread ID.
 */
function createThreadsIdFunction({
  prefix,
  // just for type safety
  vars: _,
  order
}) {
  return {
    generate: (vars) => {
      return createThreadId({
        prefix: threadIdTypes[prefix],
        order,
        ids: vars
      });
    },
    parse: (id) => {
      if (!id.startsWith(threadIdTypes[prefix])) {
        return {
          passed: false
        };
      }
      const split = id.split('|');
      if (order.orderType === 'compare') {
        return {
          passed: true,
          val: split.slice(1)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        };
      } else if (order.orderType === 'key-name') {
        const r = {};
        let i = 0;
        for (const key of order.keyOrder) {
          r[key] = split[i + 1];
          if (!r[key]) {
            throw new Error(
              'threadId was malformed: ' + id + '\nPrefix was for ' + prefix
            );
          }
          i++;
        }
        return {
          passed: true,
          val: r
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        };
      }
      return { passed: false };
    },
    // this object should never be used it's just to hold type information
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _parsedDef: {
      type: prefix
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }
  };
}

export const threadIds = {
  /**
   * Generate the `threadId` for a conversation between a provider and another provider. Order of the provider ids does not matter.
   *
   * @example
   * const id = threadIds.providerToProvider.generate({
   *  providerOneId: "provider-1",
   *  providerTwoId: "provider-2"
   * })
   * // id is now equal to
   * `0|provider-1|provider-2`
   */

  providerToProvider: createThreadsIdFunction({
    prefix: 'providerToProvider',
    vars: {
      providerOneId: true,
      providerTwoId: true
    },
    order: { orderType: 'compare' }
  }),

  /**
   * Generate the `threadId` for a conversation between a provider and a member.
   *
   * @example
   * ```tsx
   * const id = threadIds.providerToMember.generate({
   *  providerId: "provider-id",
   *  memberId: "member-id"
   * })
   * // id is now equal to
   * `1|provider-id|member-id`
   * ```
   */
  providerToMember: createThreadsIdFunction({
    prefix: 'providerToMember',
    order: {
      orderType: 'key-name',
      keyOrder: ['providerId', 'memberId']
    },
    vars: {
      providerId: true,
      memberId: true
    }
  }),
  /**
   * Generate the `threadId` for a conversation between a provider and a prm.
   *
   * @example
   * const id = threadIds.providerToPrm.generate({
   *  providerId: "provider-id",
   *  prmId: "prm-id"
   * })
   * // id is now equal to
   * `2|providerId|prmId`
   */
  providerToPrm: createThreadsIdFunction({
    prefix: 'providerToPrm',
    vars: {
      providerId: true,
      prmId: true
    },
    order: {
      orderType: 'key-name',
      keyOrder: ['providerId', 'prmId']
    }
  }),
  /**
   * Generate the `threadId` for an encounter.
   *
   * @example
   * const id = threadIds.encounter.generate({encounterId: 'encounter-id'});
   *
   * // id is now equal to
   * `3|encounter-id`
   */
  encounter: createThreadsIdFunction({
    prefix: 'encounter',
    vars: {
      encounterId: true
    },
    order: {
      orderType: 'key-name',
      keyOrder: ['encounterId']
    }
  }),
  /**
   * Generate the thread id for the messages between a private practice's providers and a single member.
   *
   * @example
   * const id = threadIds.privatePracticeProvidersToMember.generate({
   *  privatePracticeId: "private-practice-id", // Also known as a conciergeId... The id from the documents in the concierge collection
   *  memberId: "member-id"
   * })
   *
   * // id is now equal to
   * `4|private-practice-id|member-id`
   * ``
   */
  privatePracticeProvidersToMember: createThreadsIdFunction({
    prefix: 'privatePracticeProvidersToMember',
    vars: {
      privatePracticeId: true,
      memberId: true
    },
    order: {
      orderType: 'key-name',
      keyOrder: ['privatePracticeId', 'memberId']
    }
  }),

  /**
   * Generate the threadId for a member complaint for an encounter.
   *
   * @example
   * const id = threadIds.memberEncounterComplaint.generate({complaintId: "complaint-id"});
   *
   * // id is now equal to
   * `5|complaint-id`
   */

  memberEncounterComplaint: createThreadsIdFunction({
    prefix: 'memberEncounterComplaint',
    vars: {
      complaintId: true
    },
    order: {
      orderType: 'key-name',
      keyOrder: ['complaintId']
    }
  }),
  /**
   * Generate the threadId for a member feedback for an encounter.
   *
   * @example
   * const id = threadIds.memberFeedback.generate({feedbackId: "feedback-id"});
   *
   * // id is now equal to
   * `6|feedback-id`
   */

  memberFeedback: createThreadsIdFunction({
    prefix: "memberFeedback",
    vars: {
      feedbackId: true
    },
    order: {
      orderType: "key-name",
      keyOrder: ["feedbackId"]
    }
  }),
  /**
   * Generate the threadId for a member feedback for an encounter.
   *
   * @example
   * const id = threadIds.providerFeedback.generate({feedbackId: "feedback-id"});
   *
   * // id is now equal to
   * `7|feedback-id`
   */

  providerFeedback: createThreadsIdFunction({
    prefix: "providerFeedback",
    vars: {
      feedbackId: true
    },
    order: {
      orderType: "key-name",
      keyOrder: ["feedbackId"]
    }
  })
};

function makeTestCase(fn, input, shouldMatch) {
  return () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const output = fn(input);
    if (output !== shouldMatch) {
      throw new Error(
        'Test case failed for input\n' +
        JSON.stringify(input, null, 2) +
        '\nDidnt match ' +
        shouldMatch +
        '\nIt was ' +
        output
      );
    }
  };
}

const testCases = [
  makeTestCase(
    threadIds.providerToProvider.generate,
    {
      providerOneId: 'one',
      providerTwoId: 'two'
    },
    `${threadIdTypes.providerToProvider}|one|two`
  ),
  makeTestCase(
    threadIds.providerToProvider.generate,
    {
      providerOneId: 'two',
      providerTwoId: 'one'
    },
    `${threadIdTypes.providerToProvider}|one|two`
  ),
  makeTestCase(
    threadIds.providerToMember.generate,
    {
      memberId: 'member',
      providerId: 'provider'
    },
    `${threadIdTypes.providerToMember}|provider|member`
  ),
  makeTestCase(
    threadIds.providerToPrm.generate,
    {
      prmId: 'prm',
      providerId: 'provider'
    },
    `${threadIdTypes.providerToPrm}|provider|prm`
  ),
  makeTestCase(
    threadIds.encounter.generate,
    {
      encounterId: `encounter`
    },
    `${threadIdTypes.encounter}|encounter`
  ),
  makeTestCase(
    threadIds.privatePracticeProvidersToMember.generate,
    {
      memberId: 'member',
      privatePracticeId: 'private-practice'
    },
    `${threadIdTypes.privatePracticeProvidersToMember}|private-practice|member`
  ),
  makeTestCase(
    threadIds.memberEncounterComplaint.generate,
    {
      complaintId: 'complaint'
    },
    `${threadIdTypes.memberEncounterComplaint}|complaint`
  ),

  () => {
    const memberId = 'member';
    const privatePracticeId = 'practice';
    const generated = threadIds.privatePracticeProvidersToMember.generate({
      memberId: memberId,
      privatePracticeId: privatePracticeId
    });

    const parsed = threadIds.privatePracticeProvidersToMember.parse(generated);
    if (
      parsed.passed &&
      parsed.val.privatePracticeId === privatePracticeId &&
      parsed.val.memberId === memberId
    ) {
      return;
    }
    console.log(JSON.stringify(parsed, null, 2));
    throw new Error('Nope1');
  },
  () => {
    // does parse return the same values passed to generator?
    const provA = 'b';
    const provB = 'a';

    const generated = threadIds.providerToProvider.generate({
      providerOneId: provB,
      providerTwoId: provA
    });

    const parsed = threadIds.providerToProvider.parse(generated);

    if (parsed.passed && parsed.val[0] === provB && parsed.val[1] === provA) {
      return;
    }
    throw new Error('Nope2');
  },
  () => {
    const idOne = threadIds.privatePracticeProvidersToMember.generate({
      memberId: 'member',
      privatePracticeId: 'private-practice'
    });
    const idTwo = threadIds.providerToProvider.generate({
      providerOneId: 'provider-one',
      providerTwoId: 'provider-two'
    });

    const parsedOne = parseThreadId(idOne);
    const parsedTwo = parseThreadId(idTwo);

    if (
      parsedOne?.type !== 'privatePracticeProvidersToMember' ||
      parsedOne.value.memberId !== 'member' ||
      parsedOne.value.privatePracticeId !== 'private-practice'
    ) {
      throw new Error('Nope3');
    }
    if (
      parsedTwo?.type !== 'providerToProvider' ||
      parsedTwo.value[0] !== 'provider-one' ||
      parsedTwo.value[1] !== 'provider-two'
    ) {
      throw new Error('Nope 4');
    }
  }
];

/**
 * This can be used to parse any thread id and returns the ids in a named object.
 *
 * @example
 * const threadId =
 */
export function parseThreadId(id) {
  for (const generator of Object.values(threadIds)) {
    const parsed = generator.parse(id);
    if (parsed.passed) {
      return {
        type: generator._parsedDef.type,
        value: parsed.val
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      };
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function test() {
  for (const testCase of testCases) {
    testCase();
  }

  parseGenericId();
  generateThreadIdsExample();
  parseSpecificId();
}

// test();

/**
 * 🚀🚀🚀 EXAMPLES 🚀🚀🚀
 */

function generateThreadIdsExample() {
  // Get a thread id for the conversation between a provider and the PRM
  const providerId = 'provider-id';
  const prmId = 'prm-id';

  // This should be used when fetching AND posting messages.
  const providerPrmThreadId = threadIds.providerToPrm.generate({
    prmId: prmId,
    providerId: providerId
  });

  const complaintId = 'complaint';

  const complaintThreadId = threadIds.memberEncounterComplaint.generate({
    complaintId: complaintId
  });
}

function parseGenericId() {
  // Example usage of a function that can be used to parse any thread ID and
  // get the corresponding information back.
  const providerId = 'provider-id';
  const prmId = 'prm-id';
  const complaintId = 'complaint';

  const providerToPrmThread = threadIds.providerToPrm.generate({
    prmId: prmId,
    providerId: providerId
  });
  const complaintThread = threadIds.memberEncounterComplaint.generate({
    complaintId: complaintId
  });

  const parsedThreads = [
    parseThreadId(providerToPrmThread),
    parseThreadId(complaintThread)
  ];

  for (const thread of parsedThreads) {
    if (thread?.type === 'providerToPrm') {
      // if type equals 'providerToPrm',
      // we know thread.value.prmId and thread.value.providerId are defined.
      // the 'value' always matches the input variables to .generate function
      thread.value.prmId;
      thread.value.providerId;
    } else if (thread?.type === 'memberEncounterComplaint') {
      thread.value.complaintId;
    }
  }
}

function parseSpecificId() {
  // If you know the threadId type you can also just use threadIds.name.parse()
  // It isn't any better than using the generic parseThreadId though, use whichever is easier.

  // Get a thread id for the conversation between a provider and the PRM
  const providerId = 'provider-id';
  const prmId = 'prm-id';

  const threadId = threadIds.providerToPrm.generate({
    prmId: prmId,
    providerId: providerId
  });

  const parsed = threadIds.providerToPrm.parse(threadId);

  // parsed.passed is true if the thread type is determined to match providerToPrm
  if (parsed.passed) {
    console.log(parsed.val.providerId);
  }
}

import { createSlice } from '@reduxjs/toolkit';
import {
  createMedicationSet,
  listMedicationSet,
  getMedicationSet,
  updateMedicationSet,
} from './medicationSetActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  medicationSet: { docs: [] },
  selectedMedicationSet: {},
};

export const medicationSetSlice = createSlice({
  name: 'medicationSetSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listMedicationSet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listMedicationSet.rejected, (state) => {
      state.isLoading = false;
      state.medicationSet = { docs: [] };
    });
    builder.addCase(listMedicationSet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.medicationSet = action.payload;
    });
    builder.addCase(getMedicationSet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMedicationSet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getMedicationSet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMedicationSet = action.payload;
    });
    builder.addCase(createMedicationSet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMedicationSet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createMedicationSet.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMedicationSet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateMedicationSet.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateMedicationSet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedMedicationSet = action.payload.doc;
    });
    return builder;
  },
});

export const { clearData } = medicationSetSlice.actions;

import { createSlice } from '@reduxjs/toolkit';
import {
  listProviderType,
} from './providerTypeActions';

const INITIAL_STATE = {
  isLoading: false,
  providerTypes: { docs: [] },
};

export const providerTypeSlice = createSlice({
  name: 'providerTypeSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProviderType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProviderType.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProviderType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.providerTypes = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = providerTypeSlice.actions;

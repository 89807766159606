/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'shared/axios/axios';
import { toast } from 'react-toastify';
import { ALERTS_API_URL } from './alertsUrls';

export const getAllAlertsApiCall = (filters) => {
  const query = new URLSearchParams(filters).toString();
  return axios.get(ALERTS_API_URL(`?${query}`)).then(({ data }) => data);
};

export const getProviderAlerts = createAsyncThunk(
  'alerts/listProviderAlerts',
  async (filters, { getState }) => {
    const state = getState();
    // eslint-disable-next-line no-unused-vars
    const myId = state?.auth?.user?.id;
    // eslint-disable-next-line no-unused-vars
    return getAllAlertsApiCall({
      ...filters,
      'where[recipient][equals]': myId,
    });
  },
);

export const listProviderAlerts = createAsyncThunk(
  'alerts/listAllProviderAlerts',
  async (filters, { getState }) => {
    const state = getState();
    // eslint-disable-next-line no-unused-vars
    const myId = state?.auth?.user?.id;
    // eslint-disable-next-line no-unused-vars
    return getAllAlertsApiCall({
      ...filters,
      'where[recipient][equals]': myId,
    });
  },
);

export const updateAlerts = createAsyncThunk(
  'alerts/updateAlerts',
  async ({ id, params }) => {
    return axios.patch(
      ALERTS_API_URL(`/${id}`),
      params,
    ).then(({ data }) => {
      toast.success('Alert has been updated');
      return data;
    });
  },
);

export const updateMultipleAlerts = createAsyncThunk(
  'alerts/updateMultipleAlerts',
  async ({ query, params }) => {
    return axios.patch(
      ALERTS_API_URL(`?${new URLSearchParams(query).toString()}`),
      params,
    ).then(({ data }) => {
      toast.success('Alert has been updated');
      return data;
    });
  },
);

/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';

// import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import { listSymptoms, deleteSymptoms } from 'redux-store/symptoms/symptomsActions';
import { useDispatch, useSelector } from 'react-redux';
import { SymptomGridColumns } from '../../../../../utils/grid/columns';

function SymptomsList() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, searchText: '', sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [filterKey, setFilterKey] = useState('where[or][0][symptomName][like]');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { symptoms } = useSelector((state) => state.symptoms);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.SYMPTOMS_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      searchText: v,
    });
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.SYMPTOMS_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteSymptoms(item.id))
        .then((payload) => {
          if (!payload.error) {
            if (params.page === 1) {
              dispatch(listSymptoms(params));
            } else {
              setParams({ ...params, page: 1 });
            }
          }
        });
    }
  };

  useEffect(() => {
    dispatch(listSymptoms({ ...params, [filterKey]: params.searchText }));
  }, [params]);

  return (
    <div className="revdoc-setup-container">
      <div className="revdoc-setup-container__header">
        <SearchBox placeholder="Search by Symptom Name" onChange={onSearch} />
        <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
          + Add New Symptom
        </Button>
      </div>
      <Grid
        data={symptoms.docs}
        pagination={{
          total: symptoms.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
        columns={
          SymptomGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )
        }
      />
    </div>
  );
}

export default SymptomsList;

/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailabilities } from 'redux-store/myProviders/myProvidersActions';
import _ from 'lodash';
import './_schedule.scss';

function ScheduleUI() {
  const { selectedMyProviders, availability } = useSelector((state) => state.myProviders);
  const [weekAvailability, setWeekAvailability] = useState({});
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedMyProviders.availability && selectedMyProviders.availability?.length > 0) {
      const availabilityIds = { limit: 0 };
      selectedMyProviders.availability?.forEach(
        (item, index) => {
          availabilityIds[`where[id][in][${index}]`] = item;
        },
      );
      dispatch(getAvailabilities(availabilityIds));
    }
  }, [selectedMyProviders]);

  useEffect(() => {
    if (availability?.docs) {
      const groupedKeys = availability?.docs?.reduce(
        (group, item, index) => {
          if (item.dayOfWeek != null) {
            if (!group[item.dayOfWeek]) {
              group[item.dayOfWeek] = [];
            }
            const loc = availability.docs.findIndex((x) => x.dayOfWeek === item.dayOfWeek && x.hourRangeStart === item.hourRangeStart
            && x.minuteRangeStart === item.minuteRangeStart && x.hourRangeEnd === item.hourRangeEnd && x.minuteRangeEnd === item.minuteRangeEnd);
            if (loc === index) {
              group[item.dayOfWeek].push(item);
            }
          } else if (item.date && item.date != null) {
            const dayOfWeek = moment(item.date).day();
            if (!group[dayOfWeek]) {
              group[dayOfWeek] = [];
            }
            const loc = availability.docs.findIndex((x) => moment(x.date).day() === dayOfWeek && x.hourRangeStart === item.hourRangeStart
              && x.minuteRangeStart === item.minuteRangeStart && x.hourRangeEnd === item.hourRangeEnd && x.minuteRangeEnd === item.minuteRangeEnd);
            if (loc === index) {
              group[dayOfWeek].push(item);
            }
          }
          return group;
        },
        {},
      );
      setWeekAvailability(groupedKeys);
    }
  }, [availability]);

  return (
    <div>
      <div className="scheduleContainer">
        {Object.keys(weekAvailability)?.length === 0 && <> No Schedules </> }
        {Object.keys(weekAvailability)?.map((item, index) => (
          <div key={index} className="availability">
            <div className="availability__item">
              <span>{weekday[item] || ''}</span>
              <div>
                {weekAvailability[item]?.map((subItem, jindex) => (
                  <div
                    key={`${index}_${jindex}`}
                    className="availability__item__time"
                  >
                    <span>
                      {moment(
                        `${moment().format('YYYY-MM-DD ')}${
                          subItem.hourRangeStart || 0
                        }:${subItem.minuteRangeStart || 0} `,
                      ).format('hh:mm A')}
                    </span>
                    <span>&nbsp;-&nbsp;</span>
                    <span>
                      {moment(
                        `${moment().format('YYYY-MM-DD ')}${
                          subItem.hourRangeEnd || 0
                        }:${subItem.minuteRangeEnd || 0}`,
                      ).format('hh:mm A')}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
    </div>
  );
}
export default ScheduleUI;

import { createSlice } from '@reduxjs/toolkit';
import {
  createFeedbacks,
  deleteFeedbacks,
  getFeedbacks,
  listFeedbacks,
  updateFeedbacks,
} from './feedbacksActions';

const INITIAL_STATE = {
  isLoading: false,
  isDeleteing: false,
  feedbacks: { docs: [] },
  selectedFeedback: {},
};

export const feedbacksSlice = createSlice({
  name: 'feedbacksSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listFeedbacks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listFeedbacks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listFeedbacks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.feedbacks = action.payload;
    });
    builder.addCase(getFeedbacks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFeedbacks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFeedbacks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedFeedback = action.payload;
    });
    builder.addCase(createFeedbacks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createFeedbacks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createFeedbacks.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFeedbacks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFeedbacks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFeedbacks.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteFeedbacks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFeedbacks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteFeedbacks.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = feedbacksSlice.actions;

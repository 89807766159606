import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

export default function EducationCareer() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);

  return (
    <div className="my-provider-container__body--education">
      <div className="education-wrapper b-right">
        <h4>Education</h4>

        <span className="span">
          {_.get(selectedMyProviders, 'undergraduateEducation.degree', '')}
          {', '}
          {_.get(selectedMyProviders, 'undergraduateEducation.universityName', '')}
        </span>
        <span className="span">
          {_.get(selectedMyProviders, 'graduateEducation.degree', '')}
          {', '}
          {_.get(selectedMyProviders, 'graduateEducation.universityName', '')}
        </span>
        <span className="span">
          {_.get(selectedMyProviders, 'internship.internshipName', '')}
        </span>
        <span className="span">
          {_.get(selectedMyProviders, 'residency.internshipName', '')}
        </span>
        <span className="span">
          {_.get(selectedMyProviders, 'fellowship.internshipName', '')}
        </span>
      </div>
      <div className="education-wrapper b-right">
        <h4>Languages</h4>
        {_.get(selectedMyProviders, 'biography.languagesSpoken', []).map((lang) => (
          <span className="span">
            {lang.name}
          </span>
        ))}
      </div>
      <div className="education-wrapper b-right d">
        <h4>Certifications</h4>
        {_.get(selectedMyProviders, 'certifications', []).map((certification) => (
          <span className="span">{certification?.certificationName}</span>
        ))}
      </div>
      <div className="education-wrapper">
        <h4>Accepted Insurance</h4>
        {_.get(selectedMyProviders, 'insurancePlan', []).map((plan) => (
          <span className="span">
            {plan?.org?.company}
            {' - '}
            {plan?.planName}
          </span>
        ))}
      </div>

    </div>
  );
}

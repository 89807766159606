import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { listMedicationSet } from 'redux-store/medicationSet/medicationSetActions';
import { MedicationGridColumns } from '../../../../../../utils/grid/columns';

function MedicationSetList() {
  const SEARCH_KEY = 'where[name][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [SEARCH_KEY]: '',
    sort: '-updatedAt',
  };
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { medicationSet } = useSelector((state) => state.medicationSet);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.MEDICATION_SETS_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.MEDICATION_SETS_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      [SEARCH_KEY]: v,
    });
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    }
  };

  useEffect(() => {
    if (params) {
      dispatch(listMedicationSet(params));
    }
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox
            placeholder="Search by Procedure or Keyword"
            onChange={onSearch}
          />
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New Medication Set
          </Button>
        </div>
        <Grid
          columns={MedicationGridColumns({
            ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item),
          })}
          data={medicationSet.docs}
          pagination={{
            total: medicationSet.totalDocs,
            current: params.page,
            defaultPageSize: 5,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}

export default MedicationSetList;

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import SearchBox from 'shared/components/searchbox/SearchBox';
import Grid from 'shared/components/grid/Grid';
import { URLS } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProcedureSetup, listProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';
// import AddNewProcedureSetup from './components/AddNewProcedure';
import { ProcedureSetupGridColumns } from '../../../../../../utils/grid/columns';
import './_procedureSetup.scss';

function ProcedureSetupList() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, searchText: '', sort: '-updatedAt',
  };
  // eslint-disable-next-line no-unused-vars
  const [filterKey, setFilterKey] = useState('where[or][0][procedureName][like]');
  const [params, setParams] = useState(INITIAL_PARAMS);
  const { proceduresSetups } = useSelector((state) => state.proceduresSetup);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.PROCEDURE_SETUP_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.PROCEDURE_SETUP_URL}/${id}`);
  };
  const onSearch = (v) => {
    setParams({
      ...params,
      page: 1,
      searchText: v,
    });
  };
  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'delete') {
      dispatch(deleteProcedureSetup(item.id));
    }
  };
  useEffect(() => {
    dispatch(listProceduresSetup({ ...params, [filterKey]: params.searchText }));
  }, [params]);

  return (
    <div>
      <div className="revdoc-setup-container">
        <div className="revdoc-setup-container__header">
          <SearchBox placeholder="Search by Procedure name " onChange={onSearch} waitTime={1300} />
          <div>
            <DropDown
              placeholder="Filter By"
              isOptionsObj
              className="rv-setup-dropdown"
              value={filterKey}
              onChange={setFilterKey}
              options={[
                { label: 'Name', value: 'where[or][0][procedureName][like]' },
                { label: 'Category', value: 'where[or][0][procedureCategory.procedureCategoryName][like]' },
                // { label: 'Delivery Method',
                // value: 'where[or][0][deliveryMethods.deliveryMethodName][like]' },
                // { label: 'Product Price', value: 'where[or][0][procedurePrice][like]' },
                // { label: 'Term date', value: 'where[or][0][procedureTermDate][like]' },
              ]}
            />
          </div>
          <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
            + Add New Procedure
          </Button>
        </div>
        <Grid
          columns={
            ProcedureSetupGridColumns(
              { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
            )
          }
          data={proceduresSetups.docs}
          pagination={{
            total: proceduresSetups.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        // expandable={{
        //   isExpandable: true,
        //   expandedRowRender: () => (
        //     <AddNewProcedureSetup />
        //   ),
        // }}
        />
      </div>
    </div>
  );
}

export default ProcedureSetupList;

import React, { useState } from 'react';
import './DropDown.scss';
import { Select } from 'antd';

const { Option } = Select;

export default function DropDown(props) {
  const [DropdownCaret, setDropdownCaret] = useState(false);
  const onCaretChange = () => {
    setDropdownCaret(!DropdownCaret);
  };

  const {
    disabled, className, placeholder, value, onChange,
    dropdownClassName, dropdownStyle, defaultValue, onSelect,
    showSearch, onSearch, isOptionsObj, options, label, loading,
    onChangeAfter,
  } = props;
  return (
    <div>

      {label && <label htmlFor="brand">{label}</label>}
      <div className="select-box">
        <Select
          style={disabled && { opacity: 0.5 }}
          loading={loading}
          onFocus={onCaretChange}
          onBlur={onCaretChange}
          suffixIcon={(
            <div>
              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.495053 2.42424L5.95521 8.79612C6.04322 8.89877 6.1524 8.98117 6.27525 9.03766C6.3981 9.09415 6.53171 9.1234 6.66693 9.1234C6.80214 9.1234 6.93576 9.09415 7.05861 9.03766C7.18146 8.98117 7.29064 8.89877 7.37865 8.79612L12.8388 2.42424C13.3599 1.81604 12.9279 0.876587 12.1271 0.876587H1.20521C0.404428 0.876587 -0.027603 1.81604 0.495053 2.42424Z" fill="#393B3D" />
              </svg>
            </div>
          )}
          id=""
          disabled={disabled}
          className={className}
          placeholder={placeholder}
          value={value}
          onChange={(v) => {
            onChange(v);
            if (onChangeAfter) {
              onChangeAfter(v);
            }
          }}
          dropdownClassName={dropdownClassName}
          dropdownStyle={dropdownStyle}
          defaultValue={defaultValue}
          onSelect={onSelect}
          showSearch={showSearch}
          onSearch={onSearch}
          dropdownAlign={{
            offset: [0, 0],
            overflow: {
              adjustY: 0,
            },
          }}
        >
          {isOptionsObj
            ? options.map((obj, i) => (
              <Option key={i} value={obj.value}>
                {obj.label}
              </Option>
            ))
            : options.map((opt, ind) => (
              <Option key={ind} value={options[ind]}>
                {options[ind]}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}

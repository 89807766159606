/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { COMPLAINTS_API_URL } from './complaintsUrls';

export const listComplaints = createAsyncThunk(
  'complaintsSlice/listComplaints',
  async (filters, { getState }) => {
    const state = getState();
    // eslint-disable-next-line no-unused-vars
    const myId = state?.auth?.user?.id;
    const query = new URLSearchParams({
      ...filters,
      'where[or][0][prmId][like]': myId,
    }).toString();

    return axios.get(COMPLAINTS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getComplaints = createAsyncThunk(
  'complaintsSlice/getComplaints',
  async (id) => {
    return axios.get(COMPLAINTS_API_URL(id)).then(({ data }) => data);
  },
);

export const createComplaints = createAsyncThunk(
  'complaintsSlice/createComplaints',
  async (params) => {
    return axios.post(
      COMPLAINTS_API_URL(),
      params,
    ).then(({ data }) => {
      toast.success('Supply has been created');
      return data;
    });
  },
);

export const updateComplaints = createAsyncThunk(
  'complaintsSlice/updateComplaints',
  async ({ id, params }) => {
    return axios.patch(
      COMPLAINTS_API_URL(id),
      params,
    ).then(({ data }) => {
      toast.success('Complaint has been updated');
      return data;
    });
  },
);

export const deleteComplaints = createAsyncThunk(
  'complaintsSlice/deleteComplaints',
  async (id, { dispatch }) => {
    return axios.patch(COMPLAINTS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Supply has been deleted');
        return dispatch(listComplaints());
      });
  },
);

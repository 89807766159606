import { createSlice } from '@reduxjs/toolkit';
import {
  listDeliveryMethod,
} from './deliveryMethodActions';

const INITIAL_STATE = {
  isLoading: false,
  deliveryMethods: { docs: [] },
};

export const deliveryMethodSlice = createSlice({
  name: 'deliveryMethodSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listDeliveryMethod.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listDeliveryMethod.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listDeliveryMethod.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deliveryMethods = action.payload;
    });

    return builder;
  },
});

export const {
  clearData,
} = deliveryMethodSlice.actions;

/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listProviderType } from 'redux-store/providerType/providerTypeActions';
import { getNonDeletedRecordsFilter } from 'shared/utils/filters';
import MultiCheckboxSelect from '../multiCheckboxSelect/MultiCheckboxSelect';

function ProviderType({
  value,
  onChange,
}) {
  const INITIAL_PARAMS = {
    limit: 0,
    page: 1,
    ...getNonDeletedRecordsFilter(),
  };
  const [search] = useState(INITIAL_PARAMS);
  const { providerTypes = { docs: [] } } = useSelector((s) => s.providerType);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProviderType(search));
  }, [search]);

  return (
    <div>
      <MultiCheckboxSelect
        label="Provider Type *"
        options={providerTypes.docs.map((o) => ({ label: o.providerTypeName, value: o.id }))}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default ProviderType;
